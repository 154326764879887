import { Success } from '@/assets/illustrations'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { T } from '@tolgee/react'

export const SuccessMessage: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  return (
    <PopupSkeleton showClose onClose={onClose}>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6 mt-3">
          <div className="mx-2 rounded p-2">
            <img src={Success} alt="Success" />
          </div>
        </div>
        <h1 className="text-lg font-medium">
          <T keyName="components.joinSubscription.joinDetails.serviceDetails.successMessage.title" />
        </h1>
        <p className="flex text-gray-100 justify-center items-center m-3">
          <T keyName="components.joinSubscription.joinDetails.serviceDetails.successMessage.subtitle" />
        </p>
        <div className="flex justify-center items-center pb-3">
          <Button onClick={onClose}>
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.successMessage.close.btn" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
