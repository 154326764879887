import { AddCardFailed, AddCardSuccess } from '@/assets/illustrations'
import { Button } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { useMutation } from '@apollo/client'
import { T } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
const VERIFY_CARD = gql(`
  mutation verifyNewCard($transactionId: String!) {
    verifyNewCardTransaction(transactionId: $transactionId)
  }
`)

export const NewCardVerify: React.FC = () => {
  const navigate = useNavigate()

  const params = useParams<{ transactionId: string }>()
  if (!params.transactionId) navigate(-1)

  const [state, setState] = useState<{
    loading: boolean
    error: string | null
  }>({
    loading: true,
    error: null
  })

  const [verifyNewCard] = useMutation(VERIFY_CARD, {
    onError: (e) => {
      // biome-ignore lint/suspicious/noExplicitAny: GraphQL error handling
      const errMsg = (e.graphQLErrors?.[0] as any)?.data?.message
      if (!errMsg) {
        setState({ loading: false, error: 'Unknown error' })
        return
      }
      console.log(errMsg)
      setState({ loading: false, error: errMsg })
    }
  })

  useEffect(() => {
    setState({ error: null, loading: true })
    verifyNewCard({ variables: { transactionId: params.transactionId! } })
      .then((res) => {
        if (!res.data) return
        setState({ error: null, loading: false })
      })
      .catch((e) => {
        setState({ error: `${e}`, loading: false })
      })
  }, [params.transactionId, verifyNewCard])

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-4">
          {state.loading ? (
            <Spinner />
          ) : (
            <img
              alt={state.error ? 'Failed' : 'Success'}
              src={state.error ? AddCardFailed : AddCardSuccess}
              className="max-w-[150px]"
            />
          )}
        </div>

        <h1 className="text-2xl mb-4">
          <T
            keyName={`components.payments.paymentMethods.popup.newCard.${
              state.loading
                ? 'loading'
                : state.error
                  ? 'error.title'
                  : 'success.title'
            }`}
          />
        </h1>

        {!state.loading && (
          <>
            <h6 className="text-sm text-gray-shuttle-soft mb-6">
              <T
                keyName={`components.payments.paymentMethods.popup.newCard.${
                  state.error ? 'error' : 'success'
                }.text`}
              />
            </h6>

            <div className="flex justify-center gap-6 mt-8">
              <Button
                onClick={() =>
                  state.error
                    ? navigate(
                        '/overview/payments/payment-methods/popup/new-card'
                      )
                    : navigate('/overview/payments/payment-methods', {
                        state: 'refetch'
                      })
                }
              >
                <T
                  keyName={`components.payments.paymentMethods.popup.newCard.${
                    state.error ? 'error' : 'success'
                  }.btn`}
                />
              </Button>
            </div>
          </>
        )}
      </div>
    </PopupSkeleton>
  )
}
