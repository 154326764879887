import {
  TrustPilotLogoMobile,
  TrustPilotStarts
} from '@/assets/illustrations/mobile'
import { TrustPilotLogo } from '@/assets/landing_page/trust_pilot'
import { useScreenDetector } from '@/hooks/ScreenDetector'
import { T } from '@tolgee/react'

export const TrustPilot: React.FC = () => {
  const { isBig, isSmall } = useScreenDetector()
  return (
    <div
      className="flex max-w-[740px] w-full mx-auto justify-center items-center
        max-md:flex-col max-md:text-center max-md:overflow-hidden"
    >
      {isBig && (
        <div className="mr-4">
          <a
            href={'https://www.trustpilot.com/review/partizi.de'}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={TrustPilotLogo} alt="" />
          </a>
        </div>
      )}
      {isSmall && (
        <>
          <div className="mb-3">
            <a
              href={'https://www.trustpilot.com/review/partizi.de'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={TrustPilotLogoMobile} alt="" />
            </a>
          </div>
          <div className="mb-3">
            <a
              href={'https://www.trustpilot.com/review/partizi.de'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={TrustPilotStarts} alt="" />
            </a>
          </div>
        </>
      )}
      <h3 className="text-black mt-3 text-xl max-md:text-sm">
        <T keyName="components.landingPage.trustPilot.numbers" />
      </h3>
    </div>
  )
}
