import { Check, Forbidden } from '@/assets/icons'
import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { gql } from '@/gql'
import { getErrorMessage } from '@/utils/errors.ts'
import { toastError, toastSuccess } from '@/utils/toast'
import { useMutation, useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import invariant from 'tiny-invariant'

const SLOT_DETAILS = gql(`
  query slotDetails($slotId: ID!) {
    slot(where: { id: $slotId }) {
      paymentDueDate
      service {
        provider {
          price {
            offerReturn
          }
        }
      }
      usedBy {
        fullName
        avatar
      }
    }
  }
`)

const UNBLOCK_SLOT = gql(`
  mutation reactivateSlot($slotId: String!) {
    reactivate(slotId: $slotId) {
      id
    }
  }
`)

export const SlotUserUnblock: React.FC = () => {
  const { id: serviceId, slotId } = useParams<{ id: string; slotId: string }>()
  invariant(slotId, 'Slot ID should be provided by the route')

  const navigate = useNavigate()

  const { data } = useQuery(SLOT_DETAILS, {
    variables: { slotId }
  })

  const [hover, setHover] = useState(false)

  const [unblockSlot] = useMutation(UNBLOCK_SLOT)

  const handleConfirm = async () => {
    try {
      await unblockSlot({ variables: { slotId } })
      toastSuccess('Slot was unblocked!')
      navigate(`/overview/subscriptions/offer/${serviceId}/overview`, {
        replace: true,
        state: 'refetch'
      })
    } catch (e: unknown) {
      console.error(e)
      toastError(getErrorMessage(e))
    }
  }

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-6">
          <div className="relative">
            <ImgProfile
              img={data?.slot?.usedBy?.avatar}
              className={twJoin(
                'border-[3px] border-transparent shadow-[0_0_0_3px]',
                hover ? 'shadow-blue-dodger/50' : 'shadow-pink-brink/50'
              )}
            />
            {hover ? (
              <Check
                className="absolute bottom-1 right-1
            [&_circle]:fill-blue-dodger [&_circle]:stroke-blue-dodger [&_path]:stroke-white"
              />
            ) : (
              <Forbidden
                className="absolute bottom-1 right-1
            [&_circle]:fill-pink-brink [&_circle]:stroke-pink-brink [&_path]:stroke-white"
              />
            )}
          </div>
          <h6 className="text-xs text-gray-shuttle">
            {data?.slot?.usedBy?.fullName}
          </h6>
          <h6 className="text-blue-dodger text-xs">
            <T
              keyName="components.offerSubscription.offerDetails.overview.unblockUserPopup.monthlyPayment"
              params={{
                currency: () => '€',
                amount: () =>
                  data?.slot.service.provider.price.offerReturn.toFixed(2) ??
                  '0.00',
                month: () => (
                  <T keyName="components.offerSubscription.offerDetails.overview.blockUserpopup.month" />
                )
              }}
            />
          </h6>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.overview.unblockUserPopup.title"
            params={{
              user: () => data?.slot?.usedBy?.fullName ?? 'User'
            }}
          />
        </h3>

        <h6 className="text-sm text-gray-shuttle-soft mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.overview.unblockUserPopup.subTitle"
            params={{
              username: () => data?.slot?.usedBy?.fullName ?? 'User'
            }}
          />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() => navigate('../..', { replace: true })}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.offerSubscription.offerDetails.overview.unblockUserPopup.cancel" />
          </Button>
          <Button
            onClick={handleConfirm}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <T keyName="components.offerSubscription.offerDetails.overview.unblockUserPopup.confirm" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
