/**
 * WARNING: DO NOT FIX ACCORDING TO react-hooks/exhaustive-deps.
 * It will cause a circular update and crash the browser.
 * This provider can probably be implemented in a better way.
 */

import { useQuery, useSubscription } from '@apollo/client'
import _ from 'lodash'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
  ChatContext,
  type ChatMessage,
  type ChatSubscribeCallback,
  type IChatContext
} from './context'
import { GET_SERVICE_IDS, GET_UNREAD_COUNT, SUB_CHAT_MESSAGE } from './queries'

interface ServiceSubscriptionProps {
  serviceId: string
  onMessage: (message: ChatMessage) => void
  setUnreadCount: (count: number) => void
  storeRefetch: (refetch: () => void) => void
}

const ServiceSubscription: React.FC<ServiceSubscriptionProps> = ({
  serviceId,
  onMessage,
  setUnreadCount,
  storeRefetch
}) => {
  const [unreadCount, setUnreadCountState] = useState<number>(0)

  const { data: unreadData, refetch } = useQuery(GET_UNREAD_COUNT, {
    variables: { serviceId }
  })
  const { data: message } = useSubscription(SUB_CHAT_MESSAGE, {
    variables: { serviceId }
  })

  useEffect(() => {
    if (unreadData) {
      setUnreadCountState(unreadData.unreadCount)
    }
  }, [unreadData])

  // biome-ignore lint/correctness/useExhaustiveDependencies: Soham told me do not fix.
  useEffect(() => {
    setUnreadCount(unreadCount)
  }, [unreadCount])

  // biome-ignore lint/correctness/useExhaustiveDependencies: Soham told me do not fix.
  useEffect(() => {
    if (message) {
      onMessage(message.chatMessage)
      refetch()
    }
  }, [message])

  // biome-ignore lint/correctness/useExhaustiveDependencies: Soham told me do not fix.
  useEffect(() => {
    if (storeRefetch) {
      storeRefetch(() => setTimeout(refetch, 1000))
    }
  }, [refetch])

  return null // This component does not render anything
}

export const ChatProvider: React.FC<{ children: JSX.Element }> = ({
  children
}) => {
  const [serviceIds, setServiceIds] = useState<string[]>([])
  const [servicesOwned, setServicesOwned] = useState<string[]>([])
  const [servicesJoined, setServicesJoined] = useState<string[]>([])
  const [callbacks, setCallbacks] = useState<
    Record<string, ChatSubscribeCallback>
  >({}) // Callbacks per serviceId
  const [unreadCountPerService, setUnreadCountPerService] = useState<
    Record<string, number>
  >({})
  const [refetchUnreadCountFunctions, setRefetchUnreadCountFunctions] =
    useState<Record<string, () => void>>({})

  const { data: serviceData, refetch } = useQuery(GET_SERVICE_IDS, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!serviceData) return
    const servicesOwned: string[] =
      serviceData.services.servicesOwned?.map(
        (service: { id: string }) => service.id
      ) ?? []
    const servicesJoined: string[] =
      serviceData.services.servicesJoined?.map(
        (service: { id: string }) => service.id
      ) ?? []
    const uniqueServiceIds = [
      ...new Set(_.concat(servicesOwned, servicesJoined))
    ]

    setServiceIds(uniqueServiceIds)
    setServicesOwned([...new Set(servicesOwned)])
    setServicesJoined([...new Set(servicesJoined)])
  }, [serviceData])

  useEffect(() => {
    const unreadCount = Object.values(unreadCountPerService).reduce(
      (sum, count) => sum + count,
      0
    )
    const favicon = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement
    if (unreadCount) {
      document.title = `(${unreadCount}) ${document.title}`
      favicon.href = `${import.meta.env.PUBLIC_URL}/favicon-unread.ico`
      import('../../assets/notification.wav')
        .then((notificationSound) => {
          const audio = new Audio(notificationSound.default)
          audio.play()
        })
        .catch((error) => {
          console.error(error)
        })
    }
    return () => {
      document.title = document.title.replace(/^\(\d+\)\s/, '')
      favicon.href = `${import.meta.env.PUBLIC_URL}/favicon.ico`
    }
  }, [unreadCountPerService])

  const subscribeToNew = useCallback<IChatContext['subscribeToNew']>(
    (serviceId, callback) => {
      if (!_.includes(serviceIds, serviceId)) {
        refetch()
      }

      setCallbacks((prev) => ({ ...prev, [serviceId]: callback }))
    },
    [refetch, serviceIds]
  )

  const refetchUnreadCount = useCallback<
    IChatContext['refetchUnreadCount']
  >(() => {
    for (const refetch of Object.values(refetchUnreadCountFunctions)) {
      refetch()
    }
  }, [refetchUnreadCountFunctions])

  return (
    <ChatContext.Provider
      value={{
        subscribeToNew,
        unreadCountPerService,
        servicesJoined,
        servicesOwned,
        refetchUnreadCount
      }}
    >
      {serviceIds.map((id) => {
        return (
          <ServiceSubscription
            key={id}
            serviceId={id}
            onMessage={(message) => {
              const serviceCallback = callbacks[id]
              serviceCallback?.(id, message)
            }}
            setUnreadCount={(count) => {
              setUnreadCountPerService((prev) => ({ ...prev, [id]: count }))
            }}
            storeRefetch={(refetch) =>
              setRefetchUnreadCountFunctions((prev) => ({
                ...prev,
                [id]: refetch
              }))
            }
          />
        )
      })}
      {children}
    </ChatContext.Provider>
  )
}
