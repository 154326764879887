import _ from 'lodash'
import moment from 'moment'

interface Message {
  id: string
  userId: string
  content: string
  createdAt: string | Date
  user: { avatar?: string | null; firstName: string; fullName: string }
}

export const formatMessages = (
  chatMessages: Message[],
  currentUserId: string
) => {
  const messages = _.map(
    _.zip([null, ...chatMessages.slice(0, -1)], chatMessages),
    ([lastMessage, message]) => {
      const content = message?.content ? JSON.parse(message.content) : null
      const isContinued =
        lastMessage !== null &&
        lastMessage?.userId === message?.userId &&
        !!content &&
        content.text !== undefined

      if (content.type === 'text' || content.text !== undefined)
        return formatTextMessage(message!, isContinued, currentUserId)
      if (content.type === 'notice') return formatNoticeMessage(message!)

      return null
    }
  )

  return messages
}

const formatTextMessage = (
  message: Message,
  isContinued: boolean,
  currentUserId: string
) => {
  const content = JSON.parse(message.content)
  const messageDate = new Date(message.createdAt)
  const formattedDate: string = moment(messageDate).calendar({
    sameDay: '[Today] HH:mm',
    lastDay: '[Yesterday] HH:mm',
    lastWeek: 'dddd HH:mm',
    sameElse: 'DD MMM HH:mm'
  })
  const formattedTime: string = moment(messageDate).format('HH:mm')
  const messageElem = (
    <div
      className={`message-container ${
        message.userId === currentUserId ? 'right-message' : 'left-message'
      } ${isContinued ? 'continued' : ''}
            `}
      key={message.id}
    >
      <div
        className="message-avatar"
        style={
          isContinued
            ? {}
            : {
                backgroundImage: `url("${message.user.avatar ?? ''}")`
              }
        }
      />
      <div className="message-bubble">
        {isContinued ? null : (
          <div className="message-header">
            <div className="message-sender">{message.user.firstName}</div>
            <div className="message-time">{formattedDate}</div>
          </div>
        )}
        <div className="message-content">{content.text}</div>
        {isContinued ? (
          <div className="message-timestamp">{formattedTime}</div>
        ) : null}
      </div>
    </div>
  )
  return messageElem
}

const formatNoticeMessage = (message: Message) => {
  const content = JSON.parse(message.content)
  let text = 'Unknown notice'
  if (content.notice === 'join_request') {
    text = `${message.user.fullName} has requested to join the chat`
  }
  const messageElem = (
    <div className="notice-message" key={message.id}>
      {text}
    </div>
  )
  return messageElem
}
