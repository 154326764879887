import { toast } from 'react-toastify'

const OPTIONS = {
  AUTOCLOSE: 10000,
  HIDEPROGRESSBAR: true
}

/**
 * When calling this const, the success toast will be presented to the user
 * @param {*} text - the text that will be presented inside the toast
 */
export const toastSuccess = (text: string) => {
  toast(text, {
    position: 'top-right',
    autoClose: OPTIONS.AUTOCLOSE,
    hideProgressBar: OPTIONS.HIDEPROGRESSBAR,
    className: 'toast toast-success'
  })
}

/**
 * When calling this const, the error toast will be presented to the user
 * @param {*} text - the text that will be presented inside the toast
 */
export const toastError = (text: string) => {
  toast(text, {
    position: 'top-right',
    autoClose: OPTIONS.AUTOCLOSE,
    hideProgressBar: OPTIONS.HIDEPROGRESSBAR,
    className: 'toast toast-error'
  })
}
