import { Table } from '@/assets/landing_page/documents/Illustrations'
import { Footer } from '@/components/Footer'
import { PublicNavbar } from '@/components/PublicNavbar'
import './style.css'

export const Cookies: React.FC = () => (
  <div>
    <PublicNavbar lightBg />
    <div className="public-page">
      <h2 className="title">Cookie Richtlinien</h2>
      <div>
        <h3 className="section-title">Was ist ein Cookie?</h3>
        <p>
          Ein Cookie ist eine Textdatei, die nach Ihrer Wahl in einem speziellen
          Bereich der Festplatte Deines Endgeräts hinterlegt werden kann und die
          bestimmte personenbezogene Daten über Dich erfasst. Wenn Du dich auf
          unserer Website anmeldest, können wir nach Deiner Wahl verschiedene
          Cookies auf Deinem Endgerät setzen, die es uns während der
          Gültigkeitsdauer des betreffenden Cookies ermöglichen, den Browser
          Deines Endgeräts zu erkennen.
        </p>
      </div>
      <div>
        <h3 className="section-title">Von wem werden die Cookies abgelegt?</h3>
        <p>
          Cookies, die auf unserer Website platziert werden, können von Partizi
          oder von Dritten abgelegt werden. Von Partizi verwendete Cookies sind
          im Wesentlichen solche, die für den Betrieb unserer Website notwendig
          sind. Bei den anderen handelt es sich um Drittanbieter-Cookies, die
          von unseren Partnern oder von Werbeagenturen abgelegt werden. Die
          Platzierung und Verwendung von Cookies durch Dritte unterliegt den
          Cookie-Richtlinien dieser Dritten.
        </p>
      </div>
      <div>
        <h3 className="section-title">Wie und warum verwenden wir Cookies?</h3>
        <h5 className="sub-section-title">Technische Cookies</h5>
        <p>
          Dies sind Cookies, die für die Navigation auf unserer Website
          erforderlich sind (z.B. Sitzungs-IDs) und es Dir ermöglicht, die
          Hauptfunktionen der Website zu nutzen und Ihre Verbindung zu sichern.
        </p>
        <p>
          Sie ermöglichen Dir beispielsweise den direkten Zugriff auf die
          reservierten und persönlichen Bereiche unserer Website aufgrund von
          Identifikatoren oder Daten, die Du uns möglicherweise zuvor anvertraut
          haben. Ohne diese Cookies kannst Du die Website nicht normal nutzen,
          und wir raten davon ab, sie zu löschen.
        </p>
      </div>
      <div>
        <h3 className="section-title">Funktionale Cookies</h3>
        <p>
          Diese Cookies sind für Deine Navigation nicht zwingend erforderlich,
          ermöglichen es uns jedoch, die Funktionalität unserer Website zu
          optimieren und Ihnen Zugang zu bestimmten Funktionen zu geben. Sie
          ermöglichen es Dir auch, die grafische Darstellung der Website an die
          Anzeigepräferenzen Ihres Endgerätes anzupassen. Diese Cookies
          ermöglichen Ihnen eine reibungslose und individuelle Navigation.
        </p>
      </div>
      <div>
        <h3 className="section-title">Analytische Cookies</h3>
        <p>
          Dies sind Cookies, die es uns ermöglichen, die Nutzung und die
          Leistungsfähigkeit unserer Website zu kennen, Statistiken,
          Nutzungsvolumen und die Nutzung der verschiedenen Elemente unserer
          Website (besuchte Inhalte, Verlauf) zu erstellen, die es uns
          ermöglichen, die Interessensgebiete und die Benutzerfreundlichkeit
          unserer Dienstleistungen zu verbessern (die am häufigsten aufgerufenen
          Seiten oder Abschnitte, die am häufigsten gelesenen Artikel,….). Diese
          Cookies werden auch verwendet, um Besucher einer Seite zu zählen.
        </p>
      </div>
      <div>
        <h3 className="section-title">Werbe-Cookies</h3>
        <p>
          Dies sind die Cookies, die verwendet werden, um Ihnen Angebote und
          Informationen entsprechend Deinem Interesse auf unserer Website (oder
          außerhalb unserer Website beim Surfen im Internet) zu präsentieren.
          Die Ablehnung dieser Cookies hat keinen Einfluss auf die Nutzung
          unserer Website. Die Ablehnung von Werbe-Cookies bedeutet jedoch
          nicht, dass die Werbung auf unserer Website oder im Internet
          eingestellt wird. Es wird Ihnen nur eine Anzeige angezeigt, die nicht
          Deinem Interessen oder Vorlieben widerspiegelt.
        </p>
      </div>
      <div>
        <h3 className="section-title">Soziale Cookies (“Social Plugins”)</h3>
        <p>
          Unsere Website enthält Elemente von Dritten (Anwendungsbuttons, sog.
          „Plugins“), die es dem Nutzer ermöglichen, über soziale Netzwerke
          Inhalte unserer Website oder seine Meinung zu teilen. Dies ist der
          Fall bei den Buttons „Share“, „Like“, „Tweet“ aus sozialen Netzwerken
          wie Facebook, Twitter etc. Wenn Du mit solchen Plugins der sozialen
          Netzwerke interagieren, z.B. durch das Anklicken von „Gefällt mir“
          oder das Hinterlassen eines Kommentars, werden die entsprechenden
          Informationen an das soziale Netzwerk weitergeleitet und in Ihrem
          Profil veröffentlicht. Wenn Du während des Besuchs unserer Website mit
          dem sozialen Netzwerk verbunden bist, kannst Du über die
          Teilen-Buttons die aufgerufenen Inhalte unserer Website mit Ihrem
          Benutzerkonto verknüpfen. Wenn Du nicht möchtest, dass das soziale
          Netzwerk die über unsere Website gesammelten Informationen mit Deinem
          Benutzerkonto verknüpft, musst Du dich vorher aus dem sozialen
          Netzwerk ausloggen. Wir empfehlen Dir, die Datenschutzrichtlinien
          dieser sozialen Netzwerke zu lesen, um Informationen über die Zwecke
          der Nutzung, der Werbung und der Navigationsinformationen zu erhalten,
          die sie aufgrund dieser Anwendungsbuttons sammeln können.
        </p>
        <p>
          Rechtsgrundlage für die Datenverarbeitung im Zusammenhang mit den
          Cookies auf unserer Website ist Art. 6 Abs. 1 S. 1 lit. f. DSGVO.
          Unser berechtigtes Interesse an der Verarbeitung ist die Ermöglichung
          der Nutzung unserer Website, die Bereitstellung unserer Dienste, die
          laufende Verbesserung unserer Website und insbesondere die
          kontinuierliche Ausrichtung unseres Angebots anhand Ihrer Interessen,
          um den Nutzerkomfort bei Nutzung unseres Angebots fortlaufend zu
          verbessern und zu optimieren. Soweit hierbei personenbezogene Daten in
          Länder außerhalb der EU bzw. des EWR übermittelt werden, geschieht
          dies ausschließlich unter Einhaltung der Voraussetzungen der Art. 44
          ff. DSGVO, die sicherstellen, dass beim Empfänger der Daten ein
          angemessenes Datenschutzniveau gewährleistet ist.
        </p>
      </div>
      <div>
        <h3 className="section-title">Recht auf Widerspruch gegen Cookies</h3>
        <p>
          Cookies verbessern Deine Nutzerkomfort beim Surfen auf unserer
          Website, Du kannst sie aber jederzeit deaktivieren. Dein Browser kann
          auch so eingestellt werden, dass er Sie über Cookies informiert, die
          auf Ihrem Computer gespeichert werden und Sie auffordert, diese zu
          akzeptieren oder nicht. Sie können Cookies individuell akzeptieren
          oder ablehnen oder Cookies komplett ablehnen. Wir weisen Sie jedoch
          darauf hin, dass die Einstellung die Voraussetzungen Ihres Zugangs zu
          unseren Inhalten ändern kann und dass die Verwendung von Cookies für
          die Nutzung unserer Dienste erforderlich ist. Um die Verwaltung von
          Cookies in Deinem Browser zu ändern, kannst Du die die Einstellungen
          in Deinem Datenschutz-Menü ändern. Die Konfiguration der einzelnen
          Browser ist unterschiedlich. Im Hilfemenü Ihres Browsers wird
          beschrieben, wie Du Deine Einstellungen in Bezug auf Cookies
          entsprechend Deinen Wünschen ändern kannst. Alle weiteren Rechte, die
          Dir als Betroffener der Datenverarbeitung zustehen, entnimmst Du bitte
          unserer Datenschutzerklärung.
        </p>
        <div className="mt-8">
          <p>Internet Explorer:</p>
          <p className="mb-2">
            <a
              href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noreferrer"
            >
              https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </p>
          <p>Firefox:</p>
          <p className="mb-2">
            <a
              href="https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop"
              target="_blank"
              rel="noreferrer"
            >
              https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop
            </a>
          </p>
          <p>Safari:</p>
          <p className="mb-2">
            <a
              href="https://support.apple.com/kb/PH5042?locale=en_US"
              target="_blank"
              rel="noreferrer"
            >
              https://support.apple.com/kb/PH5042?locale=en_US
            </a>
          </p>
          <p>Google Chrome:</p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=de"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/chrome/answer/95647?hl=de
            </a>
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <img src={Table} alt="integrations table" />
      </div>
    </div>
    <Footer />
  </div>
)
