import { FbLogo, IgLogo } from '@/assets/landing_page/footer/icons'
import { LemonWayLogo, PartiziLogo } from '@/assets/landing_page/footer/logos'
import { T } from '@tolgee/react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export const Footer: React.FC = () => {
  const navigate = useNavigate()

  const separator = (
    <div className="text-xs text-zircon font-medium mx-3">|</div>
  )

  return (
    <div className="bg-tuna flex justify-center">
      <div className="flex justify-between w-full px-10 my-7 mx-10 max-md:mx-0 max-md:px-6 max-md:flex-col">
        <div className="flex flex-col">
          <div className="text-left mb-7">
            <img
              onClick={() => navigate('/')}
              src={PartiziLogo}
              alt="partizi logo"
              className="cursor-pointer"
            />
          </div>
          <div>
            <div className="flex mb-2">
              <div className="text-xs text-zircon font-medium">
                <a
                  href={`${window.location.origin}/impressum`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <T keyName="components.landingPage.footer.impressum" />
                </a>
              </div>
              {separator}
              <div className="text-xs text-zircon font-medium">
                <a
                  href="https://blog.partizi.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <T keyName="components.landingPage.footer.blog" />
                </a>
              </div>
              {separator}
              <div className="text-xs text-zircon font-medium">
                <a
                  href={`${window.location.origin}/terms-conditions`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <T keyName="components.landingPage.footer.terms" />
                </a>
              </div>
              {separator}
              <div className="text-xs text-zircon font-medium">
                <a
                  href={`${window.location.origin}/privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <T keyName="components.landingPage.footer.privacyPolicy" />
                </a>
              </div>
              {separator}
              <div className="text-xs text-zircon font-medium">
                <a
                  href={`${window.location.origin}/gdpr`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <T keyName="components.landingPage.footer.gdpr" />
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="text-xs text-silver font-medium">
                {`© ${moment().get('year')} `}
                <T keyName="components.landingPage.footer.rights" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-grow justify-end gap-10 max-md:flex-col max-md:gap-6 max-md:mt-6">
          <div className="flex flex-col">
            <h5 className="text-sm text-zircon font-medium mb-5 max-md:mb-2">
              <T keyName="components.landingPage.footer.payments" />
            </h5>
            <div>
              <a
                href={'https://www.lemonway.com/en'}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={LemonWayLogo} alt="" />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <h5 className="text-zircon text-sm font-medium mb-5 max-md:mb-2">
              <T keyName="components.landingPage.footer.updates" />
            </h5>
            <div className="flex gap-5">
              <div>
                <a
                  href="https://www.facebook.com/Partizi.de/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="flex"
                >
                  <div className="mr-2">
                    <FbLogo />
                  </div>
                  <div className="flex flex-col justify-center text-xs text-zircon font-light">
                    /partizi.de
                  </div>
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/partizi.de/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="flex"
                >
                  <div className="mr-2">
                    <IgLogo />
                  </div>
                  <div className="flex flex-col justify-center text-xs text-zircon font-light">
                    @partizi.de
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h5 className="text-zircon text-left text-sm font-medium mb-4 max-md:mb-2">
              <T keyName="components.landingPage.footer.address" />
            </h5>
            <div className="text-zircon text-left text-xs font-light max-w-52">
              Partizi UG (haftungsbeschränkt) Ostendorferstr.13a, 27726
              Worpswede, Germany
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
