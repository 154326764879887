import { AddCard, CreditCardProvider } from '@/assets/illustrations'
import { Button } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { useMutation } from '@apollo/client'
import { T } from '@tolgee/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const PAYMENT_URL = gql(`
  mutation addCard {
    paymentURL: addCard
  }
`)

export const NewCard: React.FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [getPaymentURL] = useMutation(PAYMENT_URL)

  const startPaymentProcess = async () => {
    setLoading(true)
    const { data } = await getPaymentURL()
    if (data) window.location.href = data.paymentURL
  }

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        {!loading ? (
          <>
            <div className="flex justify-center mb-4">
              <img
                src={AddCard}
                alt="transfer money"
                className="max-w-[150px]"
              />
            </div>
            <div className="text-2xl mb-4">
              <h1>
                <T keyName="components.payments.paymentMethods.popup.newCard.title" />
              </h1>
            </div>
            <div className="text-sm text-gray-shuttle-soft mb-6">
              <T keyName="components.payments.paymentMethods.popup.newCard.subtitle" />
            </div>
            <div className="flex justify-center gap-6 mt-8">
              <Button
                onClick={() => navigate(-1)}
                className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
              >
                <T keyName="components.payments.paymentMethods.popup.newCard.btnCancel" />
              </Button>
              <Button
                onClick={startPaymentProcess}
                className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
              >
                <T keyName="components.payments.paymentMethods.popup.newCard.btnVerify" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <Spinner />
            <div className="flex justify-center">
              <img src={CreditCardProvider} alt="" className="max-w-30 mb-4" />
            </div>
            <h6 className="text-gray-shuttle-soft text-xs">
              <T keyName="components.joinSubscription.steps.paymentMethod.bnpInfo" />
            </h6>
          </>
        )}
      </div>
    </PopupSkeleton>
  )
}
