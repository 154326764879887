import { HourGlass } from '@/assets/illustrations'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { T } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'

export const DocsOnHold: React.FC = () => {
  const navigate = useNavigate()

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={HourGlass} alt="" />
        </div>
        <h1 className="text-2xl mb-4">
          <T keyName="components.payments.wallet.docsOnHold.title" />
        </h1>
        <h6 className="text-sm text-gray-shuttle mb-4">
          <T keyName="components.payments.wallet.docsOnHold.note" />
        </h6>
        <h6 className="text-sm text-gray-shuttle">
          <T
            keyName="components.payments.wallet.docsOnHold.advise"
            params={{
              support: (
                <a href="mailto:team@partizi.de" className="text-blue-dodger">
                  Partizi's support
                </a>
              )
            }}
          />
        </h6>

        <div className="flex justify-center mt-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T keyName="components.payments.wallet.docsOnHold.btnOk" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
