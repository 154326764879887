import { Close } from '@/assets/icons'
import { useNavigate } from 'react-router-dom'
import { SubscriptionPopupSidebar } from './Sidebar'

export const SubscriptionPopupContainer: React.FC<{
  type: 'join' | 'offer'
  children: JSX.Element
  providerId?: string
  owner?: {
    fullName: string
    avatar?: string | null
  }
  showCloseButton?: boolean
}> = ({ type, children, providerId, owner, showCloseButton = true }) => {
  const navigate = useNavigate()

  return (
    <div
      className="w-[880px] min-h-[625px] flex items-stretch relative
        max-md:w-full max-md:h-full max-md:flex-col"
    >
      <SubscriptionPopupSidebar
        type={type}
        providerId={providerId}
        owner={owner}
        showCloseButton={showCloseButton}
      />

      <div className="bg-white rounded-r-xl relative max-md:rounded-r-none max-md:flex-grow">
        <div
          className="absolute cursor-pointer top-6 right-6 z-10
          [&_circle]:hover:fill-pink-brink [&_circle]:hover:stroke-pink-brink
          [&_path]:hover:stroke-white max-md:hidden max-md:top-4 max-md:right-4"
        >
          <Close
            onClick={() => navigate('/overview/subscriptions')}
            className="max-md:w-8 max-md:h-8"
          />
        </div>
        <div className="md:min-w-[580px] h-full py-14 px-10 flex flex-col max-md:py-8 max-md:px-4">
          {children}
        </div>
      </div>
    </div>
  )
}
