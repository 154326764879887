import { ArrowBack, ArrowSecondary } from '@/assets/icons'
import { Avatar1 } from '@/assets/images'
import { ImgProfile } from '@/components/ImgProfile'
import { Spinner } from '@/components/Spinner'
import { TabsHeader } from '@/components/Tabs/TabsHeader'
import { gql } from '@/gql'
import { AuthContext } from '@/providers/Auth/context'
import { useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import { useContext, useEffect } from 'react'
import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams
} from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import invariant from 'tiny-invariant'

const SERVICE_DETAILS = gql(`
  query getBasicServiceDetails($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      provider {
        name
        brand
      }
      owner {
        id
        firstName
        avatar
      }
      slots {
        usedBy {
          id
          avatar
        }
      }
    }
  }
`)

const subRoutesOffer = (id: string) => [
  {
    path: `/overview/subscriptions/offer/${id}/overview`,
    label: 'tabs.overview'
  },
  {
    path: `/overview/subscriptions/offer/${id}/chat`,
    label: 'tabs.chat',
    showNotification: true
  },
  {
    path: `/overview/subscriptions/offer/${id}/requests`,
    label: 'tabs.requests'
  },
  {
    path: `/overview/subscriptions/offer/${id}/transactions`,
    label: 'tabs.transactions'
  },
  {
    path: `/overview/subscriptions/offer/${id}/details`,
    label: 'tabs.details'
  }
]

const subRoutesJoin = (id: string) => [
  {
    path: `/overview/subscriptions/join/${id}/service-details`,
    label: 'tabs.serviceDetails'
  },
  {
    path: `/overview/subscriptions/join/${id}/chat`,
    label: 'tabs.chat',
    showNotification: true
  },
  {
    path: `/overview/subscriptions/join/${id}/transactions`,
    label: 'tabs.outgoingPayments'
  }
]

export const SubscriptionContainerPage: React.FC<{
  type: 'join' | 'offer'
}> = ({ type }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const outlet = useOutlet()

  const { id } = useParams<{ id: string }>()
  if (!id) navigate('/overview/subscriptions')

  useEffect(() => {
    if (!outlet)
      navigate(
        `/overview/subscriptions/${type}/${id}/${
          type === 'offer' ? 'overview' : 'service-details'
        }`,
        { state: state }
      )
  }, [id, navigate, outlet, type, state])

  const { currentUser } = useContext(AuthContext)!

  const { data, loading } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId: id! }
  })

  if (loading) return <Spinner />

  invariant(data, 'Data should be resolved')

  const service = data.service
  const serviceName = service.provider.name
  const serviceIcon = service.provider.brand

  const usedSlots = service.slots.filter((s) => !!s.usedBy)

  return (
    <div className="mx-auto max-w-7xl py-16 px-20 max-md:p-4">
      <div className="flex items-center gap-4 mb-12 max-md:mb-4">
        <div className="cursor-pointer [&_polyline]:stroke-white [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger -ml-14 mr-2 max-md:ml-4">
          <ArrowBack
            onClick={() => navigate('/overview/subscriptions')}
            className="max-md:w-8 max-md:h-8"
          />
        </div>

        <div
          className="border border-gray-400 rounded flex justify-center
                w-[70px] h-[70px] max-md:w-12 max-md:h-12"
        >
          <img
            src={serviceIcon}
            alt={serviceName}
            className="max-w-[46px] max-h-[46px] m-auto"
          />
        </div>

        <div className="flex flex-col max-md:-ml-[26px]">
          <h1 className="text-2xl font-medium max-md:hidden">
            <T
              keyName={`components.${type}Subscription.${type}Details.title`}
              params={{
                owner: () => `${service.owner.firstName}'s`,
                plan: () => (
                  <span className="text-base text-gray-shuttle-soft font-normal">
                    {`/ ${serviceName}`}
                  </span>
                )
              }}
            />
          </h1>
          <div className="flex gap-2 items-center">
            <ImgProfile
              img={service.owner.avatar ?? Avatar1}
              className={twJoin(
                'w-8 h-8 border border-transparent shadow-[0_0_0_1px]',
                type === 'offer' ? 'shadow-blue-dodger/50' : 'shadow-white'
              )}
            />
            <ArrowSecondary />
            {usedSlots.length > 0 ? (
              <div className="flex [&>div:not(:first-child)]:-ml-[6px]">
                {usedSlots.map((slot, idx) => (
                  <ImgProfile
                    img={Avatar1}
                    key={slot.usedBy?.id}
                    className={twJoin(
                      'w-8 h-8 border border-transparent shadow-[0_0_0_1px]',
                      slot.usedBy?.id === currentUser?.id
                        ? 'shadow-blue-dodger/50'
                        : 'shadow-white'
                    )}
                    style={{
                      zIndex: service.slots.length - idx
                    }}
                  />
                ))}
              </div>
            ) : (
              <span className="text-gray-shuttle-soft">
                <T keyName="components.offerSubscription.offerDetails.noSubscribers" />
              </span>
            )}
          </div>
        </div>
      </div>

      <TabsHeader
        routes={type === 'offer' ? subRoutesOffer(id!) : subRoutesJoin(id!)}
        type={type}
        serviceId={id!}
      />

      <div className="w-full mt-8 max-md:px-4">{outlet}</div>
    </div>
  )
}
