import {
  FacebookMedia,
  Rewards as RewardsIcon,
  TwitterMedia,
  WhatsappMedia
} from '@/assets/icons'
import { BringFriends, Referrals } from '@/assets/illustrations'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { AuthContext } from '@/providers/Auth/context'
import { writeToClipboard } from '@/utils/clipboard.ts'
import { useQuery } from '@apollo/client'
import { T, useTranslate } from '@tolgee/react'
import { useContext } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'
import invariant from 'tiny-invariant'

const REFERRAL_TRANSACTIONS = gql(`
  query userReferralsTransactions(
    $receiverId: String!
    $skip: Float
    $after: TransactionWhereUniqueInput
    $first: Float
  ) {
    referrals: transactions(
      where: { type: REFERRAL, receiverId: $receiverId }
      orderBy: { createdAt: desc }
      skip: $skip
      after: $after
      first: $first
    ) {
      id
      sender {
        avatar
        fullName
        email
      }
      amount
      type
    }
  }
`)

export const Rewards: React.FC = () => {
  const { t } = useTranslate()

  const { currentUser } = useContext(AuthContext)!
  invariant(currentUser, 'User must be logged in')
  const { loading } = useQuery(REFERRAL_TRANSACTIONS, {
    variables: {
      receiverId: currentUser?.id,
      first: 10
    }
  })

  const referralLink = `${import.meta.env.VITE_REFERRAL_BASE_URL}${currentUser?.referralCode}`

  return (
    <div className="mx-auto max-w-4xl py-16 px-8 max-md:p-4">
      <div className="flex items-center gap-4 mb-20 max-md:hidden">
        <RewardsIcon />
        <h1 className="text-2xl font-medium">
          <T keyName="components.rewards.title" />
          <span className="ml-2 text-base">
            {currentUser?.referralTotalEarns.toFixed(2)} EUR
          </span>
        </h1>
      </div>

      <div className="bg-orange-light flex flex-row mb-8 rounded max-md:flex-col-reverse">
        <div className="p-[40px_0_40px_40px] max-md:p-[24px_16px_24px_16px]">
          <div className="text-gray-100 text-2xl mb-4 font-medium">
            <T keyName="components.rewards.bringFriends.title" />
          </div>
          <div className="text-sm text-gray-100 mb-6">
            <T keyName="components.rewards.bringFriends.subtitle" />
          </div>
          <div className="flex flex-row mb-8 bg-white rounded max-h-8 justify-between items-center">
            <input
              className="text-gray-shuttle text-xs p-2 flex-grow"
              type="text"
              value={referralLink}
              disabled={true}
            />
            <span
              onClick={() => writeToClipboard(referralLink)}
              className="cursor-pointer text-blue-dodger text-xs pr-2"
            >
              <T keyName="components.rewards.bringFriends.copy" />
            </span>
          </div>
          <div className="flex max-md:flex-col items-center">
            <div className="text-xs text-gray-shuttle mr-4 max-md:mr-0 max-md:mb-2">
              <T keyName="components.rewards.bringFriends.socialInfo" />
            </div>
            <div className="flex gap-2">
              <FacebookShareButton url={referralLink} hashtag={'#partizi'}>
                <FacebookMedia />
              </FacebookShareButton>
              <WhatsappShareButton
                url={referralLink}
                title={t(
                  'components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.shareQuote'
                )}
              >
                <WhatsappMedia />
              </WhatsappShareButton>
              <TwitterShareButton
                url={referralLink}
                hashtags={['partizi']}
                title={t(
                  'components.joinSubscription.joinDetails.serviceDetails.inviteFriendsReferral.shareQuote'
                )}
              >
                <TwitterMedia />
              </TwitterShareButton>
            </div>
          </div>
        </div>
        <div className="p-[66px_24px_66px_38px] max-md:p-[24px_0_0_0] flex justify-center">
          <img src={BringFriends} alt="" />
        </div>
      </div>

      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4">
        <div>
          <h1 className="text-lg mb-4 font-medium">
            <T keyName="components.rewards.referrals.title" />
          </h1>

          {!loading ? (
            // TODO: Referrals not implemented yet
            <>
              <h2 className="text-sm mb-6">
                <T keyName="emptyStates.referrals.text" />
              </h2>
              <img src={Referrals} alt="No referrals" />
            </>
          ) : (
            <Spinner />
          )}
        </div>

        <div className="bg-blue-dodger/20 flex justify-between items-center h-fit p-6">
          <p className="text-sm">
            <T keyName="components.rewards.earned.info" />
          </p>
          <h1 className="text-2xl">
            € {currentUser?.referralTotalEarns.toFixed(2)}
          </h1>
        </div>
      </div>
    </div>
  )
}
