import { TextField, type TextFieldProps } from '@mui/material'
import { useTranslate } from '@tolgee/react'

export const TextInput: React.FC<
  {
    name: string
    // TODO: Weave in the correct type for formik
    // biome-ignore lint/suspicious/noExplicitAny: This is a formik prop.
    formik: any
    intlKey?: string
  } & TextFieldProps
> = ({ name, formik, intlKey, label, required = true, ...extraProps }) => {
  const { t } = useTranslate()

  return (
    <TextField
      name={name}
      label={label ? label : t(`form.inputs.labels.${intlKey ?? name}`)}
      placeholder={t(`form.inputs.placeholders.${intlKey ?? name}`)}
      fullWidth
      {...(required && { required })}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      {...extraProps}
    />
  )
}
