import { Settings } from '@/pages/overview/settings'
import { SettingsChangePassword } from '@/pages/overview/settings/popups/ChangePassword'
import { RemoveAccount } from '@/pages/overview/settings/popups/RemoveAccount'
import SettingsIcon from '@mui/icons-material/Settings'
import type { RouteObject } from 'react-router-dom'

export const settingsRoutes: RouteObject[] = [
  {
    path: 'settings',
    element: <Settings />,
    handle: {
      label: 'navbar.options.settings',
      icon: SettingsIcon
    },
    children: [
      {
        path: 'change-password',
        element: <SettingsChangePassword />
      },
      {
        path: 'remove-account',
        element: <RemoveAccount />
      }
    ]
  }
]
