import { createContext } from 'react'

export interface ChatMessage {
  id: string
  content: string
  createdAt: Date
  userId: string
  user: {
    firstName: string
    fullName: string
    avatar?: string | null
  }
}

export type ChatSubscribeCallback = (
  serviceId: string,
  message: ChatMessage
) => void

export interface IChatContext {
  unreadCountPerService: Record<string, number>
  servicesOwned: string[]
  servicesJoined: string[]
  subscribeToNew: (serviceId: string, callback: ChatSubscribeCallback) => void
  refetchUnreadCount: () => void
}

export const ChatContext = createContext<IChatContext | null>(null)
