import { KycType } from '@/gql/graphql.ts'

export const KycDocumentTypes = [
  {
    id: KycType.IbanProof,
    text: 'iban'
  },
  {
    id: KycType.IdCard,
    text: 'idCard'
  },
  {
    id: KycType.EuropeanPassport,
    text: 'passportEu'
  },
  {
    id: KycType.OutsideEuropePassport,
    text: 'passportNoEu'
  },
  {
    id: KycType.ResidencePermit,
    text: 'residencePermit'
  },
  {
    id: KycType.DriverLicense,
    text: 'driverLicense'
  }
]

export const KEYS = {
  DEVICE_ID: 'device-id',
  ACCESS_TOKEN: 'x-access-token',
  REFRESH_TOKEN: 'x-refresh-token'
}

export const GOOGLE_CLIENT_ID =
  '784129086898-uict3g4a9opn3a28et9n3mthh6vfc2m5.apps.googleusercontent.com'
