import { PasswordInput, TextInput } from '@/components/Inputs'
import { Button } from '@/components/Inputs/Button'
import { Spinner } from '@/components/Spinner'
import { AuthContext } from '@/providers/Auth/context'
import { Checkbox, FormControlLabel } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

export const SecondScreen: React.FC<{
  user: { firstName: string; lastName: string; avatar: string }
}> = ({ user }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const [error, setError] = useState<string | null>(null)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('form.inputs.errors.validEmail'))
      .required(t('form.inputs.errors.email')),
    password: yup.string().required(t('form.inputs.errors.password')).min(8),
    source: yup.string().required(t('form.inputs.errors.source')).min(3),
    acceptedTerms: yup
      .boolean()
      .oneOf([true], t('form.inputs.errors.acceptedTerms'))
  })

  const authCtx = useContext(AuthContext)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      acceptedTerms: false,
      source: ''
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      authCtx
        ?.signUp({
          ...user,
          ...values,
          referrerCode: localStorage.getItem('referrerCode') ?? undefined
        })
        .then(() => {
          formikHelpers.resetForm()
          formikHelpers.setSubmitting(false)
          navigate('/overview/subscriptions')
        })
        .catch((err) => {
          setError(err?.message ?? `${err}`)
          formikHelpers.setSubmitting(false)
        })
    }
  })

  const canSubmit = !(
    formik.isSubmitting ||
    formik.values.email === '' ||
    formik.values.password === '' ||
    !formik.isValid
  )

  const form = (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-4 items-center mt-8">
        <TextInput name="email" formik={formik} />
        <PasswordInput name="password" formik={formik} />
        <TextInput name="source" formik={formik} />
        <FormControlLabel
          control={
            <Checkbox
              name="acceptedTerms"
              value={formik.values.acceptedTerms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.acceptedTerms}
            />
          }
          label={
            <span className="text-xs text-gray-shuttle-soft">
              <T
                keyName="components.signup.steps.second.defaultTerms"
                params={{
                  a: () => (
                    <a
                      href="/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-dodger"
                    >
                      <T keyName="components.signup.steps.second.terms" />
                    </a>
                  ),
                  b: () => (
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-dodger"
                    >
                      <T keyName="components.signup.steps.second.privacy" />
                    </a>
                  )
                }}
              />
            </span>
          }
        />
        <Button type="submit" disabled={!canSubmit} className="mt-4 w-fit">
          <T keyName="components.signup.steps.first.button" />
        </Button>
        {error && (
          <div className="text-sm text-pink-brink text-center mt-3">
            <T keyName={error} defaultValue={error} />
          </div>
        )}
      </div>
    </form>
  )

  return (
    <div className="p-10 max-md:p-4">
      <div className="flex items-baseline mb-6 max-md:flex-col">
        <div className="text-gray-100 text-2xl mr-2">
          Hello {user.firstName}
        </div>
        <div className="text-gray-shuttle-soft text-sm">
          <T keyName="components.signup.steps.second.info" />
        </div>
      </div>
      <div>{formik.isSubmitting ? <Spinner /> : form}</div>
    </div>
  )
}
