import { Check, Close } from '@/assets/icons'
import {
  DocumentsCloud,
  DocumentsError,
  DocumentsSuccess,
  DocumentsWaiting,
  IbanCloud,
  IllustrationId1
} from '@/assets/illustrations'
import { TextInput } from '@/components/Inputs'
import { Button, LinkButton } from '@/components/Inputs/Button'
import { PopupOutlet } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { UploadStatus } from '@/gql/graphql.ts'
import { getErrorMessage } from '@/utils/errors.ts'
import { toastError } from '@/utils/toast'
import { useMutation, useQuery } from '@apollo/client'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import IBAN from 'iban'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import invariant from 'tiny-invariant'
import * as yup from 'yup'

const BANK_DETAILS = gql(`
  query getBankDetails {
    me {
      bankDetails {
        iban
        bic
        accountHolder
        bankName
      }
      documentsStatus
      firstDocumentType
      firstDocumentStatus
      ibanStatus
    }
  }
`)

const UPDATE_BANK_DETAILS = gql(`
  mutation updateUserBankDetails(
    $bankName: String!
    $iban: String!
    $bic: String!
    $accountHolder: String!
  ) {
    bankDetails: updateBankDetails(
      iban: $iban
      accountHolder: $accountHolder
      bic: $bic
      bankName: $bankName
    ) {
      iban
      bic
      accountHolder
      bankName
    }
  }
`)

export const BankDetails: React.FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { state } = useLocation()

  const { data, loading, refetch } = useQuery(BANK_DETAILS)
  const [updateBankDetails] = useMutation(UPDATE_BANK_DETAILS)

  useEffect(() => {
    if (state === 'refetch') refetch()
  }, [refetch, state])

  const validationSchema = yup.object().shape({
    accountHolder: yup
      .string()
      .matches(/^[a-zA-Z\s]*$/, t('form.inputs.errors.accountHolder'))
      .required(t('form.inputs.errors.accountHolder')),
    iban: yup
      .string()
      .test({
        name: 'iban',
        message: t('form.inputs.errors.iban'),
        test: (value) => IBAN.isValid(value ?? '')
      })
      .required(t('form.inputs.errors.iban')),
    bankName: yup
      .string()
      .matches(/^[a-zA-Z\s]*$/, t('form.inputs.errors.bankName'))
      .required(t('form.inputs.errors.bankName')),
    bic: yup
      .string()
      .matches(/[A-Z]{4}[A-Z]{2}[0-9A-Z]{2}[0-9A-Z]{0,3}$/)
      .required(t('form.inputs.errors.bic'))
  })

  const formik = useFormik({
    initialValues: {
      accountHolder: data?.me.bankDetails.accountHolder,
      iban: data?.me.bankDetails.iban,
      bankName: data?.me.bankDetails.bankName,
      bic: data?.me.bankDetails.bic
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      const { accountHolder, iban, bankName, bic } = values

      invariant(accountHolder, 'accountHolder is required')
      invariant(iban, 'iban is required')
      invariant(bankName, 'bankName is required')
      invariant(bic, 'bic is required')

      try {
        const result = await updateBankDetails({
          variables: {
            accountHolder,
            iban,
            bankName,
            bic
          }
        })

        invariant(result.data, 'data should be resolved')

        const res = result.data.bankDetails as Required<
          typeof result.data.bankDetails
        >

        formikHelpers.resetForm({
          values: {
            ...res
          }
        })
        formikHelpers.setSubmitting(false)
        navigate('documents-verification?iban')
      } catch (e: unknown) {
        formikHelpers.setSubmitting(false)
        console.error(e)
        toastError(getErrorMessage(e))
      }
    }
  })

  if (loading) return <Spinner />

  invariant(data, 'Data should be resolved')

  const {
    ibanStatus,
    documentsStatus,
    firstDocumentType,
    firstDocumentStatus
  } = data.me

  let _ibanStatus: 'pending' | 'success' | 'error' | undefined
  if (
    [UploadStatus.PendingVerification, UploadStatus.Awaiting].includes(
      ibanStatus!
    )
  ) {
    _ibanStatus = 'pending'
  } else if (
    [
      UploadStatus.Rejected,
      UploadStatus.RejectedDuplicate,
      UploadStatus.RejectedExpired,
      UploadStatus.RejectedUnreadable,
      UploadStatus.RejectedWrongName,
      UploadStatus.RejectedWrongType
    ].includes(ibanStatus!)
  ) {
    _ibanStatus = 'error'
  } else if (ibanStatus === UploadStatus.Accepted) {
    _ibanStatus = 'success'
  }

  let _documentsStatus: 'waiting' | 'success' | 'error' | 'untouched'
  switch (documentsStatus) {
    case UploadStatus.PendingVerification:
    case UploadStatus.Awaiting:
      _documentsStatus = 'waiting'
      break
    case UploadStatus.Untouched:
      _documentsStatus = 'untouched'
      break
    case UploadStatus.Accepted:
      _documentsStatus = 'success'
      break
    default:
      _documentsStatus = 'error'
      break
  }

  const isFormDisabled = ibanStatus === UploadStatus.AwaitingUpload
  const canSubmit = !(
    formik.isSubmitting ||
    !formik.isValid ||
    !IBAN.isValid(formik.values.iban!)
  )

  return (
    <div className="pt-8 flex gap-4 max-md:flex-col max-md:gap-16">
      <div
        className={twJoin(
          'basis-full flex flex-col relative box-border p-6',
          _ibanStatus === undefined && 'border border-dashed border-bombay',
          _ibanStatus === 'pending' && 'bg-orange-light',
          _ibanStatus === 'error' && 'bg-pippin',
          _ibanStatus === 'success' && 'bg-blue-dodger/20'
        )}
      >
        <div className="absolute top-[-46px] right-0">
          <img
            src={
              _ibanStatus === 'pending'
                ? DocumentsWaiting
                : _ibanStatus === 'error'
                  ? DocumentsError
                  : _ibanStatus === 'success'
                    ? DocumentsSuccess
                    : IbanCloud
            }
            alt=""
            className="w-[174px] h-[85px]"
          />
        </div>
        <h1 className="text-lg font-medium mb-2">
          <T keyName="components.payments.bankDetails.title" />
        </h1>
        <p className="text-gray-shuttle-soft text-sm">
          <T
            keyName={`components.payments.bankDetails.subTitle${
              _ibanStatus === 'pending'
                ? 'Pending'
                : _ibanStatus === 'error'
                  ? 'Error'
                  : _ibanStatus === 'success'
                    ? 'Success'
                    : ''
            }`}
          />
        </p>

        <form
          onSubmit={formik.handleSubmit}
          className="basis-full flex flex-col gap-4 mt-8"
        >
          <TextInput
            name="accountHolder"
            formik={formik}
            disabled={isFormDisabled}
          />
          <TextInput name="iban" formik={formik} disabled={isFormDisabled} />
          <TextInput
            name="bankName"
            formik={formik}
            disabled={isFormDisabled}
          />
          <TextInput name="bic" formik={formik} disabled={isFormDisabled} />

          {!isFormDisabled ? (
            <Button type="submit" className="mt-4 w-fit" disabled={!canSubmit}>
              <T keyName="components.payments.bankDetails.btn" />
            </Button>
          ) : (
            <LinkButton to="documents-verification?iban" className="w-fit mt-4">
              <T keyName="components.payments.bankDetails.btnVerify" />
            </LinkButton>
          )}
        </form>
      </div>

      <div
        className={twJoin(
          'basis-full flex flex-col relative box-border p-6 h-fit',
          _documentsStatus === 'untouched' &&
            'border border-dashed border-bombay',
          _documentsStatus === 'waiting' && 'bg-orange-light',
          _documentsStatus === 'error' && 'bg-pippin',
          _documentsStatus === 'success' && 'bg-blue-dodger/20'
        )}
      >
        <div className="absolute top-[-46px] right-0">
          <img
            src={
              _documentsStatus === 'untouched'
                ? DocumentsCloud
                : _documentsStatus === 'waiting'
                  ? DocumentsWaiting
                  : _documentsStatus === 'success'
                    ? DocumentsSuccess
                    : DocumentsError
            }
            alt=""
            className="w-[174px] h-[85px]"
          />
        </div>
        <h1 className="text-lg font-medium mb-2">
          <T
            keyName={`components.payments.bankDetails.documentsVerification.${_documentsStatus}.title`}
          />
        </h1>
        {_documentsStatus === 'untouched' || _documentsStatus === 'error' ? (
          <p className="text-gray-shuttle-soft text-sm">
            <T
              keyName={`components.payments.bankDetails.documentsVerification.${_documentsStatus}.subTitle`}
            />
          </p>
        ) : (
          <>
            <p className="text-gray-shuttle-soft text-sm">
              <T
                keyName={`components.payments.bankDetails.documentsVerification.${_documentsStatus}.firstParagraph`}
              />
            </p>
            <p className="text-gray-shuttle-soft text-sm">
              <T
                keyName={`components.payments.bankDetails.documentsVerification.${_documentsStatus}.secondParagraph`}
              />
            </p>
          </>
        )}

        {_documentsStatus !== 'untouched' && (
          <div
            className={twJoin(
              'flex items-center mt-6',
              _documentsStatus !== 'success' &&
                _documentsStatus !== 'error' &&
                'opacity-50'
            )}
          >
            <img src={IllustrationId1} alt="" className="max-w-[55px] mr-6" />
            <div className="flex-grow flex flex-col justify-evenly">
              <p className="font-medium text-sm text-gray-100">
                <T
                  keyName={`components.payments.bankDetails.documentsVerification.documents.${
                    firstDocumentType ?? 'AWAITING'
                  }`}
                />
              </p>
              {firstDocumentType && (
                <p className="text-gray-700 text-xs">
                  <T
                    keyName={`components.payments.bankDetails.documentsVerification.documents.${firstDocumentStatus}`}
                  />
                </p>
              )}
            </div>
            <div>
              {_documentsStatus === 'error' ? (
                <Close className="[&_circle]:fill-pink-brink [&_circle]:stroke-pink-brink [&_path]:stroke-white" />
              ) : _documentsStatus === 'success' ? (
                <Check className="[&_circle]:fill-blue-dodger [&_circle]:stroke-blue-dodger [&_path]:stroke-white" />
              ) : (
                <div className="w-6 h-6 rounded-full bg-orange" />
              )}
            </div>
          </div>
        )}

        {_documentsStatus === 'untouched' && (
          <LinkButton to="documents-verification" className="w-fit mt-8">
            <T keyName="components.payments.bankDetails.documentsVerification.untouched.btn" />
          </LinkButton>
        )}

        {_documentsStatus === 'error' && (
          <LinkButton
            to="documents-verification/guidelines"
            className="w-fit mt-8 border-pink-brink text-pink-brink hover:bg-pink-brink"
          >
            <T keyName="components.payments.bankDetails.documentsVerification.error.btnText" />
          </LinkButton>
        )}
      </div>
      <PopupOutlet />
    </div>
  )
}
