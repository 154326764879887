import { Avatar1 } from '@/assets/images'
import { twMerge } from 'tailwind-merge'

interface IImgProfileProps {
  img?: string | null
  className?: string
  style?: React.CSSProperties
}

export const ImgProfile: React.FC<IImgProfileProps> = ({
  img,
  className,
  style
}) => {
  return (
    <div
      className={twMerge(
        'm-1 rounded-full h-20 w-20 overflow-hidden',
        className
      )}
      style={style}
    >
      <div
        className="w-full h-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url("${img ?? Avatar1}")` }}
      />
    </div>
  )
}
