import {
  Bear,
  BottomCloud,
  TopCloud
} from '@/assets/landing_page/testimonials/illustrations'
import { ImgProfile } from '@/components/ImgProfile'
import { useScreenDetector } from '@/hooks/ScreenDetector'
import Carousel, {
  arrowsPlugin,
  autoplayPlugin,
  slidesToShowPlugin
} from '@brainhubeu/react-carousel'
import { T } from '@tolgee/react'
import '@brainhubeu/react-carousel/lib/style.css'
import { Girl, Man } from '@/assets/landing_page/testimonials/avatar'
import { ArrowLeft, ArrowRight } from '@/assets/landing_page/testimonials/icons'

const Testimonial: React.FC<{
  userAvatar: string
  username: string
  text: string | JSX.Element
}> = ({ userAvatar, text, username }) => {
  const { isBig } = useScreenDetector()
  return (
    <div className="max-w-[338px] m-auto w-full flex max-md:max-w-[275px]">
      <div className="m-auto mr-4 max-md:mr-3">
        <ImgProfile
          className={isBig ? 'w-[134px] h-[134px]' : 'w-[76px] h-[76px]'}
          img={userAvatar}
        />
      </div>
      <div className="text-left flex-col justify-center">
        <div className="text-zircon text-sm leading-5 italic font-medium mb-2">
          {text}
        </div>
        <div className="text-zircon text-xs leading-3">{username}</div>
      </div>
    </div>
  )
}

const TestimonialsCarousel: React.FC = () => {
  const { isBig } = useScreenDetector()
  return (
    <div className="w-full">
      <Carousel
        className="flex-grow mx-5 cursor-pointer"
        itemWidth={400}
        plugins={
          isBig
            ? [
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 2
                  }
                }
              ]
            : [
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 4
                  }
                },
                'centered',
                'infinite',
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 3000,
                    stopAutoPlayOnHover: true
                  }
                },
                {
                  resolve: arrowsPlugin,
                  options: {
                    arrowRight: <img src={ArrowRight} alt="arrow left" />,
                    arrowLeft: <img src={ArrowLeft} alt="arrow right" />,
                    addArrowClickHandler: true
                  }
                }
              ]
        }
      >
        <Testimonial
          text={
            <T keyName="components.landingPage.testimonials.cards.first.text" />
          }
          userAvatar={Man}
          username={'Michael Kluge'}
        />
        <Testimonial
          text={
            <T keyName="components.landingPage.testimonials.cards.second.text" />
          }
          userAvatar={Girl}
          username={'Julia Hoffmann'}
        />
      </Carousel>
    </div>
  )
}

export const Testimonials: React.FC = () => {
  return (
    <div
      className="flex flex-col max-w-[871px] w-full h-[534px] mx-auto mb-[58px] relative
        max-md:overflow-x-visible max-md:pb-6 max-md:mb-3 max-md:max-w-[414px] max-sm:overflow-x-hidden"
    >
      <div
        className="flex justify-end max-w-[568px] w-full max-h-[213px] h-full relative mx-auto
          max-md:flex-col max-md:justify-center max-md:max-w-[327px] max-md:max-h-[313px]"
      >
        <div
          className="max-w-[416px] w-full max-h-[183px] h-full bg-zircon rounded-[10px]
            shadow-[0_2px_40px_0] shadow-black/10 max-md:mt-[135px]"
        >
          <div
            className="text-left flex flex-col justify-center max-w-64
              m-[40px_0_0_115px] max-md:m-[40px_32px_32px_38px]"
          >
            <h6 className="text-gray leading-[26px] italic font-medium mb-3">
              <T keyName="components.landingPage.testimonials.quote" />
            </h6>
            <p className="text-silver leading-4 text-sm font-medium">
              <T keyName="components.landingPage.testimonials.team" />
            </p>
          </div>
        </div>
        <div className="absolute top-0 left-0 max-md:-left-1/2 max-md:-translate-x-1/2">
          <img
            src={Bear}
            alt=""
            className="max-md:w-[221px] max-md:h-[159px]"
          />
        </div>
        <div
          className="absolute bottom-0 left-[100px] max-md:bottom-auto max-md:right-5
            max-md:top-[100px]"
        >
          <img
            src={TopCloud}
            alt=""
            className="max-md:w-[120px] max-md:h-[70px]"
          />
        </div>
      </div>
      <div
        className="h-[386px] max-w-[871px] w-full bg-blue-dodger rounded-2xl mt-[-90px]
          flex flex-col justify-center max-md:h-64 max-md:w-[414px] max-md:mt-[-38px] max-md:mx-auto"
      >
        <TestimonialsCarousel />
      </div>
      <div className="absolute bottom-0 right-0 max-md:right-[-10px]">
        <img src={BottomCloud} alt="" />
      </div>
    </div>
  )
}
