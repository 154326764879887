import { PersonalSettings } from '@/assets/icons'
import { AvatarPicker, Button, TextInput } from '@/components/Inputs'
import { PopupOutlet } from '@/components/Popup'
import { gql } from '@/gql'
import { WalletStatus } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { fileUrlToBase64 } from '@/utils'
import { toastError, toastSuccess } from '@/utils/toast'
import { useMutation } from '@apollo/client'
import { InputAdornment, TextField } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import { useContext, useState } from 'react'
import ReactImageUploading from 'react-images-uploading'
import { Link } from 'react-router-dom'
import invariant from 'tiny-invariant'
import * as yup from 'yup'

const UPDATE_USER = gql(`
  mutation updateUser(
    $firstName: String!
    $lastName: String!
    $zipCode: String!
    $country: String!
    $avatar: String!
    $username: String!
  ) {
    updateUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        zipCode: $zipCode
        country: $country
        avatar: $avatar
        username: $username
      }
    ) {
      firstName
      lastName
      country
      zipCode
      email
    }
  }
`)

export const Settings: React.FC = () => {
  const { t } = useTranslate()

  const [updateUser] = useMutation(UPDATE_USER)

  const validationSchema = yup.object().shape({
    username: yup.string().required(t('form.inputs.errors.username')),
    firstName: yup.string().required(t('form.inputs.errors.firstname')),
    lastName: yup.string().required(t('form.inputs.errors.lastname')),
    zipCode: yup.string().required(t('form.inputs.errors.zipcode')),
    country: yup.string().required(t('form.inputs.errors.country')),
    email: yup
      .string()
      .email(t('form.inputs.errors.email'))
      .required(t('form.inputs.errors.email'))
  })

  const { currentUser } = useContext(AuthContext)!

  const [avatar, setAvatar] = useState<string>(currentUser?.avatar ?? '')

  const formik = useFormik({
    initialValues: {
      username: currentUser?.username,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      zipCode: currentUser?.zipCode,
      country: currentUser?.country,
      email: currentUser?.email
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      invariant(values.username, 'username is required')
      invariant(values.firstName, 'firstName is required')
      invariant(values.lastName, 'lastName is required')
      invariant(values.zipCode, 'zipCode is required')
      invariant(values.country, 'country is required')
      invariant(values.email, 'email is required')

      try {
        const result = await updateUser({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            zipCode: values.zipCode,
            country: values.country,
            avatar:
              avatar.startsWith('/') || avatar.startsWith('http')
                ? await fileUrlToBase64(avatar)
                : avatar,
            username: values.username
          }
        })
        invariant(result.data, 'result from updateUser should be resolved')
        formikHelpers.resetForm({
          values: {
            ...result.data.updateUser,
            username: values.username,
            zipCode: result.data.updateUser.zipCode
          }
        })
        formikHelpers.setSubmitting(false)
        toastSuccess(t('toastMessages.personalSettings.success'))
      } catch (e) {
        console.error(e)
        toastError(t('toastMessages.personalSettings.error'))
      }
    }
  })

  return (
    <div className="py-10 px-20 max-md:px-4 max-md:py-4 lg:mr-24">
      <div className="flex items-center gap-4 mb-20 max-md:hidden">
        <PersonalSettings />
        <h1 className="text-2xl">
          <T keyName="components.settings.header.title" />
        </h1>
      </div>

      <form onSubmit={formik.handleSubmit} className="flex max-md:flex-col">
        <div className="flex-grow flex flex-col gap-8">
          <div>
            <h1 className="text-3xl font-bold mb-6">
              <T keyName="components.settings.profile" />
            </h1>
            <div className="flex max-md:flex-col gap-8">
              <div className="flex flex-col gap-4">
                <TextInput
                  name="username"
                  formik={formik}
                  disabled={
                    currentUser?.walletStatus === WalletStatus.RegisteredWithKyc
                  }
                />
                <TextInput
                  name="zipCode"
                  formik={formik}
                  intlKey="cityZipCode"
                  disabled={
                    currentUser?.walletStatus === WalletStatus.RegisteredWithKyc
                  }
                  required
                />
              </div>
              <div>
                <p className="text-xs text-gray-shuttle mb-4">
                  <T keyName="components.settings.userDetails.photo" />
                </p>
                <div className="flex items-center gap-2">
                  <AvatarPicker avatar={avatar} setAvatar={setAvatar} />
                  <div>
                    <ReactImageUploading
                      value={[]}
                      onChange={(imageList) => {
                        setAvatar(imageList[0].data_url)
                      }}
                      dataURLKey="data_url"
                      acceptType={['jpg', 'gif', 'png']}
                      maxFileSize={3145728} // 3MB
                      onError={(errors) => {
                        if (errors?.acceptType)
                          toastError(
                            'Only jpg, png or gif are accepted as file type'
                          )
                        if (errors?.maxFileSize)
                          toastError('The file must be smaller than 3MB')
                      }}
                    >
                      {({ onImageUpload }) => (
                        <p
                          onClick={onImageUpload}
                          className="text-blue-dodger text-sm"
                        >
                          <T keyName="components.settings.userDetails.upload" />
                        </p>
                      )}
                    </ReactImageUploading>
                    <p className="text-gray-shuttle text-sm">
                      <T keyName="components.settings.userDetails.tip" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h1 className="text-3xl font-bold mb-6">
              <T keyName="components.settings.account" />
            </h1>
            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-8 gap-y-4">
              <TextInput
                name="firstName"
                formik={formik}
                intlKey="firstname"
                disabled={
                  currentUser?.walletStatus === WalletStatus.RegisteredWithKyc
                }
              />
              <TextInput
                name="lastName"
                formik={formik}
                intlKey="lastname"
                disabled={
                  currentUser?.walletStatus === WalletStatus.RegisteredWithKyc
                }
              />
              <TextInput
                name="email"
                formik={formik}
                disabled={
                  currentUser?.walletStatus === WalletStatus.RegisteredWithKyc
                }
              />
              <TextField
                label={t('form.inputs.labels.password')}
                placeholder={t('form.inputs.placeholders.password')}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Link to="change-password">
                        <span className="text-blue-dodger text-xs">
                          <T keyName="components.settings.userDetails.change" />
                        </span>
                      </Link>
                    </InputAdornment>
                  )
                }}
                disabled
              />
            </div>
          </div>

          <div>
            <h1 className="text-3xl font-bold mb-6">
              <T keyName="components.settings.verification" />
            </h1>
            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-8 gap-y-4">
              <TextInput
                name="country"
                formik={formik}
                disabled={
                  currentUser?.walletStatus === WalletStatus.RegisteredWithKyc
                }
              />
            </div>
          </div>
        </div>

        <Button
          type="submit"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          className="mt-4 w-fit h-fit"
        >
          <T keyName="components.settings.userDetails.btn" />
        </Button>
      </form>

      <p className="text-sm mt-8 text-gray-shuttle">
        <T
          keyName="components.settings.userDetails.removeAccount"
          defaultValue="Want to fully remove your account from the Partizi platform? <remove>remove</remove>"
          params={{
            remove: () => (
              <Link to="remove-account">
                <span className="text-blue-dodger">
                  <T keyName="components.settings.userDetails.remove" />
                </span>
              </Link>
            )
          }}
        />
      </p>

      <PopupOutlet />
    </div>
  )
}
