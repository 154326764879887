import { Link, type LinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export const LinkButton: React.FC<LinkProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = twMerge(
    `cursor-pointer box-border font-roboto text-blue-dodger text-center
    border border-blue-dodger rounded p-[11px_23px] bg-transparent whitespace-nowrap
    hover:text-white hover:bg-blue-dodger max-md:text-[18px]`,
    className
  )
  return (
    <Link {...props} className={classes}>
      {children}
    </Link>
  )
}

export const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, disabled, ...props }) => {
  className = twMerge(
    `cursor-pointer box-border font-roboto text-blue-dodger text-center
    border border-blue-dodger rounded p-[11px_23px] bg-transparent whitespace-nowrap
    hover:text-white hover:bg-blue-dodger max-md:text-[18px]`,
    disabled
      ? 'bg-porcelain text-bombay border-porcelain pointer-events-none'
      : '',
    className
  )
  return (
    <button {...props} className={className} disabled={disabled}>
      {children}
    </button>
  )
}
