import { CoinsGoingOut } from '@/assets/illustrations'
import { Button } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { gql } from '@/gql'
import { AuthContext } from '@/providers/Auth/context'
import { useMutation } from '@apollo/client'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

const RECLAIM_MONEY = gql(`
  mutation moneyOut($userId: String!) {
    money: moneyOut(userId: $userId)
  }
`)

export const Reclaim: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!

  const navigate = useNavigate()

  const [reclaimMoney] = useMutation(RECLAIM_MONEY)

  const handleReclaim = async () => {
    if (
      currentUser?.idDocumentsStatus === 'ACCEPTED' &&
      currentUser?.ibanStatus === 'ACCEPTED'
    ) {
      await reclaimMoney({ variables: { userId: currentUser?.id } })
      navigate('/overview/payments/wallet/popup/reclaim/success')
    } else {
      const route =
        currentUser?.idDocumentsStatus !== 'PENDING_VERIFICATION' &&
        currentUser?.idDocumentsStatus !== 'ACCEPTED'
          ? '/overview/payments/bank-details/documents-verification'
          : currentUser?.ibanStatus?.startsWith('REJECTED') ||
              currentUser?.ibanStatus === 'AWAITING_UPLOAD'
            ? '/overview/payments/bank-details/documents-verification?iban'
            : '/overview/payments/bank-details'
      navigate(route)
    }
  }

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={CoinsGoingOut} alt="" />
        </div>
        <h1 className="text-2xl mb-1">{`€${currentUser?.totalBalance.toFixed(2)}`}</h1>
        <h6 className="text-sm text-gray-shuttle-soft mb-6">
          <T keyName="components.payments.wallet.reclaimPopup.info" />
        </h6>
        <h1 className="text-2xl mb-4">
          <T
            keyName={
              currentUser?.walletStatus === 'REGISTERED_WITH_KYC'
                ? 'components.payments.wallet.reclaimPopup.titleWithKyc'
                : 'components.payments.wallet.reclaimPopup.titleNoKyc'
            }
          />
        </h1>
        <h6 className="text-sm text-gray-shuttle">
          <T
            keyName={
              currentUser?.walletStatus === 'REGISTERED_WITH_KYC'
                ? 'components.payments.wallet.reclaimPopup.textWithKyc'
                : 'components.payments.wallet.reclaimPopup.textNoKyc'
            }
            params={{
              balanceavailable: (
                <span>
                  €{currentUser?.balanceAvailable?.toFixed(2) ?? '0.00'}
                </span>
              )
            }}
          />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.payments.wallet.reclaimPopup.btnCancel" />
          </Button>
          <Button
            onClick={handleReclaim}
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T
              keyName={
                currentUser?.idDocumentsStatus === 'ACCEPTED' &&
                currentUser?.ibanStatus === 'ACCEPTED'
                  ? 'components.payments.wallet.reclaimPopup.btnTransfer'
                  : 'components.payments.wallet.reclaimPopup.btnVerify'
              }
            />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
