import {
  IbanProof,
  IllustrationIban,
  IllustrationId1,
  KycDetails,
  MoneyBear
} from '@/assets/illustrations'
import { LinkButton } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup'
import { AuthContext } from '@/providers/Auth/context'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

export const DocumentsVerification: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!

  const [searchParams] = useSearchParams()
  const isIban = searchParams.has('iban')
  const isFirstTime = !currentUser?.nationality || !currentUser?.dateOfBirth

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-6">
          <img src={isIban ? IbanProof : MoneyBear} alt="" />
        </div>

        <h3 className="text-2xl font-medium mb-4">
          <T
            keyName={`components.payments.bankDetails.documentsVerification.simplifyYourPayments.title${
              isIban ? 'Iban' : ''
            }`}
          />
        </h3>

        {isIban ? (
          <p className="text-gray-shuttle-soft">
            <T keyName="components.payments.bankDetails.documentsVerification.simplifyYourPayments.subTitleIban" />
          </p>
        ) : (
          <>
            <p className="text-gray-shuttle-soft">
              <T keyName="components.payments.bankDetails.documentsVerification.simplifyYourPayments.subTitle.first" />
            </p>
            <p className="text-gray-shuttle-soft">
              <T keyName="components.payments.bankDetails.documentsVerification.simplifyYourPayments.subTitle.second" />
            </p>
          </>
        )}

        <div className="flex justify-center my-4 gap-8">
          {isIban ? (
            <div className="flex flex-col items-center gap-2">
              <img src={IllustrationIban} alt="" className="h-16" />
              <p className="font-medium mb-4">
                <T keyName="components.payments.bankDetails.documentsVerification.simplifyYourPayments.ibanProof" />
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center gap-2">
                <img src={KycDetails} alt="" className="h-16" />
                <p className="font-medium mb-4">
                  <T keyName="components.payments.bankDetails.documentsVerification.simplifyYourPayments.details" />
                </p>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={IllustrationId1} alt="" className="h-16" />
                <p className="font-medium mb-4">
                  <T keyName="components.payments.bankDetails.documentsVerification.simplifyYourPayments.identityProof" />
                </p>
              </div>
            </>
          )}
        </div>

        <div className="flex justify-center gap-6 mt-8">
          <LinkButton
            to={
              isIban
                ? 'guidelines?iban'
                : isFirstTime
                  ? 'questions'
                  : 'guidelines'
            }
          >
            <T
              keyName={`components.payments.bankDetails.documentsVerification.simplifyYourPayments.btn${
                isIban ? 'Iban' : ''
              }`}
            />
          </LinkButton>
        </div>
      </div>
    </PopupSkeleton>
  )
}
