import {
  Close,
  Forbidden,
  Padlock64,
  PadlockOpen64,
  Plus64
} from '@/assets/icons'
import { ImgProfile } from '@/components/ImgProfile'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { SlotState } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import invariant from 'tiny-invariant'

const SLOTS = gql(`
  query getSlots($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      numFreeSlots
      provider {
        numberOfSlots
        price {
          offerReturn
        }
      }
      slots {
        id
        usedBy {
          fullName
          avatar
        }
        status
        paymentDueDate
        cancelledByJoiner
      }
    }
  }
`)

export const Slots: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading, refetch } = useQuery(SLOTS, {
    variables: { serviceId: id! }
  })

  const { state } = useLocation()

  useEffect(() => {
    if (state === 'refetch') refetch()
  }, [refetch, state])

  if (loading) return <Spinner />

  invariant(data)

  const activeSlots = data.service.slots.filter(
    ({ usedBy, status }) => !!usedBy && status === SlotState.InUse
  )
  const blockedSlots = data.service.slots.filter(
    ({ usedBy, status }) => !!usedBy && status === SlotState.ToRemove
  )
  const numReservedSlots = data.service.slots.filter(
    ({ status }) => status === SlotState.Reserved
  ).length
  const monthlyPrice = data.service.provider.price.offerReturn

  return (
    <div className="flex flex-col items-stretch justify-between gap-y-4 md:items-start md:flex-row md:flex-wrap">
      <Slot type="owner" price={monthlyPrice} />
      {activeSlots.map((slot, idx) => (
        <Slot
          type="used"
          slot={slot}
          key={`used-${idx}`}
          price={monthlyPrice}
        />
      ))}
      {blockedSlots.map((slot, idx) => (
        <Slot
          type="blocked"
          slot={slot}
          key={`blocked-${idx}`}
          price={monthlyPrice}
        />
      ))}
      {Array.from({ length: data.service.numFreeSlots }).map((_, idx) => (
        <Slot type="free" key={`free-${idx}`} price={monthlyPrice} />
      ))}
      {Array.from({ length: numReservedSlots }).map((_, idx) => (
        <Slot type="reserved" key={`reserved-${idx}`} price={monthlyPrice} />
      ))}
    </div>
  )
}

const Slot: React.FC<{
  type: 'free' | 'reserved' | 'used' | 'owner' | 'blocked'
  price: number
  slot?: {
    id: string
    usedBy?: {
      fullName: string
      avatar?: string | null
    } | null
    status: string
    paymentDueDate?: string
    cancelledByJoiner?: boolean
  } | null
}> = ({ type, price, slot }) => {
  const { currentUser } = useContext(AuthContext)!
  const navigate = useNavigate()

  const [slotHover, setSlotHover] = useState(false)
  const [primaryHover, setPrimaryHover] = useState(false)
  const [secondaryHover, setSecondaryHover] = useState(false)

  let profileImg: JSX.Element
  let slotText: JSX.Element | string
  let slotEarn: JSX.Element | undefined
  let slotActions: JSX.Element

  const handlePrimaryClick = () => {
    switch (type) {
      case 'owner':
        return
      case 'free':
        navigate('user/add')
        return
      case 'reserved':
        navigate('user/reactivate')
        return
      case 'blocked':
        navigate(`user/unblock/${slot?.id}`)
        return
      case 'used':
        navigate(`user/remove/${slot?.id}`)
        return
    }
  }

  const handleSecondaryClick = () => {
    switch (type) {
      case 'owner':
      case 'reserved':
        return
      case 'used':
        navigate(`user/remove/${slot?.id}`)
        return
      case 'free':
        navigate('user/reserve')
        return
      case 'blocked':
        navigate(`user/refund/${slot?.id}`)
        return
    }
  }

  switch (type) {
    case 'free':
      profileImg = (
        <button
          type="button"
          onClick={handlePrimaryClick}
          onMouseEnter={() => setPrimaryHover(true)}
          onMouseLeave={() => setPrimaryHover(false)}
          className={twJoin(
            'w-16 h-16 flex justify-center items-center cursor-pointer',
            primaryHover && '[&_path]:fill-blue-dodger [&_path]:stroke-white',
            'max-md:[&_path]:fill-blue-dodger max-md:[&_path]:stroke-white'
          )}
        >
          <Plus64 />
        </button>
      )
      slotText = (
        <T keyName="components.offerSubscription.offerDetails.overview.userSlot.available.title" />
      )
      slotEarn = (
        <T
          keyName="components.offerSubscription.offerDetails.overview.userSlot.available.info"
          params={{
            currency: () => <T keyName="currency.symbol.euro" />,
            price: () => price,
            month: () => (
              <T keyName="components.offerSubscription.offerDetails.overview.userSlot.active.month" />
            )
          }}
        />
      )
      slotActions = (
        <span>
          <a
            href="#add"
            onClick={handlePrimaryClick}
            className={twJoin(
              'max-md:text-blue-dodger',
              primaryHover && 'text-blue-dodger'
            )}
            onMouseEnter={() => setPrimaryHover(true)}
            onMouseLeave={() => setPrimaryHover(false)}
          >
            <T keyName="components.offerSubscription.offerDetails.overview.userSlot.add" />
          </a>
          <span className="select-none">{' / '}</span>
          <a
            href="#reserve"
            onClick={handleSecondaryClick}
            className={twJoin(
              'max-md:text-blue-dodger',
              secondaryHover && 'text-blue-dodger'
            )}
            onMouseEnter={() => setSecondaryHover(true)}
            onMouseLeave={() => setSecondaryHover(false)}
          >
            <T keyName="components.offerSubscription.offerDetails.overview.userSlot.reserve" />
          </a>
        </span>
      )
      break
    case 'reserved':
      profileImg = (
        <>
          <button
            type="button"
            onClick={handlePrimaryClick}
            className="w-16 h-16 flex justify-center items-center cursor-pointer max-md:hidden"
          >
            {slotHover ? <PadlockOpen64 /> : <Padlock64 />}
          </button>
          <button
            type="button"
            onClick={handlePrimaryClick}
            className="w-16 h-16 flex justify-center items-center cursor-pointer md:hidden"
          >
            <PadlockOpen64 />
          </button>
        </>
      )
      slotText = (
        <T keyName="components.offerSubscription.offerDetails.overview.userSlot.reserved.title" />
      )
      slotEarn = (
        <T
          keyName="components.offerSubscription.offerDetails.overview.userSlot.reserved.info"
          params={{
            currency: () => <T keyName="currency.symbol.euro" />,
            price: () => price,
            month: () => (
              <T keyName="components.offerSubscription.offerDetails.overview.userSlot.active.month" />
            )
          }}
        />
      )
      slotActions = (
        <a
          href="#unreserve"
          onClick={handlePrimaryClick}
          className="text-blue-dodger"
        >
          <T keyName="components.offerSubscription.offerDetails.overview.userSlot.unreserve" />
        </a>
      )
      break
    case 'owner':
      profileImg = (
        <ImgProfile
          img={currentUser?.avatar}
          className="w-16 h-16 border-[3px] border-transparent shadow-[0_0_0_3px] shadow-blue-dodger/50"
        />
      )
      slotText = (
        <T keyName="components.offerSubscription.offerDetails.overview.userSlot.owner.title" />
      )
      slotActions = (
        <T keyName="components.offerSubscription.offerDetails.overview.userSlot.reserve" />
      )
      break
    case 'blocked':
      profileImg = (
        <div className="relative">
          <ImgProfile
            img={slot?.usedBy?.avatar}
            className="w-16 h-16 opacity-50"
          />
          <Forbidden
            className="absolute bottom-1 right-1
            [&_circle]:fill-pink-brink [&_circle]:stroke-pink-brink [&_path]:stroke-white"
          />
        </div>
      )
      slotEarn = (
        <T
          keyName="components.offerSubscription.offerDetails.overview.userSlot.blocked.timeLeft"
          params={{
            time: () => moment(slot?.paymentDueDate).diff(moment(), 'days')
          }}
        />
      )
      slotText = slot?.usedBy?.fullName ?? 'Partizi User'
      slotActions = (
        <span>
          {!slot?.cancelledByJoiner && (
            <>
              <a
                href="#reactive"
                onClick={handlePrimaryClick}
                className={twJoin(
                  'max-md:text-blue-dodger',
                  primaryHover && 'text-blue-dodger'
                )}
                onMouseEnter={() => setPrimaryHover(true)}
                onMouseLeave={() => setPrimaryHover(false)}
              >
                <T keyName="components.offerSubscription.offerDetails.overview.userSlot.reactive" />
              </a>
              <span className="select-none">{' / '}</span>
            </>
          )}
          <a
            href="#remove"
            onClick={handleSecondaryClick}
            className={twJoin(
              'max-md:text-blue-dodger',
              secondaryHover && 'text-blue-dodger'
            )}
            onMouseEnter={() => setSecondaryHover(true)}
            onMouseLeave={() => setSecondaryHover(false)}
          >
            <T keyName="components.offerSubscription.offerDetails.overview.userSlot.remove" />
          </a>
        </span>
      )
      break
    case 'used':
      profileImg = (
        <ImgProfile img={slot?.usedBy?.avatar} className="w-16 h-16" />
      )
      slotEarn = (
        <T
          keyName="components.offerSubscription.offerDetails.overview.userSlot.active.info"
          params={{
            currency: () => <T keyName="currency.symbol.euro" />,
            price: () => price,
            month: () => (
              <T keyName="components.offerSubscription.offerDetails.overview.userSlot.active.month" />
            )
          }}
        />
      )
      slotActions = (
        <a
          href={'#remove'}
          onClick={handlePrimaryClick}
          className={twJoin(
            'max-md:text-blue-dodger',
            secondaryHover && 'text-blue-dodger'
          )}
          onMouseEnter={() => setSecondaryHover(true)}
          onMouseLeave={() => setSecondaryHover(false)}
        >
          <T keyName="components.offerSubscription.offerDetails.overview.userSlot.remove" />
        </a>
      )
      slotText = slot?.usedBy?.fullName ?? 'Partizi User'
      break
  }

  return (
    <>
      {/* Desktop */}
      <div
        onMouseEnter={() => setSlotHover(true)}
        onMouseLeave={() => setSlotHover(false)}
        className="flex flex-col justify-center items-center gap-2 max-md:hidden"
      >
        <div
          className={twJoin(
            'w-[153px] h-[153px] flex flex-col justify-center items-center rounded relative',
            type === 'free'
              ? 'border border-dashed border-gray-400'
              : 'bg-gallery'
          )}
        >
          <div
            onClick={handleSecondaryClick}
            onMouseEnter={() => setSecondaryHover(true)}
            onMouseLeave={() => setSecondaryHover(false)}
            className={twJoin(
              'absolute top-2 right-2 z-10 cursor-pointer',
              secondaryHover &&
                '[&_circle]:fill-pink-brink [&_circle]:stroke-pink-brink [&_path]:stroke-white',
              (type === 'owner' || type === 'reserved') && 'hidden',
              slotHover ? 'visible' : 'invisible'
            )}
          >
            <Close />
          </div>
          <div className="relative w-full flex justify-center">
            {profileImg}
            <h6
              className={twJoin(
                'text-xs text-bombay absolute block -bottom-5 w-full text-center',
                type !== 'owner' && !slotHover && 'invisible'
              )}
            >
              {slotActions}
            </h6>
          </div>
        </div>
        <h5 className="text-sm">{slotText}</h5>
        {slotEarn && (
          <h6 className="text-xs text-gray-shuttle-soft">{slotEarn}</h6>
        )}
      </div>
      {/* Mobile */}
      <div
        className={twJoin(
          'flex flex-row items-center gap-2 p-2 md:hidden',
          type === 'free' || type === 'reserved'
            ? 'border border-dashed'
            : 'bg-gallery',
          type === 'free' && 'border-blue-dodger',
          type === 'reserved' && 'border-pink-brink'
        )}
      >
        <div>{profileImg}</div>
        <div className="flex flex-col flex-grow">
          <h5>{slotText}</h5>
          {slotEarn && (
            <h6 className="text-sm text-gray-shuttle-soft">{slotEarn}</h6>
          )}
        </div>
        <div className="text-sm">{slotActions}</div>
      </div>
    </>
  )
}
