import { Close, PDF, UploadArrow } from '@/assets/icons'
import { Idea } from '@/assets/illustrations'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { KycDocumentTypes } from '@/consts.ts'
import { gql } from '@/gql'
import { KycType } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { fileToBase64 } from '@/utils'
import { getErrorMessage } from '@/utils/errors.ts'
import { toastError } from '@/utils/toast'
import { useMutation } from '@apollo/client'
import { T } from '@tolgee/react'
import { useContext, useEffect, useState } from 'react'
import { type FileRejection, useDropzone } from 'react-dropzone'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import invariant from 'tiny-invariant'

const UPLOAD_KYC = gql(`
  mutation uploadKYC($data: KYCInput!) {
    uploadKYC(data: $data)
  }
`)

export const UploadDocument: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { currentUser } = useContext(AuthContext)!
  invariant(currentUser, 'User must be logged in')

  useEffect(() => {
    const type = searchParams.get('type')
    if (
      !type ||
      KycDocumentTypes.map(({ id }) => id).indexOf(type as KycType) === -1
    ) {
      navigate(-1)
    }
  }, [navigate, searchParams])

  const [file, setFile] = useState<File & { preview: string }>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [uploadKYC] = useMutation(UPLOAD_KYC)

  const docType = KycDocumentTypes.find(
    ({ id }) => id === searchParams.get('type')
  )!

  const handleSubmit = async () => {
    if (!file) return
    setIsSubmitting(true)
    try {
      const fileBase64 = await fileToBase64(file)
      await uploadKYC({
        variables: {
          data: {
            userId: currentUser?.id,
            document: fileBase64.split(',')[1],
            name: file.name,
            type: docType.id,
            step: 1 // Hardcoded since only 1 document is neccessary now
          }
        }
      })
      navigate('../success')
    } catch (e: unknown) {
      console.log(e)
      setIsSubmitting(false)
      toastError(getErrorMessage(e))
    }
  }

  return (
    <PopupSkeleton
      showClose
      showGoBack
      onCloseLink="/overview/payments/bank-details"
    >
      <div className="w-[768px] h-full max-md:w-full p-10 text-center">
        <h3 className="text-lg font-medium mb-4">
          <T
            keyName={`components.payments.bankDetails.documentsVerification.documentUpload.${docType.text}`}
          />
        </h3>

        <p className="text-gray-shuttle-soft mb-8">
          <T
            keyName={`components.payments.bankDetails.documentsVerification.documentUpload.${
              docType.id === KycType.IbanProof ? 'iban' : 'other'
            }SubTitle`}
            params={{
              doc: () => (
                <T
                  keyName={`components.payments.bankDetails.documentsVerification.documentUpload.word.${docType.text}`}
                />
              )
            }}
          />
        </p>

        <div className="grid grid-cols-2 gap-8 max-md:grid-cols-1">
          <div>
            <UploadContainer file={file} setFile={setFile} />
          </div>
          <div className="flex flex-col justify-center items-center gap-6">
            <img src={Idea} alt="" />
            <p className="text-gray-shuttle-soft text-sm">
              {docType.id === KycType.IbanProof ? (
                <>
                  <T keyName="components.payments.bankDetails.documentsVerification.documentUpload.ibanInfo" />
                  <span className="font-bold">
                    <T keyName="components.payments.bankDetails.documentsVerification.documentUpload.ibanMandatoryInfo" />
                  </span>
                </>
              ) : (
                <T keyName="components.payments.bankDetails.documentsVerification.documentUpload.info" />
              )}
            </p>
          </div>
        </div>

        <div className="flex justify-center gap-6 mt-8">
          <Button disabled={!file || isSubmitting} onClick={handleSubmit}>
            <T keyName="components.payments.bankDetails.documentsVerification.documentUpload.btn" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}

const UploadContainer: React.FC<{
  file?: File & { preview: string }
  setFile: (file: (File & { preview: string }) | undefined) => void
}> = ({ file, setFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', 'jpeg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    },
    maxSize: 3145728, // 3 MB
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (!file) return

      setFile(
        Object.assign(file, {
          preview:
            file.type === 'application/pdf' ? 'pdf' : URL.createObjectURL(file)
        })
      )
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      const error = fileRejections[0].errors[0]

      if (error.code === 'file-too-large')
        toastError('The file must be smaller than 3MB')
      else toastError(error.message)
    }
  })

  const rootProps = getRootProps()
  const rootClasses = rootProps.className

  if (file) {
    return (
      <div
        className="flex flex-col justify-center items-center gap-4 rounded p-6
          bg-gray-400/10 border border-dashed border-gray-dusty relative"
      >
        <div
          className="absolute cursor-pointer top-2 right-2 z-10
          [&_circle]:hover:fill-pink-brink [&_circle]:hover:stroke-pink-brink
          [&_path]:hover:stroke-white max-md:hidden max-md:top-4 max-md:right-4"
        >
          <Close
            onClick={() => setFile(undefined)}
            className="max-md:w-8 max-md:h-8"
          />
        </div>
        {file.preview === 'pdf' ? (
          <div className="h-[212px] flex justify-center items-center">
            <PDF />
          </div>
        ) : (
          <img src={file.preview} alt="" className="max-h-[212px]" />
        )}
      </div>
    )
  }

  return (
    <div
      {...rootProps}
      className={twJoin(
        'flex flex-col justify-center items-center gap-4 rounded p-8',
        'bg-gray-400/10 border border-dashed border-gray-dusty',
        rootClasses
      )}
    >
      <input {...getInputProps()} />
      <div className="flex-grow my-6">
        <UploadArrow />
      </div>
      <h6 className="text-sm">
        <T keyName="components.payments.bankDetails.documentsVerification.documentUpload.upload.title" />
      </h6>
      <p className="text-xs text-gray-shuttle-soft">
        <T keyName="components.payments.bankDetails.documentsVerification.documentUpload.upload.info" />
      </p>
    </div>
  )
}
