import { Button, PasswordInput } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { gql } from '@/gql'
import { getErrorMessage } from '@/utils/errors'
import { toastError } from '@/utils/toast'
import { useMutation } from '@apollo/client'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

const CHANGE_PASSWORD = gql(`
  mutation changePassword($password: String!) {
    password: changePassword(password: $password) {
      id
    }
  }
`)

export const SettingsChangePassword: React.FC = () => {
  const { t } = useTranslate()

  const [changePassword] = useMutation(CHANGE_PASSWORD)

  const navigate = useNavigate()

  const validationSchema = yup.object().shape({
    confirmPassword: yup
      .string()
      .required(t('form.inputs.errors.password'))
      .min(8),
    password: yup.string().required(t('form.inputs.errors.password')).min(8)
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      if (values.password !== values.confirmPassword) {
        formikHelpers.setFieldError(
          'confirmPassword',
          t('form.inputs.errors.passwordsNotMatch')
        )
        return
      }

      try {
        await changePassword({
          variables: {
            password: values.password
          }
        })
        navigate(-1)
      } catch (err) {
        console.error(err)
        toastError(getErrorMessage(err))
      }
      formikHelpers.resetForm()
      formikHelpers.setSubmitting(false)
    }
  })

  return (
    <PopupSkeleton showClose>
      <div className="w-[480px] h-full max-md:w-full p-8">
        <h1 className="text-2xl mb-8">
          <T keyName="components.changePassword.title" />
        </h1>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col items-center gap-4"
        >
          <PasswordInput
            name="password"
            formik={formik}
            intlKey="resetPassword"
            required
          />
          <PasswordInput
            name="confirmPassword"
            formik={formik}
            intlKey="confirmResetPassword"
            required
          />
          <Button
            type="submit"
            className="w-fit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <T keyName="components.resetPassword.btn" />
          </Button>
        </form>
      </div>
    </PopupSkeleton>
  )
}
