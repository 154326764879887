import { Payments as PaymentsIcon } from '@/assets/icons'
import { TabsHeader } from '@/components/Tabs/TabsHeader'
import { T } from '@tolgee/react'
import { useEffect } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'

const subRoutes = [
  {
    path: '/overview/payments/wallet',
    label: 'tabs.wallet'
  },
  {
    path: '/overview/payments/payment-methods',
    label: 'tabs.paymentMethods'
  },
  {
    path: '/overview/payments/transactions',
    label: 'tabs.transactions'
  },
  {
    path: '/overview/payments/bank-details',
    label: 'tabs.bankDetails'
  }
]

export const Payments: React.FC = () => {
  const navigate = useNavigate()
  const outlet = useOutlet()
  useEffect(() => {
    if (!outlet) navigate('/overview/payments/wallet')
  }, [navigate, outlet])

  return (
    <div className="mx-auto max-w-4xl py-16 px-8 max-md:p-4">
      <div className="flex items-center gap-4 mb-12 max-md:hidden">
        <PaymentsIcon />
        <h1 className="text-2xl font-medium">
          <T keyName="components.payments.title" />
        </h1>
      </div>

      <div className="max-md:-mx-4">
        <TabsHeader routes={subRoutes} />
      </div>

      <div className="w-full mt-8 max-md:px-4">{outlet}</div>
    </div>
  )
}
