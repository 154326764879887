import { FormatIcu } from '@tolgee/format-icu'
import {
  DevTools,
  FormatSimple,
  LanguageDetector,
  Tolgee,
  TolgeeProvider
} from '@tolgee/react'
import { BackendFetch } from '@tolgee/react'
import { useCallback, useEffect, useState } from 'react'
import { TranslationContext } from './context'

const tolgee = Tolgee()
  .use(DevTools())
  .use(LanguageDetector())
  .use(FormatSimple())
  .use(FormatIcu())
  .use(BackendFetch())
  .init({
    defaultLanguage: 'de-DE',
    availableLanguages: ['en', 'de-DE'],

    // for development
    apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY
  })

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [language, setLanguage] = useState('de')

  const updateLanguage = useCallback((language: string) => {
    setLanguage(language)
    localStorage.setItem('language', language)
    tolgee.changeLanguage(language === 'de' ? 'de-DE' : 'en')
  }, [])

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language')
    if (storedLanguage) {
      updateLanguage(storedLanguage)
    }
  }, [updateLanguage])

  return (
    <TolgeeProvider tolgee={tolgee}>
      <TranslationContext.Provider
        value={{
          updateLanguage: updateLanguage,
          currentLanguage: language
        }}
      >
        {children}
      </TranslationContext.Provider>
    </TolgeeProvider>
  )
}
