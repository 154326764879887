import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

const REQUEST_DETAILS = gql(`
  query getRequestDetailsToAccept($requestId: String!) {
    request(where: { id: $requestId }) {
      isLastRequest
      service {
        provider {
          name
          price {
            offerReturn
          }
        }
      }
      requestBy {
        firstName
        fullName
        avatar
      }
    }
  }
`)

export const AcceptRequestSuccess: React.FC = () => {
  const { id: serviceId, requestId } = useParams<{
    id: string
    requestId: string
  }>()
  invariant(requestId, 'Request ID should be provided by the route')
  const navigate = useNavigate()
  const { state } = useLocation()

  const { data, loading } = useQuery(REQUEST_DETAILS, {
    variables: { requestId }
  })

  let content: JSX.Element
  if (loading) content = <Spinner />
  else {
    invariant(data, 'Data should be loaded')
    const { request } = data

    content = (
      <>
        <div className="flex flex-col items-center gap-1 mb-4">
          <div className="flex justify-center">
            <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3" />
            <div className="mx-2 border border-dashed border-bombay rounded p-2">
              <ImgProfile
                img={request.requestBy.avatar}
                className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-blue-dodger/50"
              />
            </div>
            <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3" />
          </div>
          <p className="text-xs">{request.requestBy.fullName}</p>
          <p className="text-xs text-blue-dodger">
            <T
              keyName="components.offerSubscription.offerDetails.requests.acceptRequest.monthlyPrice"
              params={{
                price: () => request.service.provider.price.offerReturn,
                currency: () => <T keyName="currency.symbol.euro" />
              }}
            />
          </p>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.requests.acceptRequest.successTitle"
            params={{
              user: () => request.requestBy.fullName,
              service: () => request.service.provider.name
            }}
          />
        </h3>

        <p className="text-sm text-gray-shuttle-soft mb-6">
          <T
            keyName="components.offerSubscription.offerDetails.requests.acceptRequest.successSubTitle"
            params={{
              user: () => request.requestBy.fullName,
              service: () => request.service.provider.name
            }}
          />
        </p>

        <div className="flex justify-center gap-4 max-md:flex-col">
          <Button
            onClick={
              !state?.isLastRequest
                ? () =>
                    navigate(
                      `/overview/subscriptions/offer/${serviceId}/requests/popup/warning`
                    )
                : () =>
                    navigate(`/overview/subscriptions/offer/${serviceId}`, {
                      state: 'refetch'
                    })
            }
          >
            <T
              keyName="components.offerSubscription.offerDetails.requests.acceptRequest.btn"
              params={{
                service: () => request.service.provider.name
              }}
            />
          </Button>
        </div>
      </>
    )
  }

  return (
    <PopupSkeleton
      showClose
      onClose={
        !state?.isLastRequest
          ? () =>
              navigate(
                `/overview/subscriptions/offer/${serviceId}/requests/popup/warning`
              )
          : () =>
              navigate(`/overview/subscriptions/offer/${serviceId}`, {
                state: 'refetch'
              })
      }
    >
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        {content}
      </div>
    </PopupSkeleton>
  )
}
