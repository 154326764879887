import { ServiceBear } from '@/assets/landing_page/documents/Illustrations'
import { Footer } from '@/components/Footer'
import { Button } from '@/components/Inputs/Button'
import { PublicNavbar } from '@/components/PublicNavbar'
import './style.css'

export const TermsConditions: React.FC = () => (
  <div>
    <PublicNavbar lightBg />
    <div className="public-page">
      <div className="flex justify-center mb-4">
        <img src={ServiceBear} alt="partizi bear" />
      </div>
      <h2 className="title">Nutzungsbedingung</h2>
      <div>
        <h3 className="section-title">1. Geltungsbereich</h3>
        <div className="point">
          <span className="number">1.1</span>
          <p className="text">
            Die nachstehenden Allgemeinen Geschäftsbedingungen gelten für die
            Inanspruchnahme der Plattform der Firma Partizi UG
            (haftungsbeschränkt), durch den Kunden (später „Du“ oder „Kunde“)
            als Verbraucher im Rahmen eines Nutzungsvertrags.
          </p>
        </div>
        <div className="point">
          <span className="number">1.2</span>
          <p className="text">
            Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu
            Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
            ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">
          2. Vertragsgegenstand; Vertragsschluss
        </h3>
        <div className="point">
          <span className="number">2.1</span>
          <p className="text">
            Wir bieten Dir unsere Plattform, um Dich mit Familienangehörigen und
            Freunden, mit denen Du in der Regel zusammen wohnst, zu verbinden
            und die Kosten für Deine Streaming-Services aufzuteilen. Wir möchten
            damit im Rahmen der Nutzungsbedingungen der jeweiligen
            Streaming-Services die Zahlungen zwischen Dir und deinen
            Familienangehörigen und Freunden erleichtern und bieten dir auf
            unserer Plattform eine Vermittlungsbasis. Wir vermitteln auf unserer
            Plattform zwischen Dir und anderen Familienangehörige und Freunde
            miteinander, damit Du im Rahmen der jeweiligen Nutzungsbedingungen
            Streaming-Services gemeinsam nutzen kannst.
          </p>
        </div>
        <div className="point">
          <span className="number">2.2</span>
          <p className="text">
            Um einen Nutzungsvertrag mit uns abzuschließen, musst Du in der
            Registrierungsmaske unter https://partizi.de/ deine Daten angeben
            (z.B. Vor- und Nachname, E-Mail-Adresse, Passwort). Dies gilt als
            Angebot zum Abschluss eines Nutzungsvertrags durch Dich. Die
            Registrierung ist die Einrichtung eines Nutzerkontos. Mit dieser
            Registrierung kommt nach Annahme durch uns ein Nutzungsvertrag mit
            Dir zustande. Eine Annahme durch uns liegt vor, wenn wir eine
            Bestätigung der Registrierung vorgenommen und das Nutzerkonto
            eingerichtet haben.
          </p>
        </div>
        <div className="point">
          <span className="number">2.3</span>
          <p className="text">
            Diesen Account kannst Du auf zwei Arten nutzen: sowohl kannst Du
            selbst Deinen Streaming-Account für andere zur Verfügung stellen,
            oder einen Account von anderen Kunden wie Dir in Anspruch nehmen.
            Hier ist Voraussetzung, dass Du oder der andere Kunde über einen
            Streaming-Account verfügen, der mehrere Nutzerprofile vorsieht. Du
            kannst dann im Rahmen der Nutzungsbedingungen des jeweiligen
            Streaming-Anbieters die Nutzerprofile, bis auf eines für Dich, zur
            Verfügung stellen oder ein Nutzerprofil eines anderen Kunden in
            Anspruch nehmen.
          </p>
        </div>
        <div className="point">
          <span className="number">2.4</span>
          <p className="text">
            Du schließt dabei als Grundlage einen unentgeltlichen
            Nutzungsvertrag mit uns ab. Dieser wird entgeltpflichtig, wenn Du
            das Nutzerprofil eines anderen Kunden in Anspruch nimmst (vgl. 8.
            Vergütung).
          </p>
        </div>
        <div className="point">
          <span className="number">2.5</span>
          <p className="text">
            Wenn Du das Nutzerprofil eines anderen Kunden nutzt, kommt mit
            diesem Kunden jeweils ein Vertrag über die Nutzung des jeweiligen
            Nutzerprofils des Streaming-Services zustande.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">
          3. Vertragssprache; Vertragstextspeicherung
        </h3>
        <div className="point">
          <span className="number">3.1</span>
          <p className="text">
            Die für den Vertragsschluss zur Verfügung stehende Sprache ist
            Deutsch.
          </p>
        </div>
        <div className="point">
          <span className="number">3.2</span>
          <p className="text">
            Die AGB sind jederzeit auf der Homepage unter
            https://partizi.de/legal abrufbar.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">4. Deine Pflichten</h3>
        <div className="point">
          <span className="number">4.1</span>
          <p className="text">
            Du versicherst uns, dass die von Dir im Rahmen einer Registrierung
            oder im Rahmen der Eröffnung eines Nutzer-Accounts auf unserer
            Website gemachten Angaben zu Deiner Person, insbesondere Dein Vor-,
            Nachname und Deine Postanschrift sowie das Geburtsdatum und die
            E-Mail-Adresse, wahrheitsgemäß und richtig sind. Sofern sich
            Änderungen bei den Daten ergeben, die Du im Rahmen der Registrierung
            angegeben hast, teilst Du uns dies unverzüglich mit.
          </p>
        </div>
        <div className="point">
          <span className="number">4.2</span>
          <p className="text">
            Du verpflichtest Dich, Deine Zugangsdaten vertraulich zu behandeln
            und Dritten nicht zur Verfügung zu stellen.
          </p>
        </div>
        <div className="point">
          <span className="number">4.3</span>
          <p className="text">
            Du versicherst, dass Du die Nutzungsbedingungen der jeweiligen
            Streaming-Services einhältst. Wir weisen Dich ausdrücklich darauf
            hin, dass unsere Plattform nur dazu dient, Familie und Freunde zu
            finden, die entsprechend der jeweiligen Nutzungsbedingungen für eine
            Nutzung Deines Accounts geeignet sind. Dies bedeutet in der Regel,
            dass Du die anderen Kunden kennst.
          </p>
        </div>
        <div className="point">
          <span className="number">4.4</span>
          <p className="text">
            Wenn Du Deinen Streaming-Account mit Nutzerprofilen zur Verfügung
            stellst, sicherst Du zu, dass Du befugt bist, diesen zu nutzen und
            die entsprechenden Anmeldedaten zur Verfügung zu stellen. Du
            verpflichtest Dich, diese Daten aktuell zu halten und den Kunden,
            die Deine Nutzerprofile nutzen, für die Dauer ihres Abonnements zur
            Verfügung zu stellen (vgl. auch die Kündigungsmöglichkeit unter
            6.1.). Wenn Du ein Nutzerprofil nicht mehr zur Verfügung stellen
            möchtest, kannst Du es auf inaktiv setzen. Es steht dann anderen
            Kunden nicht mehr zur Verfügung.
          </p>
        </div>
        <div className="point">
          <span className="number">4.5</span>
          <p className="text">
            Wenn Du das Nutzerprofil eines anderen Kunden nutzt, verpflichtest
            Du Dich die angegebenen Anmeldedaten vertraulich zu behandeln, und
            keinem Dritten zur Verfügung zu stellen. Du bist verpflichtet,
            hierbei die gleiche Sorgfalt walten zu lassen, wie Du dies bei
            Deinen eigenen Streaming-Accounts tust.
          </p>
        </div>
        <div className="point">
          <span className="number">4.6</span>
          <p className="text">
            Du versicherst uns auf Inhalte, die Du selbst generierst und uns zur
            Verfügung stellst („nutzergenerierte Inhalte“), beispielsweise im
            Rahmen eines Profilbilds, dass
          </p>
        </div>
        <ul className="info-list pl-8">
          <li className="item">
            die nutzergenerierten Inhalte Dir gehören oder Du berechtigt bist,
            diese zu posten, und
          </li>
          <li className="item">
            die nutzergenerierten Inhalte weder gegen diese Nutzungsbedingungen
            verstoßen, noch geltende gesetzliche Bestimmungen oder Rechte an
            geistigem Eigentum, Veröffentlichungsrechte, Persönlichkeitsrechte
            oder sonstige Rechte Dritter verletzen.
          </li>
        </ul>
        <p>Gleiches gilt für die Nutzung der nutzergenerierten Inhalte.</p>
      </div>
      <div>
        <h3 className="section-title">5. Schlichtungsverfahren</h3>
        <div className="point">
          <span className="number">5.1</span>
          <p className="text">
            Wenn es bei im Zusammenhang mit der Zurverfügungstellung eines
            Nutzerprofils zu Problemen zwischen Dir und einem anderen Nutzer
            kommt, weil beispielsweise das Passwort nicht mehr funktioniert,
            geben wir Euch drei Tage Zeit, das Problem zu lösen. Kommt es
            innerhalb dieser drei Tage nicht zu einer einvernehmlichen Lösung,
            haben Du und der andere Kunde die Möglichkeit, uns für ein
            Schlichtungsverfahren zu kontaktieren.
          </p>
        </div>
        <div className="point">
          <span className="number">5.2</span>
          <p className="text">
            Im Rahmen des Schlichtungsverfahrens geben wir Dir und dem anderen
            Kunden die Möglichkeit Eure Sicht zu schildern, im Anschluss werden
            wir in der Regel binnen drei Tagen eine Entscheidung fällen.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">6. Referral</h3>
        <div className="point">
          <span className="number">6.1</span>
          <p className="text">
            Unter Referral verstehen wir das erfolgreiche Weiterempfehlen von
            neuen Kunden durch Dich.
          </p>
        </div>
        <div className="point">
          <span className="number">6.2</span>
          <p className="text">
            Wenn ein neuer Kunde sich auf deine Empfehlung mit Deinem
            Referral-Link einen Account bei uns erstellt und kostenpflichtig ein
            Nutzerprofil eines anderen Kunden in Anspruch nimmt, hat ein
            Referral stattgefunden. Für jeden Monat, den der neue Kunde
            kostenpflichtig ein Nutzerprofil eines anderen Kunden nutzt,
            erhältst Du als Dankeschön von dem geworbenem Kunden eine Gutschrift
            für Dein Referral.
          </p>
        </div>
        <div className="point">
          <span className="number">6.3</span>
          <p className="text">
            Sofern Du erfolgreich uns einen neuen Kunden vermittelt hast,
            erhältst Du für jedes Referral eine Gutschrift in Höhe von 0,50 Euro
            pro Monat für eine Dauer von maximal insgesamt sechs (6) Monaten,
            insgesamt also maximal eine Gutschrift in Höhe von 3,00 Euro brutto.
            Dieses Guthaben wird auf deinen Account gebucht und mit anstehenden
            Abbuchen verrechnet, es sei denn, Du klickst an, dass Dir das
            Guthaben ausgeschüttet wird. Eine Auszahlung findet dabei immer per
            Banküberweisung statt. Diese kann nur erfolgen,wenn Du Dich zuvor
            erfolgreich verifiziert hast. Sofern die Gutschriften ein
            gewerbliches Maß annehmen, können eine weitere Verifikation sowie
            gegebenenfalls eine Besteuerung der Gutschriften erforderlich sein,
            die Du selbst vornehmen musst. Alternativ kannst Du das Geld auch
            spenden.
          </p>
        </div>
        <div className="point">
          <span className="number">6.4</span>
          <p className="text">
            Wenn Du einen Account mit einem Referral-Link erstellt hast, werden
            0,50 Euro pro kostenpflichtigen Monat von Deiner an uns zu zahlenden
            Servicegebühr an denjenigen weitergeleitet, dessen Referral-Link Du
            verwendet hast.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">7. Vertragslaufzeit; Kündigung</h3>
        <div className="point">
          <span className="number">7.1</span>
          <p className="text">
            Der unentgeltliche Nutzungsvertrag wird unbefristet geschlossen. Du
            kannst den unentgeltlichen Nutzungsvertrag ordentlich zum Ende eines
            Kalendermonats mit einer Frist von 14 Tagen kündigen, sofern Du
            keine Nutzerprofile anbietest. Ist dies der Fall, kann eine
            Kündigung erst erfolgen, wenn Du die Nutzerprofile deaktiviert hast.
          </p>
        </div>
        <div className="point">
          <span className="number">7.2</span>
          <p className="text">
            Wir behalten uns vor, bei Verstößen gegen diese Allgemeinen
            Geschäftsbedingungen ebenfalls von dem Recht der ordentlichen
            Kündigung Gebrauch zu machen.
          </p>
        </div>
        <div className="point">
          <span className="number">7.3</span>
          <p className="text">
            Das gesetzliche Kündigungsrecht aus wichtigem Grund bleibt hiervon
            unberührt.
          </p>
        </div>
        <div className="point">
          <span className="number">7.4</span>
          <p className="text">Jede Kündigung bedarf der Textform.</p>
        </div>
      </div>
      <div>
        <h3 className="section-title">8. Zahlungsart; Bezahlung; Auszahlung</h3>
        <div className="point">
          <span className="number">8.1</span>
          <p className="text">
            Die Zahlungen erfolgen über unseren Bezahldienstleister Lemon Way.
            Dieser übernimmt die Übermittlung von Zahlungen, die Du tätigst oder
            von anderen Kunden erhältst. Hierfür musst Du ein Benutzerkonto bei
            Lemon W eröffnet haben und dafür die jeweils geltenden AGB
            akzeptiert haben.
          </p>
        </div>
        <div className="point">
          <span className="number">8.2</span>
          <p className="text">
            Dir stehen folgende Zahlungsmöglichkeiten zur Verfügung:
          </p>
        </div>
        <ul className="info-list no-bullet pl-6">
          <li className="item">Kreditkarte</li>
          <li className="item">
            Mit Abgabe der Bestellung gibst Du Deine Kreditkartendaten an. Nach
            Deiner Legitimation als rechtmäßiger Karteninhaber wird unmittelbar
            bei Fälligkeit der monatlichen Zahlung die Zahlungstransaktion
            automatisch durchgeführt und Deine Karte belastet.
          </li>
          <li className="item">SEPA-Lastschriftverfahren</li>
          <li className="item">
            Mit Abgabe der Bestellung erteilst Du lemonway ein
            SEPA-Lastschriftmandat. Über das Datum der Kontobelastung werden wir
            Dich informieren (sog. Prenotification). Mit Einreichung des
            SEPA-Lastschriftmandats fordern wir unsere Bank zur Einleitung der
            Zahlungstransaktion auf. Die Zahlungstransaktion wird automatisch
            durchgeführt und Dein Konto belastet.
          </li>
          <li className="item">
            Die Kontobelastung erfolgt vor Fälligkeit der monatlichen Zahlung.
            Die Frist für die Vorabankündigung über das Datum der Kontobelastung
            (sog. Prenotification-Frist) beträgt 3 Tage.
          </li>
        </ul>
        <div className="point">
          <span className="number">8.3</span>
          <p className="text">
            Sofern Du Nutzerprofile von anderen in Anspruch nimmst, zahlst Du
            pro Nutzerprofil einen monatlichen Betrag in Höhe des anteiligen
            Betrags der Nutzung des Streaming-Services sowie zuzüglich eine
            Servicegebühr in Höhe von 0,99 Euro brutto an uns. Die Servicegebühr
            beinhaltet ebenfalls die Gebühr des Bezahldienstleisters und im
            Falle eines Referrals auch diesen Betrag, der dann an Dich bzw. den
            Kunden als „Dankeschön“ weitergeleitet wrid.
          </p>
        </div>
        <div className="point">
          <span className="number">8.4</span>
          <p className="text">
            Der Betrag insgesamt wird immer am Anfang des Monats fällig. Die
            Abbuchung erfolgt dann nach dem von Dir gewählten Bezahlmittel.
          </p>
        </div>
        <div className="point">
          <span className="number">8.5</span>
          <p className="text">
            Wenn Du ein Nutzerprofil zur Verfügung stellst, und dieses in
            Anspruch genommen wird, wird Dir der Betrag sofort nach Fälligkeit
            beim anderen Kunden gutgeschrieben. Eine Ausschüttung erfolgt jedoch
            erst nach einer Wartezeit von 30 Tagen, sofern keine Beschwerden des
            anderen Kunden eingegangen sind (vgl. 4.6.).
          </p>
        </div>
        <div className="point">
          <span className="number">8.6</span>
          <p className="text">
            Wenn Dein Account über ein Guthaben verfügt, wird dies mit
            ausstehenden Abbuchungen zuerst verrechnet, es sei denn Du klickst
            zuvor an, dass eine Ausschüttung erfolgen soll. Für den Fall, dass
            ein Guthaben ausgeschüttet wird, verwenden wir das Zahlungsmittel,
            welches Du sonst für deine Zahlungen gewählt hast. Es steht Dir
            frei, ein anderes verfügbares Zahlungsmittel auszuwählen.
          </p>
        </div>
        <div className="point">
          <span className="number">8.7</span>
          <p className="text">
            Alle genannten Preise verstehen sich jeweils in Euro inkl. der
            gesetzlichen Umsatzsteuer.
          </p>
        </div>
        <div className="point">
          <span className="number">8.8</span>
          <p className="text">
            Schlägt der Forderungseinzug fehl, so hast Du uns die dafür
            anfallenden Mehrkosten zu erstatten, soweit Du das Fehlschlagen zu
            vertreten hast.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">9. Unsere Haftung; Deine Haftung</h3>
        <div className="point bottom-22">
          <span className="number">9.1</span>
          <p className="text">
            Für Ansprüche aufgrund von Schäden, die durch uns, unsere
            gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden,
            haften wir stets unbeschränkt
          </p>
        </div>
        <ul className="info-list pl-8">
          <li className="item">
            bei Verletzung des Lebens, des Körpers oder der Gesundheit,
          </li>
          <li className="item">
            bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung,
          </li>
          <li className="item">
            bei Garantieversprechen, soweit vereinbart, oder
          </li>
          <li className="item">
            soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet
            ist.
          </li>
        </ul>
        <div className="point">
          <span className="number">9.2</span>
          <p className="text">
            Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die
            ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht
            und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen
            darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns,
            unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die
            Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren
            Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet
            werden muss.
          </p>
        </div>
        <div className="point">
          <span className="number">9.3</span>
          <p className="text">
            Im Übrigen sind Ansprüche auf Schadensersatz ausgeschlossen.
          </p>
        </div>
        <div className="point">
          <span className="number">9.4</span>
          <p className="text">
            Sollten wir aufgrund von Verstößen gegen Deine Pflichten nach 4.3
            bis 4.6 in Anspruch genommen werden, behalten wir uns vor, die
            dadurch entstandenen Schäden bei Dir geltend zu machen. Dies gilt
            insbesondere für Verstöße gegen die Nutzungsbedingungen der
            jeweiligen Streaming-Services sowie Verstöße durch von Dir
            generierten Inhalte.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">10. Streitbeilegung</h3>
        <div className="point">
          <p className="text">
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit, die Du hier findest. Zur
            Teilnahme an einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht
            bereit.
          </p>
        </div>
      </div>
      <div>
        <h3 className="section-title">11. Änderungen der AGB</h3>
        <div className="point">
          <span className="number">11.1</span>
          <p className="text">
            Wir behalten uns vor, diese Allgemeinen Nutzungsbedingungen mit
            Wirkung für die Zukunft zu ändern, soweit hierdurch wesentliche
            Regelungen des Vertragsverhältnisses (insbesondere solche über die
            Laufzeit der vereinbarten Leistungen bzw. deren Art und Umfang
            einschließlich der Regelungen zur Kündigung) nicht berührt werden
            und diese nötig sind, um Entwicklungen zu berücksichtigen, die für
            uns nicht vorhersehbar waren und deren Nichtberücksichtigung zu
            einer erheblichen Störung des Vertragsverhältnisses führen würden.
            Darüber hinaus können Änderungen vorgenommen werden, um nach
            Vertragsschluss entstandene Regelungslücken zu schließen oder
            Gesetzesänderungen zu berücksichtigen.
          </p>
        </div>
        <div className="point">
          <span className="number">11.2</span>
          <p className="text">
            Sollte dies der Fall sein, werden wir Dir die geplanten Änderungen
            der Allgemeinen Nutzungsbedingungen umgehend per E-Mail an die
            E-Mail-Adresse mitteilen, die Du für Deinen Account hinterlegt hast.
            Du erhältst diese Mitteilung mindestens 30 Tage vor dem
            Wirksamwerden der geplanten Änderung dieser Allgemeinen
            Geschäftsbedingungen. Du hast dann 30 Tage Zeit der geplanten
            Änderung zu widersprechen.
          </p>
        </div>
        <div className="point">
          <span className="number">11.3</span>
          <p className="text">
            Sofern Du der geplanten Änderung der Allgemeinen
            Geschäftsbedingungen widersprichst, wird der Vertrag zu den
            bestehenden Konditionen fortgeführt. Wir behalten uns für diesen
            Fall vor das Vertragsverhältnis zum nächstmöglichen Zeitpunkt
            ordentlich zu kündigen.
          </p>
        </div>
      </div>
      <p className="text-xs text-gray-shuttle">Stand Mai 2020, Worpswede</p>
      <div>
        <Button
          onClick={() =>
            window
              .open(
                'https://www.lemonway.com/wp-content/uploads/2020/04/3.-LEMON-WAY-Terms-Conditions_DE_2020.03.pdf',
                '_blank'
              )
              ?.focus()
          }
        >
          Lemon Way AGBS - Deutsch
        </Button>
        <Button
          onClick={() =>
            window
              .open(
                'https://www.lemonway.com/wp-content/uploads/2020/04/3.-LEMON-WAY-Terms-Conditions_EN_2020.03.pdf',
                '_blank'
              )
              ?.focus()
          }
        >
          Lemon Way T&C - English
        </Button>
      </div>
    </div>
    <Footer />
  </div>
)
