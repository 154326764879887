import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { gql } from '@/gql'
import { AuthContext } from '@/providers/Auth/context'
import { getErrorMessage } from '@/utils/errors.ts'
import { toastError } from '@/utils/toast'
import { useMutation } from '@apollo/client'
import { TextareaAutosize } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { ErrorMessage, Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

const CANCEL_JOIN_REQUEST = gql(`
  mutation cancelRequest($serviceId: String!, $feedback: String!) {
    service: cancelRequest(serviceId: $serviceId, feedback: $feedback) {
      id
    }
  }
`)

export const WithdrawSubscription: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const { t } = useTranslate()
  const { id } = useParams<{ id: string }>()
  const { currentUser } = useContext(AuthContext)!
  const navigate = useNavigate()

  const [cancelled, setCancelled] = useState(false)
  const [cancelService] = useMutation(CANCEL_JOIN_REQUEST)

  const handleCancel = async (feedback: string) => {
    try {
      await cancelService({ variables: { serviceId: id!, feedback } })
      setCancelled(true)
    } catch (e) {
      console.error(e)
      toastError(getErrorMessage(e))
    }
  }

  return (
    <PopupSkeleton showClose={!cancelled} onClose={onClose}>
      <Formik
        initialValues={{ feedback: '' }}
        validationSchema={yup.object({
          feedback: yup
            .string()
            .min(8, t('form.inputs.errors.feedback.length'))
            .required(t('form.inputs.errors.feedback.required'))
        })}
        onSubmit={({ feedback }) => handleCancel(feedback)}
      >
        {(formik) => (
          <Form className="w-[450px] h-full max-md:w-full p-10 text-center">
            <div className="flex justify-center mb-6">
              <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3" />
              <div className="mx-2 border border-dashed border-bombay rounded p-2">
                <ImgProfile
                  img={currentUser?.avatar}
                  className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50"
                />
              </div>
              <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3" />
            </div>

            <h1 className="text-2xl mb-4">
              <T
                keyName={
                  cancelled
                    ? 'components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.withdrawSuccessfully.title'
                    : 'components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.title'
                }
              />
            </h1>
            <h6 className="text-gray-100 mb-4">
              <T
                keyName={
                  cancelled
                    ? 'components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.withdrawSuccessfully.info'
                    : 'components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.info'
                }
              />
            </h6>

            <h6 className="text-xs text-gray-shuttle-soft mb-8">
              <T keyName="components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.tip" />
            </h6>

            {!cancelled && (
              <>
                <TextareaAutosize
                  name="feedback"
                  className="w-full border border-gray-shuttle rounded-md p-4 mt-2 resize-none"
                  onChange={formik.handleChange}
                  minRows={5}
                  maxRows={5}
                  placeholder={t('form.inputs.placeholders.feedback')}
                />

                <ErrorMessage
                  name="feedback"
                  render={(msg) => (
                    <span className="text-pink-brink">{msg}</span>
                  )}
                />
              </>
            )}

            <div className="flex justify-center gap-6 mt-8">
              {cancelled ? (
                <Button
                  onClick={() =>
                    navigate('/overview/subscriptions', { state: 'refetch' })
                  }
                  className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
                >
                  <T keyName="components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.withdrawSuccessfully.btn" />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={onClose}
                    className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
                  >
                    <T keyName="components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.cancel" />
                  </Button>

                  <Button
                    type="submit"
                    className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
                  >
                    <T keyName="components.joinSubscription.joinDetails.serviceDetails.withdrawSubscription.confirm" />
                  </Button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </PopupSkeleton>
  )
}
