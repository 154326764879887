import { ArrowBack, Close } from '@/assets/icons'
import { useNavigate } from 'react-router-dom'

export const PopupSkeleton: React.FC<{
  children: React.ReactNode
  showGoBack?: boolean
  showClose?: boolean
  onGoBack?: () => void
  onClose?: () => void
  onCloseLink?: string
}> = ({ children, showGoBack, showClose, onClose, onCloseLink, onGoBack }) => {
  const navigate = useNavigate()

  return (
    <div className="bg-white relative rounded-lg max-md:w-full">
      {showGoBack && (
        <div className="absolute cursor-pointer top-6 left-6 z-10 max-md:top-4 max-md:left-4 [&_polyline]:stroke-white [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger">
          <ArrowBack
            onClick={onGoBack ?? (() => navigate(-1))}
            className="w-8 h-8"
          />
        </div>
      )}
      {showClose && (
        <div
          className="absolute cursor-pointer top-6 right-6 z-10 max-md:top-4 max-md:right-4 
          [&_circle]:hover:fill-pink-brink [&_circle]:hover:stroke-pink-brink
          [&_path]:hover:stroke-white"
        >
          <Close
            onClick={
              onClose ??
              (() =>
                onCloseLink
                  ? navigate(onCloseLink, { state: 'refetch' })
                  : navigate(-1))
            }
            className="w-8 h-8"
          />
        </div>
      )}
      {children}
    </div>
  )
}
