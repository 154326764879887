import { gql } from '@/gql'

export const SUB_CHAT_MESSAGE = gql(`
  subscription chatMessage($serviceId: String!) {
    chatMessage: subscribeChatMessage(serviceId: $serviceId) {
      id
      content
      createdAt
      userId
      user {
        firstName
        fullName
        avatar
      }
    }
  }
`)

export const GET_UNREAD_COUNT = gql(`
  query GetUnreadCount($serviceId: String!) {
    unreadCount: getUnreadChatMessagesCount(serviceId: $serviceId)
  }
`)

export const GET_SERVICE_IDS = gql(`
  query getServiceIds {
    services: me {
      servicesOwned {
        id
      }
      servicesJoined {
        id
      }
    }
  }
`)
