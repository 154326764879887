import { toastError, toastSuccess } from './toast.ts'

function writeToClipboardLegacy(content: string) {
  const textArea = document.createElement('textarea')
  textArea.value = content
  textArea.style.opacity = '0'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    const success = document.execCommand('copy')
    if (success) {
      toastSuccess('Copied to clipboard')
    } else {
      toastError('Failed to copy to clipboard. Please do it manually.')
    }
  } catch (err) {
    toastError('Failed to copy to clipboard. Please do it manually.')
  }
  document.body.removeChild(textArea)
}

async function writeToClipboardNew(content: string) {
  try {
    await navigator.clipboard.writeText(content)
    toastSuccess('Copied to clipboard')
  } catch {
    return writeToClipboardLegacy(content)
  }
}

export async function writeToClipboard(content: string) {
  if ('clipboard' in navigator) {
    await writeToClipboardNew(content)
  } else {
    writeToClipboardLegacy(content)
  }
}
