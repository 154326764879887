import { HeartSolid, Plane, Savings } from '@/assets/illustrations'
import { LinkButton } from '@/components/Inputs/Button'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { ProviderStatus } from '@/gql/graphql.ts'
import { useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import type { ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { SubscriptionPopupContainer } from './PopupContainer'

const PROVIDER_DETAILS = gql(`
  query getProviderDetailsForJoinPopup($providerId: String!) {
    provider(where: { id: $providerId }) {
      name
      status
      brand
    }
  }
`)

export const JoinSubscriptionPopup: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  if (!id) navigate('/overview/subscriptions')

  const { data, loading } = useQuery(PROVIDER_DETAILS, {
    variables: { providerId: id! }
  })

  let content: ReactElement
  if (loading) content = <Spinner />
  else if (data?.provider?.status !== ProviderStatus.Active) {
    invariant(data, 'Data should be resolved')
    content = (
      <>
        <h1 className="text-2xl font-medium mb-2">
          <T
            keyName="components.offerSubscription.steps.comingSoon.title"
            params={{
              providername: () => data.provider.name
            }}
          />
        </h1>
        <h6 className="text-gray-shuttle-soft text-sm mb-8">
          <T keyName="components.offerSubscription.steps.comingSoon.subTitle" />
        </h6>

        <div className="flex justify-center">
          <img src={data.provider.brand} alt="" />
        </div>
      </>
    )
  } else {
    content = (
      <>
        <h1 className="text-2xl font-medium mb-8">
          <T keyName="components.joinSubscription.steps.howDoesThisWork.title" />
        </h1>

        <div className="max-md:mx-5">
          <h6 className="text-gray-shuttle-soft text-sm mb-4">
            <T keyName="components.joinSubscription.steps.howDoesThisWork.subtitle1" />
          </h6>
          <div className="flex gap-8 mb-5">
            <div className="w-[75px] h-[75px] flex-shrink-0 flex flex-col justify-center">
              <img src={Plane} alt="" className="w-[96px] -mr-3 max-w-max" />
            </div>
            <div>
              <h1 className="text-lg font-medium mb-1">
                <T keyName="components.joinSubscription.steps.howDoesThisWork.step1Title" />
              </h1>
              <p className="text-sm text-gray-shuttle-soft">
                <T keyName="components.joinSubscription.steps.howDoesThisWork.step1Subtitle" />
              </p>
            </div>
          </div>

          <h6 className="text-gray-shuttle-soft text-sm mb-4">
            <T keyName="components.joinSubscription.steps.howDoesThisWork.subtitle2" />
          </h6>
          <div className="flex gap-8 mb-5">
            <div className="w-[75px] h-[75px] flex-shrink-0 flex flex-col justify-center">
              <img src={HeartSolid} alt="" className="w-[62px] h-auto ml-3" />
            </div>
            <div>
              <h1 className="text-lg font-medium mb-1">
                <T keyName="components.joinSubscription.steps.howDoesThisWork.risk1Title" />
              </h1>
              <p className="text-sm text-gray-shuttle-soft">
                <T keyName="components.joinSubscription.steps.howDoesThisWork.risk1Subtitle" />
              </p>
            </div>
          </div>
          <div className="flex gap-8 mb-8">
            <div className="w-[75px] h-[75px] flex-shrink-0 flex flex-col justify-center">
              <img src={Savings} alt="" className="w-[55px] ml-4" />
            </div>
            <div>
              <h1 className="text-lg font-medium mb-1">
                <T keyName="components.joinSubscription.steps.howDoesThisWork.risk2Title" />
              </h1>
              <p className="text-sm text-gray-shuttle-soft">
                <T keyName="components.joinSubscription.steps.howDoesThisWork.risk2Subtitle" />
              </p>
            </div>
          </div>
        </div>

        <div className="md:hidden h-28" />
        <div className="flex justify-end items-center gap-4 max-md:justify-center max-md:fixed max-md:bg-white max-md:bottom-0 max-md:w-full max-md:h-28 md:mt-auto max-md:inset-x-0">
          <h6 className="text-xs text-gray-shuttle-soft max-md:text-base">
            <T keyName="components.joinSubscription.steps.howDoesThisWork.soundsGreat" />
          </h6>
          <LinkButton to={`/overview/subscriptions/new-join/${id}/service`}>
            <T keyName="components.joinSubscription.steps.howDoesThisWork.btn" />
          </LinkButton>
        </div>
      </>
    )
  }

  return (
    <SubscriptionPopupContainer type="join">
      {content}
    </SubscriptionPopupContainer>
  )
}
