import { CardGeneric, CartesBancaires, Mastercard, Visa } from '@/assets/icons'
import { DefaultCard as DefaultCardImg } from '@/assets/illustrations'
import { Button } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { gql } from '@/gql'
import { useMutation } from '@apollo/client'
import { T } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const SET_DEFAULT_CARD = gql(`
  mutation changeCard($cardId: Int!) {
    changeDefaultCard(cardId: $cardId)
  }
`)

export const DefaultCard: React.FC = () => {
  const navigate = useNavigate()

  const { cardId } = useParams<{ cardId: string }>()
  if (!cardId) navigate('/overview/payments/payment-methods')

  const location = useLocation()
  const [cardData, setCardData] = useState<{
    number: string | null
    type: string | null
    expiration: string | null
  }>({ number: null, type: null, expiration: null })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const number = searchParams.get('num')
    const type = searchParams.get('type')
    const expiration = searchParams.get('exp')
    setCardData({ number, type, expiration })
  }, [location])

  const [setDefaultCard] = useMutation(SET_DEFAULT_CARD)

  const handleSetDefaultCard = async () => {
    await setDefaultCard({ variables: { cardId: Number.parseInt(cardId!) } })
    navigate('/overview/payments/payment-methods', {
      state: 'refetch'
    })
  }

  let cardImage = <CardGeneric />
  switch (cardData?.type) {
    case 'VISA':
      cardImage = <Visa />
      break
    case 'MASTERCARD':
      cardImage = <Mastercard />
      break
    case 'CB':
      cardImage = <CartesBancaires />
      break
  }

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <h1 className="text-2xl mb-4">
          <T keyName="components.payments.paymentMethods.popup.cardDefault.title" />
        </h1>
        <h6 className="text-sm text-gray-shuttle-soft mb-6">
          <T keyName="components.payments.paymentMethods.popup.cardDefault.text" />
        </h6>

        {cardData.number && (
          <div
            className="flex justify-center items-center relative w-[380px] text-sm text-gray-shuttle
              py-4 gap-6 border rounded-2xl border-bombay bg-iron/20 max-md:w-[320px]"
          >
            <div className="h-7 [&_svg]:h-7 [&_svg]:w-[42px]">{cardImage}</div>
            <div>{cardData.number}</div>
            <div>{cardData.expiration}</div>
            <div className="flex items-center absolute z-50 -left-6 max-md:-left-4">
              <img
                src={DefaultCardImg}
                alt="default-card"
                className="w-12 max-md:w-9"
              />
            </div>
          </div>
        )}

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.payments.paymentMethods.popup.newCard.btnCancel" />
          </Button>
          <Button
            onClick={handleSetDefaultCard}
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T keyName="components.payments.paymentMethods.popup.setDefaultCard.btnOk" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
