import {
  Box,
  Enjoy,
  Join
} from '@/assets/landing_page/easy_start/illustrations'
import { Button } from '@/components/Inputs/Button'
import { T } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'

export const EasyToStart: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div
      className="flex flex-col max-w-[886px] w-full m-auto pb-[125px] relative text-center
        max-md:pb-[184px] max-md:overflow-hidden"
    >
      <h2 className="text-tuna text-5xl mb-2 max-md:text-4xl">
        <T keyName="components.landingPage.easyToStart.title" />
      </h2>
      <h4 className="text-emperor text-xl mb-[84px] max-md:text-tuna max-md:text-lg max-md:mb-[52px]">
        <T keyName="components.landingPage.easyToStart.subtitle" />
      </h4>
      <div className="flex justify-between w-full m-auto mb-[52px] max-md:flex-col max-md:mb-6">
        <Step
          title={
            <T keyName="components.landingPage.easyToStart.steps.one.title" />
          }
          subtitle={
            <T keyName="components.landingPage.easyToStart.steps.one.subtitle" />
          }
          img={Join}
        />
        <Step
          title={
            <T keyName="components.landingPage.easyToStart.steps.two.title" />
          }
          subtitle={
            <T keyName="components.landingPage.easyToStart.steps.two.subtitle" />
          }
          img={Box}
        />
        <Step
          title={
            <T keyName="components.landingPage.easyToStart.steps.three.title" />
          }
          subtitle={
            <T keyName="components.landingPage.easyToStart.steps.three.subtitle" />
          }
          img={Enjoy}
        />
      </div>
      <div>
        <Button
          className="border-none bg-purple text-white px-8 py-4 rounded
        hover:bg-purple-heart active:bg-purple-heart focus:bg-purple-heart"
          onClick={() => navigate('/auth/signup')}
        >
          <T keyName="components.landingPage.easyToStart.btn" />
        </Button>
      </div>
    </div>
  )
}

const Step: React.FC<{
  title: JSX.Element | string
  subtitle: JSX.Element | string
  img: string
}> = ({ title, subtitle, img }) => {
  return (
    <div className="w-full max-w-[186px] max-md:max-w-[315px] max-md:m-auto max-md:flex">
      <div
        className="max-h-[136px] w-full h-full mx-auto mb-11
        flex justify-center max-md:flex max-md:max-w-[73px] max-md:mr-7"
      >
        <img src={img} alt="" className="max-md:h-[73px] max-md:w-[73px]" />
      </div>
      <div className="max-md:text-left">
        <div className="mb-1 text-emperor text-lg font-medium max-md:mb-2">
          {title}
        </div>
        <div className="leading-6 md:text-sm text-gray">{subtitle}</div>
      </div>
    </div>
  )
}
