import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin
} from '@brainhubeu/react-carousel'
import { T } from '@tolgee/react'
import '@brainhubeu/react-carousel/lib/style.css'
import { MobileBearServices } from '@/assets/illustrations/mobile'
import {
  DoorBear,
  NewsFrankfurter,
  NewsGolem,
  NewsKreiszeitung,
  NewsMannheim,
  NewsT3N,
  NewsTZ
} from '@/assets/landing_page/header/illustrations'
import { Girl1, Girl2, Man1, Man2 } from '@/assets/landing_page/header/images'
import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs/Button'
import { useScreenDetector } from '@/hooks/ScreenDetector'
import { useNavigate } from 'react-router-dom'

export const LandingHeader: React.FC = () => {
  const navigate = useNavigate()
  const { isSmall } = useScreenDetector()

  return (
    <>
      <div
        className="flex text-left relative w-full pt-6 mt-0
        mx-auto overflow-hidden max-md:flex-col"
      >
        <div className="max-w-[732px] w-full flex-shrink-0 max-md:m-auto">
          <div
            className="relative ml-[106px] mt-[122px] max-md:text-center
            max-md:mt-16 max-md:mx-auto max-md:mb-0"
          >
            <h1 className="text-6xl max-md:text-5xl max-md:text-center text-white">
              <T
                keyName={
                  isSmall
                    ? 'components.landingPage.header.title'
                    : 'components.landingPage.header.titleDesktop'
                }
              />
            </h1>
            <h1 className="text-6xl max-md:text-5xl mb-5 text-white font-medium">
              <T
                keyName={
                  isSmall
                    ? 'components.landingPage.header.subTitle'
                    : 'components.landingPage.header.subTitleDesktop'
                }
              />
            </h1>

            <h3 className="text-2xl text-titan mb-14 max-md:hidden">
              <T keyName="components.landingPage.header.easyToShareDesktop" />
            </h3>
            <Button
              className="text-white bg-pink-tickle border-none px-8 py-4
            shadow-[0_6px_20px_0] shadow-[#0003] hover:bg-french-rose
            hover:text-white max-md:hidden"
              onClick={() => navigate('/auth/signup')}
            >
              <T keyName="components.landingPage.header.btn" />
            </Button>
          </div>
        </div>

        <div className="max-w-[715px] w-full flex-shrink-0 relative max-md:m-auto">
          <div className="top-0 left-0 relative -z-10 flex justify-center max-md:mx-auto max-md:my-[-35px]">
            <img
              src={isSmall ? MobileBearServices : DoorBear}
              alt=""
              className="max-w-max max-h-[680px]"
            />
          </div>
        </div>
      </div>
      <div className="w-full text-center md:hidden">
        <Button
          className="text-white bg-pink-tickle px-12 py-5 text-xl font-medium
              shadow-[0_6px_20px_0] shadow-[#0003] hover:bg-french-rose hover:text-white
              focus:bg-french-rose active:bg-french-rose border-none"
          onClick={() => navigate('/auth/signup')}
        >
          <T keyName="components.landingPage.header.btn" />
        </Button>
      </div>

      <div className="mt-4 mb-16 text-center max-md:mt-10 max-md:mb-6">
        <Carousel
          plugins={[
            'infinite',
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: isSmall ? 3 : 6
              }
            },
            {
              resolve: autoplayPlugin,
              options: {
                interval: 1000
              }
            }
          ]}
          className="md:px-24"
        >
          <a
            href="https://www.fr.de/verbraucher/sparen-streaming-anbieter-netflix-disney-amazon-experte-tricks-sparen-accounts-tipps-geld-zr-92850022.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={NewsFrankfurter}
              alt="Frankfurter news article"
              className="px-4"
            />
          </a>
          <a
            href="https://www.golem.de/news/netflix-disney-paramount-und-deezer-partizi-hilft-beim-teilen-von-streamingabos-2403-183328.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NewsGolem} alt="Golem news article" className="px-4" />
          </a>
          <a
            href="https://www.kreiszeitung.de/verbraucher/sparen-streaming-anbieter-netflix-disney-amazon-experte-tricks-sparen-accounts-tipps-geld-zr-92850022.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={NewsKreiszeitung}
              alt="Kreiszeitung news article"
              className="px-4"
            />
          </a>
          <a
            href="https://www.mannheim24.de/verbraucher/verbraucher-magazin/sparen-streaming-anbieter-netflix-disney-amazon-experte-tricks-sparen-accounts-tipps-geld-zr-92850022.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={NewsMannheim}
              alt="Mannheim news article"
              className="px-4"
            />
          </a>
          <a
            href="https://t3n.de/news/legale-sharing-plattform-partizi-netflix-disney-plus-guenstiger-1622807/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NewsT3N} alt="T3N news article" className="px-4" />
          </a>
          <a
            href="https://www.tz.de/verbraucher/experte-streaming-netflix-disney-amazon-account-teilen-geld-sparen-tricks-tipps-92850014.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NewsTZ} alt="TZ news article" className="px-4" />
          </a>
        </Carousel>
        <h6 className="mt-4 text-white text-sm">
          <T keyName="components.landingPage.header.newsTrust" />
        </h6>
      </div>

      <div className="w-full text-center mb-4 md:hidden">
        <h3 className="text-4xl text-titan">
          <T keyName="components.landingPage.header.easyToShare" />
        </h3>
      </div>
      <div className="absolute right-0 top-[600px] max-md:relative max-md:top-0 max-md:mb-24">
        <div
          className="max-h-[88px] max-w-[378px] w-full h-full rounded-lg
            bg-gradient-to-r from-blue-cornflower to-blue-dodger
            shadow-[0_-15px_55px_0] shadow-[#0003]
            max-md:bg-none max-md:shadow-none max-md:max-h-[126px] max-md:m-auto"
        >
          <div className="flex p-[23px_0_23px_24px] w-[378px] box-border max-md:flex-col max-md:p-0">
            <div className="mr-3 flex max-md:m-auto">
              <ImgProfile
                img={Girl1}
                className="z-40 w-10 h-10 max-md:w-20 max-md:h-20 max-md:mb-4"
              />
              <ImgProfile
                img={Man1}
                className="z-30 w-10 h-10 max-md:w-20 max-md:h-20 max-md:mb-4 -ml-2"
              />
              <ImgProfile
                img={Man2}
                className="z-20 w-10 h-10 max-md:w-20 max-md:h-20 max-md:mb-4 -ml-2"
              />
              <ImgProfile
                img={Girl2}
                className="z-10 w-10 h-10 max-md:w-20 max-md:h-20 max-md:mb-4 -ml-2"
              />
            </div>
            <div className="max-md:flex max-md:m-auto">
              <h4 className="text-lg font-bold text-white">
                2,578{' '}
                <T keyName="components.landingPage.header.usersAlreadyJoined.users" />
              </h4>
              <p className="text-sm max-md:text-lg text-zircon max-md:ml-1">
                <T keyName="components.landingPage.header.usersAlreadyJoined.joined" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
