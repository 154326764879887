import { Success } from '@/assets/illustrations'
import { Footer } from '@/components/Footer'
import { PublicNavbar } from '@/components/PublicNavbar'
import './style.css'

export const PrivacyPolicy: React.FC = () => (
  <div>
    <PublicNavbar lightBg />
    <div className="public-page">
      <div className="flex justify-center mb-4">
        <img src={Success} alt="success cloud" />
      </div>
      <h2 className="title">Datenschutzerklärung</h2>
      <p>
        Partizi ist eine Sharing Plattform für privat Kunden, die
        professioneller Geheimhaltung unterliegt. Partizi garantiert die
        Sicherheit und Vertraulichkeit der anvertrauten Daten.
      </p>
      <p>
        Die neue EU General Data Protection Regulation (GDPR) trat am 25. Mai
        2018 in Kraft. Wir haben das zum Anlass genommen, unsere
        Datenschutzerklärung (von jetzt an die Erklärung) zu überarbeiten. Diese
        Erklärung beschreibt die Maßnahmen, die Partizi als
        Datenverantwortlicher ergriffen hat, um die Einhaltung des Schutzes
        Ihrer personenbezogenen Daten zu gewährleisten. Diese Erklärung ist ein
        integraler Teil der AGBs. Definierte Begriffe haben die selbe Bedeutung
        in den AGBs und in dieser Erklärung.
      </p>
      <p>
        Wann immer persönliche Informationen auf Partizi erhoben werden,
        versuchen wir einen Link zu dieser Erklärung bereitzustellen. Die
        aktuelle Version dieser Erklärung finden Sie hier. Wenn es Änderungen
        gibt, werden Sie über diese Seite oder auf andere Weise informiert.
        Erfahren Sie hier mehr zu unserer Cookie Richtlinien.
      </p>
      <div>
        <h3 className="section-title">
          1. Welche Daten sammeln und verarbeiten wir?
        </h3>
        <p>
          Partizi sammelt eine Vielzahl von Daten über die unterschiedlichen
          Online-Angebote:
        </p>
        <ul className="info-list">
          <li className="item">
            Persönliche Daten (Vorname, Nachname, Geburtstag und Geburtsort
            etc.);
          </li>
          <li className="item">
            Kontaktdaten (Telefonnummer, Anschrift und E-Mail-Adresse etc.);
          </li>
          <li className="item">
            Transaktionen (z.B. Transfers, Kreditkartenzahlungen, Aufladungen
            und Auszahlungen von Ihrem Partizi Account);
          </li>
          <li className="item">
            Steuerdaten (Steuersitz, Steuernummer, etc.);
          </li>
          <li className="item">
            Daten über Ihr Online Verhalten und Präferenzen beim Besuch unserer
            Website oder Seiten von Drittanbietern oder Ihre Login Daten;
          </li>
          <li className="item">
            Daten Ihrer Kommunikation mit Partizi (Chat, E-Mails, etc.);
          </li>
          <li className="item">
            Daten die mit Ihrer Zustimmung erhoben wurden und/oder durch
            Regulierungen erhoben werden müssen oder dürfen.
          </li>
        </ul>
        <p>
          Alle diese Daten erhalten wir gewöhnlich direkt von Ihnen, via
          Telefon, Chat oder den Besuch unserer Website. Partizi verarbeitet
          jedoch auch Daten die wir von Drittanbietern erhalten, vor allem um
          den Verpflichtungen der Regulierungsbehörden nachzukommen oder mit
          Ihrem Einverständnis in Verbindung mit der Nutzung bestimmter
          Dienstleistungen.
        </p>
      </div>
      <div>
        <h3 className="section-title">
          2. Wofür diese Daten verarbeitet werden
        </h3>
        <p>
          Die bei Partizi durchgeführte Datenverarbeitung erfüllt einen Zweck,
          der auf der Einhaltung einer gesetzlichen oder regulatorischen
          Bestimmung, der Erfüllung eines Vertrags, der Zustimmung zu Diensten,
          die die Synchronisierung externer Dokumente ermöglichen, oder dem
          legitimen Interesse an der kommerziellen Verwertung beruht.
        </p>
        <ul className="info-list">
          <li className="item">Kundenbeziehungsmanagement</li>
          <li className="item">
            Bereitstellung abonnierter Produkte und Dienstleistungen
          </li>
          <li className="item">
            Bereitstellung von Dienstleistungen der Datensynchronisierung
          </li>
          <li className="item">
            Bearbeitung, Untersuchung und von Abonnements
          </li>
          <li className="item">
            Einhaltung von legalen und behördlichen Bestimmungen
          </li>
          <li className="item">Betrugsbekämpfung</li>
          <li className="item">
            Sicherung von Computernetzwerken und Transaktionen
          </li>
          <li className="item">
            Nachweiserteilung von Transaktionen und Vereinbarungen
          </li>
          <li className="item">
            Inkasso und Forderungsabtretung, Management von Zahlungsvorfällen
          </li>
          <li className="item">
            Maßgeschneiderte Angebote und kommerzielle Angebote
          </li>
        </ul>
        <p />
      </div>
      <div>
        <h3 className="section-title">3. Die Empfänger Ihrer Daten</h3>
        <p>
          Partizi ist dazu verpflichtet, bestimmte Daten an Regulierungsbehörden
          weiterzuleiten, wenn diese angefordert werden oder als Teil von
          regulatorischen und gesetzlichen Verpflichtungen.
        </p>
        <p>
          Partizi kann Ihre persönlichen Daten an die technischen Dienstleister,
          Partner, Versicherungsbroker oder an Unternehmen in der Gruppe
          weiterleiten, wenn dies für einen der oben genannten Gründe notwendig
          ist.
        </p>
      </div>
      <div>
        <h3 className="section-title">4. Login und Soziale Medien</h3>
        <p>
          Wir benutzen soziale Medien, um das Teilen von Abonnements so einfach
          wie möglich zu machen. Um das Teilen und angebotene Services zu
          vermarkten und unsere eigenen Services zu bewerben, zu optimieren und
          zu unterstützen.
        </p>
        <p>
          Durch die Nutzung der sozialen Medien werden personenbezogene Daten
          zwischen uns und dem Social-Media-Anbieter wie im Folgenden
          beschrieben ausgetauscht. Sie können sich gegen die Nutzung jeglicher
          zur Verfügung gestellter sozialer Medien entscheiden.
        </p>
        <ol className="info-list numbered">
          <li className="item">
            <p>
              Melden Sie sich mit Ihrem Social-Media-Konto an. Wir bieten Ihnen
              die Möglichkeit, sich mit einem Ihrer Social-Media-Konten in Ihrem
              Nutzerkonto anzumelden. Grund dafür ist, dass Sie sich so weniger
              Nutzernamen und Passwörter für unterschiedliche Online-Services
              merken müssen.
            </p>
            <p>
              In der Regel werden hier bei Profilfoto, Vorname, Nachname,
              Emailadresse und ggfs. Postleitzahl Ihres Wohnortes übermittelt.
            </p>
            <p>
              Wenn Sie sich einmal angemeldet haben, können Sie Ihr
              Social-Media-Konto verwenden, um sich in Ihrem Partizi.de-Konto
              anzumelden. Sie können bei Bedarf jederzeit Ihr
              Partizi.de-Nutzerkonto vom ausgewählten Social-Media-Konto
              trennen.
            </p>
          </li>
          <li className="item">
            <p>
              Wir haben zudem Social-Media-Plugins in die Webseite Partizi.de
              integriert. Wenn Sie auf einen Button, wie zum Beispiel den
              Facebook-Share „Auf Facebook teilen” klicken oder tippen, werden
              bestimmte Informationen mit diesen Social-Media-Anbietern geteilt.
            </p>
            <p>
              Wenn Sie gleichzeitig in Ihrem Social-Media-Konto angemeldet sind
              und einen dieser Buttons anklicken oder antippen, kann Ihr
              Social-Media-Anbieter diese Information Ihrem Social-Media-Konto
              zuordnen. Abhängig von Ihren Einstellungen können diese
              Aktivitäten auf Ihrem Social-Media-Profil angezeigt und von
              anderen in Ihrem Netzwerk gesehen werden.
            </p>
          </li>
        </ol>
        <p>
          Wir nutzen diese Informationen, um die Bereitstellung des von Ihnen
          angefragten Services zu unterstützen – zum Beispiel leiten wir eine
          Nachricht, die Sie Ihren Kontakten schicken möchten, weiter oder
          erstellen eine individuelle Nutzererfahrung innerhalb der App oder auf
          unseren Webseiten. Wir können also, wenn Sie dies wünschen, unsere
          Services Ihren Bedürfnissen anpassen – wir bringen Ihre Freunde und
          Sie selbst mit den besten Services zusammen und analysieren bzw.
          erweitern so unsere Services.
        </p>
        <p>
          Wenn Sie sich über Ihr Facebook- oder Google-Konto verbinden, schauen
          Sie sich bitte die folgenden Links mit Informationen an, wie diese
          Parteien die erhaltenen Daten nutzen. &nbsp;
          <a
            target="_blank"
            href="https://www.facebook.com/policy.php"
            rel="noreferrer"
          >
            Facebook
          </a>{' '}
          und{' '}
          <a
            target="_blank"
            href="https://policies.google.com/privacy"
            rel="noreferrer"
          >
            Google
          </a>
          .
        </p>
      </div>
      <div>
        <h3 className="section-title">5. Sicherung Ihrer Daten</h3>
        <p>
          Wir verwenden SSL-Technologien um die Vertraulichkeit der
          Datenverbindungen zu garantieren.
        </p>
      </div>
      <div>
        <h3 className="section-title">
          6. Zeitlimit und Aufbewahrung Ihrer Daten
        </h3>
        <p>
          Um die richtige Verarbeitung von Finanztransaktionen zu garantieren,
          müssen wir Ihre persönlichen Daten aufbewahren und über die Dauer
          Ihres Nutzungsvertrages regelmäßig erneuern.
        </p>
        <p>
          Um gesetzlichen und regulatorischen Verpflichtungen und Anfragen Folge
          zu leisten, werden Ihre Daten für 5 Jahre ab Ihrer letzten Aktivität
          aufbewahrt.
        </p>
        <p>Einige Daten werden anonymisiert und für Statistiken verwendet.</p>
      </div>
      <div className="section bottom-36">
        <h3 className="section-title">7. Ihre Rechte</h3>
        <p>
          In Übereinstimmung mit der Datenschutz-Grundverordnung (DSGVO) haben
          Sie das Recht, auf Ihre Daten zuzugreifen, diese zu korrigieren, zu
          löschen, die Verarbeitung einzuschränken, die Übertragung
          einzuschränken und zu bestimmen was mit diesen im Todesfall geschehen
          soll. Diese Rechte können unter den Konditionen und innerhalb der
          Grenzen der DSGVO ausgeübt werden.
        </p>
        <p>
          Sie können Ihre Rechte ausüben, indem Sie sich an den
          Datenschutzbeauftragten von Partizi unter{' '}
          <a href="mailto:Team@Partizi.de">Team@Partizi.de</a> wenden.
        </p>
        <p>
          Sie haben das Recht, eine Beschwerde bei der Landesbeauftragte(n) für
          den Datenschutz Niedersachsen, der überwachenden Behörde für die
          Einhaltung von Verpflichtungen bezüglich persönlicher Daten,
          einzureichen.
        </p>
      </div>
      <div className="text-xs text-gray-shuttle">
        Anwendungsdatum dieser Erklärung: 12. Dezember 2021
      </div>
    </div>
    <Footer />
  </div>
)
