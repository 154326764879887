import { Spinner } from '@/components/Spinner'
import { KEYS } from '@/consts.ts'
import type { SocialAuthProviders } from '@/gql/graphql.ts'
import { fileUrlToBase64 } from '@/utils'
import { type ObservableSubscription, useApolloClient } from '@apollo/client'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useCallback, useEffect, useState } from 'react'
import invariant from 'tiny-invariant'
import { AuthContext } from './context'
import { GET_ME, LOGIN, SIGNUP, SOCIAL_LOGIN, SUB_ME } from './queries'

export type User = NonNullable<ResultOf<typeof GET_ME>>['user']

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)
  const [userSub, setUserSub] = useState<ObservableSubscription | null>(null)

  const client = useApolloClient()

  const logOut = useCallback(async () => {
    localStorage.removeItem(KEYS.ACCESS_TOKEN)
    localStorage.removeItem(KEYS.REFRESH_TOKEN)

    setCurrentUser(undefined)

    userSub?.unsubscribe()

    setUserSub(null)
    setAccessToken(null)

    await client.clearStore()
  }, [userSub, client])

  const fetchUser = useCallback(() => {
    setFetching(true)
    ;(async () => {
      try {
        const res = await client.query({ query: GET_ME })

        const user = res.data.user
        setCurrentUser(user)

        userSub?.unsubscribe()

        const newUserSub = client
          .subscribe({ query: SUB_ME })
          .subscribe((subscribeResult) => {
            if (subscribeResult.data) {
              const { user } = subscribeResult.data
              if (user) {
                setCurrentUser(user)
              }
            }
          })

        setUserSub(newUserSub)
        setFetching(false)
      } catch (err) {
        console.error(err)
        logOut()
        setFetching(false)
      }
    })()
  }, [client, userSub, logOut])

  const storeTokens = useCallback(
    async (tokens: { accessToken: string; refreshToken: string }) => {
      const { accessToken, refreshToken } = tokens

      localStorage.setItem(KEYS.ACCESS_TOKEN, accessToken)
      localStorage.setItem(KEYS.REFRESH_TOKEN, refreshToken)

      await client.resetStore()

      return fetchUser()
    },
    [fetchUser, client]
  )

  const logIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const res = await client.mutate({
        mutation: LOGIN,
        variables: { email, password }
      })

      invariant(res.data, 'Data should be loaded')

      if (res.data.login) {
        return storeTokens(res.data.login)
      }
      throw new Error('Invalid credentials')
    },
    [client, storeTokens]
  )

  const signUp = useCallback(
    async (values: {
      firstName: string
      lastName: string
      email: string
      password: string
      avatar: string
      referrerCode?: string
      source: string
    }) => {
      let avatar = values.avatar
      if (values.avatar.startsWith('/') || values.avatar.startsWith('http')) {
        avatar = await fileUrlToBase64(values.avatar)
      }

      await client.mutate({
        mutation: SIGNUP,
        variables: { ...values, avatar }
      })
      return await logIn({ email: values.email, password: values.password })
    },
    [client, logIn]
  )

  const socialLogIn = useCallback(
    async (values: { provider: SocialAuthProviders; token: string }) => {
      const result = await client.mutate({
        mutation: SOCIAL_LOGIN,
        variables: values
      })
      const { data } = result
      if (data?.socialLogin) {
        return storeTokens(data.socialLogin)
      }
      throw new Error('Invalid credentials')
    },
    [client, storeTokens]
  )

  const [accessToken, setAccessToken] = useState<string | null>(null)

  const loadFromStorage = useCallback(() => {
    const token = localStorage.getItem(KEYS.ACCESS_TOKEN)

    if (token && token !== accessToken) {
      setAccessToken(token)
      fetchUser()
    }
  }, [accessToken, fetchUser])

  useEffect(() => {
    loadFromStorage()

    window.addEventListener('storage', loadFromStorage)

    return () => {
      window.removeEventListener('storage', loadFromStorage)
    }
  }, [loadFromStorage])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setUser: fetchUser,
        signUp,
        logIn,
        socialLogIn,
        logOut
      }}
    >
      {fetching ? (
        <div className="w-screen h-screen">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}
