import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { gql } from '@/gql'
import { getErrorMessage } from '@/utils/errors.ts'
import { toastError } from '@/utils/toast'
import { useMutation, useQuery } from '@apollo/client'
import { TextareaAutosize } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { ErrorMessage, Form, Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import * as yup from 'yup'

const SLOT_DETAILS = gql(`
  query slotDetails($slotId: ID!) {
    slot(where: { id: $slotId }) {
      paymentDueDate
      service {
        provider {
          price {
            offerReturn
          }
        }
      }
      usedBy {
        fullName
        avatar
      }
    }
  }
`)

const REFUND_USER = gql(`
  mutation refundUser($slotId: String!, $feedback: String!) {
    refund(slotId: $slotId, feedback: $feedback) {
      id
    }
  }
`)

export const SlotUserRefund: React.FC = () => {
  const { t } = useTranslate()
  const { slotId } = useParams<{ slotId: string }>()
  invariant(slotId, 'Slot ID should be provided by the route')

  const navigate = useNavigate()

  const { data } = useQuery(SLOT_DETAILS, {
    variables: { slotId }
  })

  const [refundUser] = useMutation(REFUND_USER)

  const handleConfirm = async (feedback: string) => {
    try {
      await refundUser({ variables: { slotId, feedback } })
      navigate('success', {
        replace: true
      })
    } catch (e: unknown) {
      console.error(e)
      toastError(getErrorMessage(e))
    }
  }

  return (
    <PopupSkeleton showClose>
      <Formik
        initialValues={{ feedback: '' }}
        validationSchema={yup.object({
          feedback: yup
            .string()
            .min(8, t('form.inputs.errors.feedback.length'))
            .required(t('form.inputs.errors.feedback.required'))
        })}
        onSubmit={({ feedback }) => handleConfirm(feedback)}
      >
        {(formik) => (
          <Form className="w-[450px] h-full max-md:w-full p-10 text-center">
            <div className="flex flex-col items-center gap-2 mb-6">
              <ImgProfile
                img={data?.slot?.usedBy?.avatar}
                className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50"
              />
              <h6 className="text-xs text-gray-shuttle">
                {data?.slot?.usedBy?.fullName}
              </h6>
              <h6 className="text-pink-brink text-xs">
                <T
                  keyName="components.offerSubscription.offerDetails.overview.refundUserpopup.monthlyPayment"
                  params={{
                    currency: () => '€',
                    amount: () =>
                      data?.slot.service.provider.price.offerReturn.toFixed(
                        2
                      ) ?? '0.00',
                    month: () => (
                      <T keyName="components.offerSubscription.offerDetails.overview.refundUserpopup.month" />
                    )
                  }}
                />
              </h6>
            </div>

            <h3 className="text-lg font-medium mb-4">
              <T
                keyName="components.offerSubscription.offerDetails.overview.refundUserpopup.title"
                params={{
                  username: () => data?.slot?.usedBy?.fullName ?? 'User'
                }}
              />
            </h3>

            <p className="text-sm text-gray-shuttle-soft mb-4">
              <T
                keyName="components.offerSubscription.offerDetails.overview.refundUserpopup.subTitle"
                params={{
                  username: () => data?.slot?.usedBy?.fullName ?? 'User'
                }}
              />
            </p>

            <TextareaAutosize
              name="feedback"
              className="w-full border border-gray-shuttle rounded-md p-4 mt-2 resize-none"
              onChange={formik.handleChange}
              minRows={5}
              maxRows={5}
              placeholder={t('form.inputs.placeholders.feedback')}
            />

            <ErrorMessage
              name="feedback"
              render={(msg) => <span className="text-pink-brink">{msg}</span>}
            />

            <div className="flex justify-center gap-6 mt-4">
              <Button
                onClick={() => navigate('../..', { replace: true })}
                className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
              >
                <T keyName="components.offerSubscription.offerDetails.overview.refundUserpopup.cancel" />
              </Button>
              <Button type="submit">
                <T keyName="components.offerSubscription.offerDetails.overview.refundUserpopup.confirm" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PopupSkeleton>
  )
}
