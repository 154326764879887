import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { useMutation, useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

const SERVICE_DETAILS = gql(`
  query serviceProviderName($serviceId: String!) {
    service(where: { id: $serviceId }) {
      provider {
        name
        brand
      }
    }
  }
`)

const DELETE_SERVICE = gql(`
  mutation deleteService($serviceId: String!) {
    service: deleteService(where: { id: $serviceId }) {
      provider {
        name
        brand
      }
    }
  }
`)

export const RemoveService: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const { data, loading } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId: id! }
  })
  const [deleteService] = useMutation(DELETE_SERVICE)

  const handleConfirm = async () => {
    try {
      const result = await deleteService({ variables: { serviceId: id! } })
      invariant(result.data, 'Data should be resolved')
      navigate('/overview/subscriptions/popup/service-removed', {
        state: {
          name: result.data.service.provider.name,
          brand: result.data.service.provider.brand,
          tabIndex: 1
        }
      })
    } catch (e) {
      console.error(e)
      navigate('error')
    }
  }

  if (loading)
    return (
      <PopupSkeleton showClose>
        <Spinner />
      </PopupSkeleton>
    )

  invariant(data, 'Data should be loaded')
  const name = data.service.provider.name
  const serviceIcon = data.service.provider.brand

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3" />
          <div className="mx-2 border border-dashed border-bombay rounded p-2">
            <ImgProfile img={serviceIcon} />
          </div>
          <div className="w-[72px] h-[72px] bg-bombay/20 rounded mt-3" />
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.details.removeService.title"
            params={{ service: () => name }}
          />
        </h3>
        <h6 className="text-gray-shuttle-soft text-sm mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.details.removeService.subTitle"
            params={{ service: () => name }}
          />
        </h6>

        <div className="flex justify-center gap-6 mt-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.offerSubscription.offerDetails.details.removeService.cancel" />
          </Button>
          <Button onClick={handleConfirm}>
            <T keyName="components.offerSubscription.offerDetails.details.removeService.confirm" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
