import { Coins, Share } from '@/assets/illustrations'
import { Button } from '@/components/Inputs/Button'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { useQuery } from '@apollo/client'
import { Checkbox, FormControlLabel } from '@mui/material'
import { T } from '@tolgee/react'
import { type ReactElement, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { SubscriptionPopupContainer } from './PopupContainer'

const PROVIDER_DETAILS = gql(`
  query getProviderDetailsForOfferPopup($providerId: String!) {
    provider(where: { id: $providerId }) {
      id
      name
      type
      status
      price {
        id
        fullPrice
        commission
        offerReturn
      }
      website
      numberOfSlots
    }
  }
`)

export const OfferSubscriptionPopup: React.FC = () => {
  const [confirmed, setConfirmed] = useState(false)

  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  if (!id) navigate('/overview/subscriptions')

  const { data, loading } = useQuery(PROVIDER_DETAILS, {
    variables: { providerId: id! }
  })

  let content: ReactElement
  if (loading) content = <Spinner />
  else {
    invariant(data, 'Data should be resolved')
    content = (
      <>
        <h1 className="text-2xl font-medium mb-2">
          <T
            keyName="components.offerSubscription.steps.acceptConditions.title"
            params={{
              providername: () => data.provider.name
            }}
          />
        </h1>
        <h6 className="text-gray-shuttle-soft mb-8 text-sm max-md:text-base">
          <T keyName="components.offerSubscription.steps.acceptConditions.subTitle" />
        </h6>
        <div className="flex flex-col gap-8 mb-11">
          <div className="flex gap-8">
            <img src={Coins} alt="Coins" className="w-[83px] h-[83px]" />
            <div>
              <h1 className="text-lg font-medium mb-1">
                <T keyName="components.offerSubscription.steps.acceptConditions.receiveShare.title" />
              </h1>
              <p className="text-sm text-gray-shuttle-soft">
                <T
                  keyName="components.offerSubscription.steps.acceptConditions.receiveShare.subTitle"
                  params={{
                    a: () => (
                      <span className="font-bold">
                        <T keyName="currency.symbol.euro" />
                      </span>
                    ),
                    b: () => (
                      <span className="font-bold">
                        {(data.provider?.price?.offerReturn ?? 0).toFixed(2)}
                      </span>
                    )
                  }}
                />
              </p>
            </div>
          </div>
          <div className="flex gap-8">
            <img src={Share} alt="Coins" className="w-[83px] h-[83px]" />
            <div>
              <h1 className="text-lg font-medium mb-1">
                <T keyName="components.offerSubscription.steps.acceptConditions.alreadySubscription.title" />
              </h1>
              <p className="text-sm text-gray-shuttle-soft">
                <T keyName="components.offerSubscription.steps.acceptConditions.alreadySubscription.subTitle" />
              </p>
            </div>
          </div>
        </div>

        <div className="mb-2">
          <FormControlLabel
            control={<Checkbox name="acceptedTerms" />}
            checked={confirmed}
            onChange={() => setConfirmed((prev) => !prev)}
            label={
              <h6 className="text-xs font-bold max-md:text-sm">
                <T
                  keyName="components.offerSubscription.steps.acceptConditions.checkText"
                  params={{
                    service: () => data.provider.name.split(' ')[0]
                  }}
                />
              </h6>
            }
          />
        </div>
        <h6 className="mb-8 text-xs text-gray-shuttle-soft max-md:text-sm">
          <T
            keyName="components.offerSubscription.steps.acceptConditions.conditions"
            params={{
              service: () => data.provider.name.split(' ')[0],
              providername: () => data.provider.name
            }}
          />
        </h6>
        <div className="flex justify-end items-center gap-4 max-md:flex-col max-md:justify-center">
          <h6 className="text-xs text-gray-shuttle-soft max-md:text-base">
            <T keyName="components.offerSubscription.steps.acceptConditions.soundsGreat" />
          </h6>
          <Button
            onClick={() =>
              confirmed
                ? navigate(
                    `/overview/subscriptions/new-offer/${id}/account-details`
                  )
                : undefined
            }
            disabled={!confirmed}
          >
            <T keyName="components.offerSubscription.steps.acceptConditions.btn" />
          </Button>
        </div>
      </>
    )
  }

  return (
    <SubscriptionPopupContainer type="offer">
      {content}
    </SubscriptionPopupContainer>
  )
}
