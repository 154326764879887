import { Footer } from '@/components/Footer'
import { PopupOutlet } from '@/components/Popup'
import { PublicNavbar } from '@/components/PublicNavbar'
import { SocialAuthProviders } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { useGoogleOneTapLogin } from '@react-oauth/google'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Background } from './Background'
import { EasyToStart } from './EasyToStart'
import { HowItWorks } from './HowItWorks'
import { LandingHeader } from './LandingHeader'
import { SafeAndSound } from './SafeAndSound'
import { SaveYourSpot } from './SaveYourSpot'
import { Subscriptions } from './Subscriptions'
import { Testimonials } from './Testimonials'
import { TrustPilot } from './TrustPilot'

export const LandingPage: React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      if (!credentialResponse.credential) {
        console.log('No credential found')
        return
      }

      authContext
        ?.socialLogIn({
          provider: SocialAuthProviders.Google,
          token: credentialResponse.credential
        })
        .then(() => navigate('/overview/subscriptions'))
        .catch((err: unknown) => {
          console.error('One-tap login failed', err)
        })
    },
    onError: () => {
      console.error('Login Failed')
    }
  })

  return (
    <>
      <div className="relative">
        <Background />
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-full z-10 min-h-screen">
          <PublicNavbar />
          <LandingHeader />
          <Subscriptions />
          <EasyToStart />
          <HowItWorks />
          <SafeAndSound />
          <Testimonials />
          <TrustPilot />
          <SaveYourSpot />
        </div>
        <Footer />
      </div>
      <PopupOutlet />
    </>
  )
}
