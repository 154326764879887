import { ArrowFront, Plus64 } from '@/assets/icons'
import { PopupSkeleton } from '@/components/Popup'
import { T } from '@tolgee/react'
import { Link } from 'react-router-dom'

export const SlotUserAdd: React.FC = () => {
  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <div className="border border-dashed border-blue-dodger rounded p-4">
            <Plus64
              className="rounded-full shadow-[0_0_0_6px] shadow-blue-dodger/20
                [&_path]:stroke-white [&_path]:fill-blue-dodger"
            />
          </div>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T keyName="components.offerSubscription.offerDetails.overview.addUserPopup.title" />
        </h3>

        <div className="flex flex-col gap-4">
          <Link
            to="../popup/invite-friends"
            className="bg-iron/50 w-full flex items-center justify-between gap-2 p-4
            hover:bg-blue-dodger/20 [&_path]:hover:stroke-white
            [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger"
          >
            <h5 className="text-sm">
              <T keyName="components.offerSubscription.offerDetails.overview.addUserPopup.invite" />
            </h5>
            <ArrowFront />
          </Link>
          <Link
            to="../../requests"
            className="bg-iron/50 w-full flex items-center justify-between gap-2 p-4
            hover:bg-blue-dodger/20 [&_path]:hover:stroke-white
            [&_circle]:hover:fill-blue-dodger [&_circle]:hover:stroke-blue-dodger"
          >
            <h5 className="text-sm">
              <T keyName="components.offerSubscription.offerDetails.overview.addUserPopup.pending" />
            </h5>
            <ArrowFront />
          </Link>
        </div>
      </div>
    </PopupSkeleton>
  )
}
