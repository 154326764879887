import { HowItWorksMobile } from '@/assets/illustrations/mobile'
import { HowItWorksImg } from '@/assets/landing_page/how_it_works'
import { useScreenDetector } from '@/hooks/ScreenDetector'
import { T } from '@tolgee/react'
import { twMerge } from 'tailwind-merge'

const Card: React.FC<{ text: JSX.Element; className?: string }> = ({
  text,
  className
}) => (
  <div
    className={twMerge(
      `max-w-[358px] w-full rounded-[10px] relative before:block before:bg-transparent
      before:w-[200px] before:h-[112px] before:-z-10 before:top-0 before:left-0
      before:absolute before:rounded-[10px] before:shadow-[0_2px_60px_0] before:shadow-black/20
      before:max-md:h-[92px] before:max-md:rounded-none max-sm:rounded-none`,
      className
    )}
  >
    <div>
      <h6 className="text-zircon md:text-lg text-left max-md:leading-[26px]">
        {text}
      </h6>
    </div>
  </div>
)

export const HowItWorks: React.FC = () => {
  const { isBig } = useScreenDetector()

  return (
    <div
      className="relative mb-[100px] pb-[100px] pt-[125px] text-center
    max-md:pt-0 max-md:mb-[52px] max-md:overflow-hidden max-md:mt-12"
    >
      <h2 className="text-5xl text-zircon mb-2 max-md:text-4xl">
        <T keyName="components.landingPage.howItWorks.title" />
      </h2>
      <h4 className="text-xl text-white mb-[85px] max-md:mb-11 max-md:text-2xl max-md:font-light max-md:leading-7">
        <T keyName="components.landingPage.howItWorks.subtitle" />
      </h4>
      <div className="max-w-[844px] w-full mx-auto relative pb-20 max-md:max-w-[414px]">
        {/* First */}
        <Card
          text={
            <p>
              <T
                keyName="components.landingPage.howItWorks.cards.first.text"
                params={{
                  joining: () => (
                    <span className="font-bold">
                      <T keyName="components.landingPage.howItWorks.cards.first.joining" />
                    </span>
                  )
                }}
              />
            </p>
          }
          className="absolute right-0 bg-gradient-to-r from-purple to-heliotrope p-[30px_40px_31px_111px]
            box-border max-w-[331px] max-md:max-w-[312px] max-md:p-[20px_16px_20px_111px] before:left-auto before:right-0"
        />
        {/* Second */}
        <Card
          text={
            <p>
              <T
                keyName="components.landingPage.howItWorks.cards.second.text"
                params={{
                  offering: () => (
                    <span className="font-bold">
                      <T keyName="components.landingPage.howItWorks.cards.second.offering" />
                    </span>
                  )
                }}
              />
            </p>
          }
          className="absolute top-[195px] left-0 bg-gradient-to-r from-heliotrope to-purple
            p-[30px_111px_31px_40px] box-border max-md:max-w-[312px] max-md:p-[20px_111px_20px_16px]"
        />
        {/* Third */}
        <Card
          text={
            <p>
              <T
                keyName="components.landingPage.howItWorks.cards.third.text"
                params={{
                  everybody: () => (
                    <span className="font-bold">
                      <T keyName="components.landingPage.howItWorks.cards.third.everybody" />
                    </span>
                  )
                }}
              />
            </p>
          }
          className="absolute bottom-0 bg-gradient-to-r from-heliotrope to-purple
            p-[30px_111px_31px_40px] box-border max-w-[354px] md:left-[230px]
            max-md:right-0 max-md:max-w-[312px] max-md:p-[20px_16px_20px_111px]
            max-md:to-heliotrope max-md:from-purple max-md:before:right-0 max-md:before:left-auto"
        />
        <div className="relative m-auto z-20 max-md:pt-[52px]">
          <img
            src={isBig ? HowItWorksImg : HowItWorksMobile}
            alt=""
            className="m-auto"
          />
        </div>
      </div>
    </div>
  )
}
