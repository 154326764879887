import { twMerge } from 'tailwind-merge'

export const Spinner: React.FC<{ className?: string; small?: boolean }> = ({
  className,
  small
}) => (
  <div
    className={twMerge(
      'w-full h-full flex justify-center items-center py-5',
      className
    )}
  >
    <div
      className={twMerge(
        'box-border h-[108px] w-[108px] border-4 border-bombay' +
          'border-t-4 border-t-blue-dodger rounded-full animate-spin',
        small ? 'h-[48px] w-[48px]' : ''
      )}
    />
  </div>
)
