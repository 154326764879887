import { Overview } from '@/pages/overview'
import { ChatProvider } from '@/providers/Chat/provider'
import { Navigate, type RouteObject } from 'react-router-dom'
import { paymentsRoutes } from './payments'
import { publicRoutes } from './public'
import { rewardsRoutes } from './rewards'
import { settingsRoutes } from './settings'
import { subscriptionRoutes } from './subscriptions'

export const routes: RouteObject[] = [
  ...publicRoutes,
  {
    path: '/overview',
    element: (
      <ChatProvider>
        <Overview />
      </ChatProvider>
    ),
    errorElement: <Navigate to="/" replace />,
    children: [
      ...subscriptionRoutes,
      ...paymentsRoutes,
      ...settingsRoutes,
      ...rewardsRoutes
    ]
  }
]
