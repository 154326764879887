import './styles.css'

export const Background = () => (
  <div className="bg-zircon overflow-hidden h-full">
    {/* First Background */}
    <div className="my-0 mx-auto flex relative">
      <div className="w-1/2 h-[1289px] bg-blue-dodger max-md:hidden" />
      <div className="w-1/2 h-[1356px] bg-blue-dodger max-md:hidden" />
      <div
        className="z-10 absolute left-1/2 top-0 -translate-x-1/2 bg-no-repeat bg-top
      w-[2438px] h-[1531px] bg-[image:var(--image)] background-1
      max-md:w-[1336px] max-md:h-[1363px] max-md:bg-[image:var(--mobile-image)]"
      />
    </div>
    {/* Second Background */}
    <div className="my-0 mx-auto bg-zircon h-[790px] max-md:h-[1571px]" />
    {/* Third Background */}
    <div className="background flex relative">
      <div className="w-1/2 h-[1040px] bg-purple mt-[1px] max-md:hidden" />
      <div className="w-1/2 h-[874px] bg-purple mt-[186px] max-md:hidden" />
      <div
        className="z-10 absolute left-1/2 top-0 -translate-x-1/2 bg-no-repeat bg-top
      w-[2438px] h-[1182px] bg-[image:var(--image)] background-2
      max-md:w-[1024px] max-md:h-[649px] max-md:bg-[image:var(--mobile-image)]"
      />
    </div>
    {/* Fourth Background */}
    <div className="my-0 mx-auto bg-zircon h-[1269px] max-md:h-[1290px]" />
    {/* Fifth Background */}
    <div className="my-0 mx-auto flex relative">
      <div className="w-1/2 h-[381px] bg-purple mt-[259px] max-md:hidden" />
      <div className="w-1/2 bg-purple mt-[1px] max-md:hidden" />
      <div
        className="z-10 absolute left-1/2 top-0 -translate-x-1/2 bg-no-repeat bg-top
      w-[2437px] h-[640px] bg-[image:var(--image)] background-3
      max-md:w-[1541px] max-md:h-[976px] max-md:bg-[image:var(--mobile-image)]"
      />
    </div>
    {/* Sixth Background */}
    <div className="my-0 mx-auto bg-zircon h-[2716px] hidden max-md:block" />
    {/* Seventh Background */}
    <div className="my-0 mx-auto relative h-[471px] hidden max-md:flex">
      <div className="w-1/2 h-[381px] bg-purple mt-[259px] max-md:hidden" />
      <div className="w-1/2 bg-purple mt-[1px] max-md:hidden" />
      <div
        className="z-10 absolute left-1/2 top-0 -translate-x-1/2 bg-no-repeat bg-top
      w-[2437px] h-[471px] bg-[image:var(--image)] background-4"
      />
    </div>
  </div>
)
