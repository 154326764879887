import { Cross, Incoming, Outgoing } from '@/assets/icons'
import { ImgProfile } from '@/components/ImgProfile'
import { TransactionStatus, TransactionType } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { T } from '@tolgee/react'
import moment from 'moment'
import { useContext } from 'react'
import { twJoin } from 'tailwind-merge'

interface TransactionProps {
  sender: { id: string; avatar?: string | null; fullName: string }
  receiver: { id: string; avatar?: string | null; fullName: string }
  amount: number
  createdAt: Date
  type: TransactionType
  serviceName: string
  commission: number
  status: TransactionStatus
}

export const Transaction: React.FC<TransactionProps> = (props) => {
  const { currentUser } = useContext(AuthContext)!

  const {
    type,
    sender,
    receiver,
    serviceName: _serviceName,
    commission,
    amount: _amount
  } = props

  const avatar =
    type === TransactionType.AddNew
      ? sender.avatar
      : sender?.id === currentUser?.id
        ? receiver.avatar
        : sender.avatar

  const name =
    type === TransactionType.AddNew
      ? 'Partizi.de'
      : sender?.id === currentUser?.id
        ? receiver?.fullName
        : receiver?.id === currentUser?.id
          ? sender?.fullName
          : 'Partizi.de'

  let serviceName = _serviceName ? _serviceName.split(' ')[0] : ''
  let serviceClass = 'bg-red'
  if (type === TransactionType.AddNew) {
    serviceName = 'Card Verification'
    serviceClass = 'bg-green-pastel'
  } else if (type === TransactionType.Refund) {
    serviceName = 'Refund'
    serviceClass = 'bg-gray'
  } else if (type === TransactionType.Referral) {
    serviceName = 'Referral'
    serviceClass = 'bg-pink-brink'
  }

  const outgoing = !!(
    type === TransactionType.AddNew ||
    type === TransactionType.MoneyOut ||
    (sender && sender.id === currentUser?.id)
  )

  const amount =
    type === TransactionType.AddNew
      ? commission
      : _amount.toFixed(2)
        ? type === TransactionType.Refund
          ? (_amount - commission).toFixed(2)
          : receiver.id === currentUser?.id && type === TransactionType.MoneyIn
            ? (_amount - commission).toFixed(2)
            : _amount.toFixed(2)
        : 0

  const typeLabel =
    type === TransactionType.Refund
      ? 'refund'
      : type === TransactionType.Referral
        ? 'referral'
        : outgoing
          ? 'outgoing'
          : 'incoming'

  return (
    <div className="flex items-center text-sm border-b border-b-gray-400 max-md:text-base max-md:py-4 max-md:first-of-type:pt-0">
      <div className="w-[6%] max-md:w-1/3">
        <ImgProfile img={avatar} className="w-8 h-8 max-md:w-14 max-md:h-14" />
      </div>
      <div className="w-[44%] flex items-center max-md:w-full max-md:flex-col max-md:flex-grow max-md:gap-2">
        <div className="w-1/2 text-gray-100 py-4 max-md:p-0 max-md:w-full">
          {name}
        </div>
        <div className="w-1/2 py-4 max-md:p-0 max-md:w-full">
          <div
            className={twJoin(
              'text-xs rounded-xl px-2 text-white w-fit',
              serviceClass
            )}
          >
            {serviceName}
          </div>
        </div>
      </div>
      <div className="w-[50%] flex items-center max-md:flex-col-reverse max-md:w-fit max-md:gap-2">
        <div
          className="w-1/2 text-xs text-gray-shuttle-soft py-4 whitespace-nowrap
            max-md:p-0 max-md:w-full max-md:text-sm"
        >
          {moment(props.createdAt).format('DD MMM YYYY')}
        </div>
        <div className="w-1/2 flex items-center max-md:hidden">
          <div className="mr-1">{outgoing ? <Outgoing /> : <Incoming />}</div>
          <div className="text-xs text-gray-shuttle-soft py-4 max-md:p-0">
            <T keyName={`components.offerSubscription.${typeLabel}`} />
          </div>
        </div>
        <div className="flex-grow text-end text-gray-100 py-4 max-md:p-0 max-md:w-full">
          {props.status === TransactionStatus.Cancelled ? (
            <span className="flex justify-end items-center gap-[6px]">
              <Cross className="w-[14px] h-[14px]" /> Failed
            </span>
          ) : (
            <span className="whitespace-nowrap">{`${outgoing ? '-' : '+'} ${amount} EUR`}</span>
          )}
        </div>
      </div>
    </div>
  )
}
