import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { LinkButton } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { toastError, toastSuccess } from '@/utils/toast'
import { useMutation, useQuery } from '@apollo/client'
import ContentCopy from '@mui/icons-material/ContentCopy'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

const PENDING_REQUEST = gql(`
  query getPendingRequestForAcceptPopup($requestId: String!) {
    request(where: { id: $requestId }) {
      isLastRequest
      joinerEmail
      service {
        provider {
          name
          price {
            offerReturn
          }
          joinerEmailRequired
          website
        }
      }
      requestBy {
        fullName
        avatar
      }
    }
  }
`)

const ACCEPT_REQUEST = gql(`
  mutation acceptRequest($requestId: String!) {
    acceptRequest(requestId: $requestId) {
      id
    }
  }
`)

export const AcceptRequest: React.FC = () => {
  const { t } = useTranslate()

  const { id: serviceId, requestId } = useParams<{
    id: string
    requestId: string
  }>()
  invariant(requestId, 'Request ID should be provided by route')

  const navigate = useNavigate()

  const { data, loading } = useQuery(PENDING_REQUEST, {
    variables: { requestId }
  })
  const [acceptRequest] = useMutation(ACCEPT_REQUEST)

  const [confirmed, setConfirmed] = useState(false)

  const handleConfirm = async () => {
    invariant(data, 'handleConfirm should only be callable once data is loaded')
    try {
      await acceptRequest({ variables: { requestId } })
      navigate(
        `/overview/subscriptions/offer/${serviceId}/requests/popup/accept-request/${requestId}/create-account`,
        {
          state: { isLastRequest: data.request.isLastRequest }
        }
      )
    } catch (e) {
      console.error(e)
      toastError((e as Error).message ?? e ?? 'An error occured')
    }
  }

  if (loading)
    return (
      <PopupSkeleton showClose>
        <Spinner />
      </PopupSkeleton>
    )

  invariant(data, 'Data should be loaded')
  const { request } = data

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toastSuccess(t('toastMessages.copyToClipboard.success'))
      },
      () => {
        toastError(t('toastMessages.copyToClipboard.error'))
      }
    )
  }

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-4">
          <ImgProfile
            img={request.requestBy.avatar}
            className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-blue-dodger/50"
          />
          <p className="text-xs">{request.requestBy.fullName}</p>
          <p className="text-xs text-blue-dodger">
            <T
              keyName="components.offerSubscription.offerDetails.requests.acceptRequest.monthlyPrice"
              params={{
                price: () => request.service.provider.price.offerReturn,
                currency: () => <T keyName="currency.symbol.euro" />
              }}
            />
          </p>
        </div>

        <h3 className="text-lg font-medium mb-4">
          <T
            keyName="components.offerSubscription.offerDetails.requests.acceptRequest.confirmTitle"
            params={{
              user: () => request.requestBy.fullName,
              service: () => request.service.provider.name
            }}
          />
        </h3>
        {request.service.provider.joinerEmailRequired && (
          <div>
            <div className="grid mb-4">
              <TextField
                name="joinerEmail"
                value={data.request.joinerEmail}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="copy to clipboard"
                        onClick={() =>
                          copyToClipboard(data.request.joinerEmail ?? '')
                        }
                        edge="end"
                      >
                        <ContentCopy />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
        )}

        <FormControlLabel
          control={<Checkbox name="acceptedTerms" />}
          checked={confirmed}
          onChange={() => setConfirmed((prev) => !prev)}
          label={
            <h6 className="text-xs max-md:text-sm text-left text-gray-shuttle-soft">
              <T
                keyName="components.offerSubscription.offerDetails.requests.acceptRequest.confirmCheckText"
                params={{
                  user: () => request.requestBy.fullName,
                  service: () => request.service.provider.name
                }}
              />
            </h6>
          }
          className="mb-8"
        />
        {request.service.provider.joinerEmailRequired && !confirmed && (
          <div className="flex justify-center gap-6">
            <Button
              onClick={() => navigate(-1)}
              className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
            >
              <T keyName="components.offerSubscription.offerDetails.requests.acceptRequest.btnCancel" />
            </Button>
            <LinkButton
              to={request.service.provider.website ?? '#'}
              target="_blank"
            >
              <T
                keyName="components.offerSubscription.offerDetails.details.goToService"
                params={{
                  service: () => request.service.provider.name
                }}
              />
            </LinkButton>
          </div>
        )}

        {(!request.service.provider.joinerEmailRequired ||
          (request.service.provider.joinerEmailRequired && confirmed)) && (
          <div className="flex justify-center gap-6">
            <Button
              onClick={() => navigate(-1)}
              className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
            >
              <T keyName="components.offerSubscription.offerDetails.requests.acceptRequest.btnCancel" />
            </Button>
            <Button onClick={handleConfirm} disabled={!confirmed}>
              <T keyName="components.offerSubscription.offerDetails.requests.acceptRequest.btnConfirm" />
            </Button>
          </div>
        )}
      </div>
    </PopupSkeleton>
  )
}
