import { useEffect, useRef } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'

export const PopupOutlet: React.FC = () => {
  const outlet = useOutlet()

  if (outlet) {
    return <PopupContainer>{outlet}</PopupContainer>
  }

  return <></>
}

export const PopupContainer: React.FC<{ children?: JSX.Element }> = ({
  children
}) => {
  useEffect(() => {
    if (children) {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.height = 'auto'
      document.body.style.overflow = 'auto'
    }
  }, [children])

  const navigate = useNavigate()
  const overlay = useRef<HTMLDivElement>(null)
  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (e.target === overlay.current) {
        navigate('..', { replace: true })
      }
    }

    const overlayEl = overlay.current
    if (!overlayEl) return

    overlayEl.addEventListener('click', onClick)
    return () => overlayEl.removeEventListener('click', onClick)
  })

  return (
    <>
      <div
        className="fixed inset-0 bg-black/50 z-[999] md:flex md:justify-center md:items-center"
        ref={overlay}
      >
        <div className="overflow-y-auto max-md:fixed max-md:inset-0 max-md:flex max-md:justify-center max-md:items-center">
          {children}
        </div>
      </div>
    </>
  )
}
