import { ImgProfile } from '@/components/ImgProfile'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { toastError } from '@/utils/toast'
import { useMutation, useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

const PENDING_REQUEST = gql(`
  query getRequestDetailsToDecline($requestId: String!) {
    request(where: { id: $requestId }) {
      isLastRequest
      service {
        provider {
          price {
            offerReturn
          }
        }
      }
      requestBy {
        fullName
        avatar
      }
    }
  }
`)

const DECLINE_REQUEST = gql(`
  mutation rejectRequest($requestId: String!) {
    rejectRequest(requestId: $requestId) {
      id
    }
  }
`)

export const DeclineRequest: React.FC = () => {
  const { id: serviceId, requestId } = useParams<{
    id: string
    requestId: string
  }>()
  invariant(requestId, 'Request ID should be provided by route')

  const navigate = useNavigate()

  const { data, loading } = useQuery(PENDING_REQUEST, {
    variables: { requestId }
  })
  const [declineRequest] = useMutation(DECLINE_REQUEST)

  const handleConfirm = async () => {
    invariant(data, 'handleConfirm should only be callable once data is loaded')

    try {
      await declineRequest({ variables: { requestId } })
      if (data.request.isLastRequest)
        navigate(`/overview/subscriptions/offer/${serviceId}/requests`, {
          state: 'refetch'
        })
      else
        navigate(
          `/overview/subscriptions/offer/${serviceId}/requests/popup/warning`
        )
    } catch (e) {
      console.error(e)
      toastError((e as Error).message ?? e ?? 'An error occured')
    }
  }

  if (loading)
    return (
      <PopupSkeleton showClose>
        <Spinner />
      </PopupSkeleton>
    )

  invariant(data, 'Data should be loaded')
  const { request } = data

  return (
    <PopupSkeleton showClose>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex flex-col items-center gap-2 mb-4">
          <ImgProfile
            img={request.requestBy.avatar}
            className="border-[3px] border-transparent shadow-[0_0_0_3px] shadow-pink-brink/50"
          />
          <p className="text-xs">{request.requestBy.fullName}</p>
          <p className="text-xs text-pink-brink">
            <T
              keyName="components.offerSubscription.offerDetails.requests.rejectRequest.monthlyPrice"
              params={{
                price: () => request.service.provider.price.offerReturn,
                currency: () => <T keyName="currency.symbol.euro" />
              }}
            />
          </p>
        </div>

        <h3 className="text-lg font-medium mb-6">
          <T
            keyName="components.offerSubscription.offerDetails.requests.rejectRequest.declineTitle"
            params={{ user: () => request.requestBy.fullName }}
          />
        </h3>

        <div className="flex justify-center gap-6">
          <Button
            onClick={() => navigate(-1)}
            className="border-gray-shuttle text-gray-shuttle hover:bg-gray-shuttle hover:text-white"
          >
            <T keyName="components.offerSubscription.offerDetails.requests.rejectRequest.btnCancel" />
          </Button>
          <Button onClick={handleConfirm}>
            <T keyName="components.offerSubscription.offerDetails.requests.rejectRequest.btnConfirm" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
