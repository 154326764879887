import { SuccessIllustrationId } from '@/assets/illustrations'
import { LinkButton } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup'
import { UploadStatus } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

export const UploadSuccess: React.FC = () => {
  const { currentUser } = useContext(AuthContext)!
  const [searchParams] = useSearchParams()
  const isIban = searchParams.has('iban')

  const doIbanVerification =
    !isIban &&
    !!currentUser?.ibanStatus &&
    [
      UploadStatus.Rejected,
      UploadStatus.RejectedDuplicate,
      UploadStatus.RejectedExpired,
      UploadStatus.RejectedUnreadable,
      UploadStatus.RejectedWrongName,
      UploadStatus.RejectedWrongType
    ].includes(currentUser?.ibanStatus)

  return (
    <PopupSkeleton showClose onCloseLink="/overview/payments/bank-details">
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-6">
          <img src={SuccessIllustrationId} alt="" />
        </div>

        <h3 className="text-2xl font-medium mb-4">
          <T
            keyName={
              isIban
                ? 'components.payments.bankDetails.documentsVerification.waitingIban.title'
                : 'components.payments.bankDetails.documentsVerification.waiting.firstParagraph'
            }
          />
        </h3>

        <p className="text-gray-shuttle-soft mb-8">
          <T
            keyName={
              isIban
                ? 'components.payments.bankDetails.documentsVerification.waitingIban.firstParagraph'
                : 'components.payments.bankDetails.documentsVerification.waiting.secondParagraph'
            }
          />
        </p>

        <div className="flex justify-center gap-6 mt-8">
          <LinkButton
            to={
              doIbanVerification
                ? '/overview/payments/documents-verification?iban'
                : '/overview/payments/bank-details'
            }
            replace
            state={!doIbanVerification ? 'refetch' : undefined}
          >
            <T
              keyName={
                isIban
                  ? 'components.payments.bankDetails.documentsVerification.waitingIban.btnText'
                  : 'components.payments.bankDetails.documentsVerification.waiting.btnOk'
              }
            />
          </LinkButton>
        </div>
      </div>
    </PopupSkeleton>
  )
}
