import { Payments, Rewards, Subscriptions } from '@/assets/icons'
import { ImgProfile } from '@/components/ImgProfile'
import { UnreadCounter } from '@/components/UnreadCounter'
import { ScrollRefContext } from '@/contexts'
import { AuthContext } from '@/providers/Auth/context'
import ClearIcon from '@mui/icons-material/Clear'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsIcon from '@mui/icons-material/Settings'
import { googleLogout } from '@react-oauth/google'
import { T } from '@tolgee/react'
import { useContext, useEffect, useRef, useState } from 'react'
import {
  NavLink,
  useLocation,
  useMatches,
  useNavigate,
  useOutlet
} from 'react-router-dom'
import { twJoin } from 'tailwind-merge'

const routes = [
  {
    path: '/overview/subscriptions',
    label: 'navbar.links.subscriptions',
    icon: Subscriptions,
    showNotification: true
  },
  {
    path: '/overview/rewards',
    label: 'navbar.links.rewards',
    icon: Rewards
  },
  {
    path: '/overview/payments',
    label: 'navbar.links.payments',
    icon: Payments
  }
]

export const Overview: React.FC = () => {
  const navigate = useNavigate()
  const outlet = useOutlet()
  useEffect(() => {
    if (!outlet) navigate('/overview/subscriptions')
  }, [navigate, outlet])

  const { currentUser, logOut } = useContext(AuthContext)!
  const [showNav, setShowNav] = useState(false)

  const matches = useMatches()
  const activeMatch = matches.filter(
    // biome-ignore lint/suspicious/noExplicitAny: The handle is not typed
    (match) => !!(match.handle as any)?.label
  )?.[0]?.handle as {
    label: string
    icon: React.FC
  }

  const { pathname } = useLocation()
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this to happen everytime pathname changes
  useEffect(() => {
    setShowNav(false)
  }, [pathname])

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleLogOut = () => {
    googleLogout()
    logOut()
    navigate('/')
  }

  return (
    <div className="h-screen flex max-md:flex-col">
      <div className="md:hidden visible bg-gray-100 flex items-center">
        <div className="flex-grow flex items-center gap-4 p-4 text-orange">
          {activeMatch?.icon && <activeMatch.icon />}
          <h1 className="text-lg text-white">
            {activeMatch?.label && <T keyName={activeMatch?.label} />}
          </h1>
        </div>
        <div className="block text-white mr-2" onClick={() => setShowNav(true)}>
          <MenuIcon />
        </div>
      </div>
      <div
        className={twJoin(
          'bg-gray-100 h-full w-[500px] flex flex-col gap-8 text-white py-20 ' +
            'max-md:absolute max-md:w-full max-md:h-full max-md:z-50 max-md:transition-transform',
          showNav ? 'max-md:translate-y-0' : 'max-md:-translate-y-full'
        )}
      >
        <div
          className="md:hidden visible absolute top-4 right-4 text-orange"
          onClick={() => setShowNav(false)}
        >
          <ClearIcon />
        </div>
        <div className="flex items-center gap-6 justify-center">
          <ImgProfile img={currentUser?.avatar} />
          <h1 className="text-xl">{currentUser?.fullName}</h1>
        </div>
        <div className="mt-20 flex flex-col flex-grow">
          {routes.map((route) => (
            <NavLink
              to={route.path}
              key={route.path}
              className={({ isActive }) =>
                twJoin(
                  'w-full flex items-center justify-start pl-20 py-4 gap-4 relative',
                  isActive &&
                    'bg-darkshark md:before:absolute md:before:bg-darkshark ' +
                      'md:before:left-full md:before:h-full md:before:pr-3 md:before:rounded-r ' +
                      'md:after:bg-darkshark md:after:absolute md:after:left-full md:after:top-1/2 ' +
                      'md:after:-translate-y-1/2 md:after:ml-4 md:after:w-2 md:after:h-2 md:after:rounded-full'
                )
              }
            >
              <route.icon />
              <h2 className="text-lg relative">
                {route.showNotification && (
                  <UnreadCounter className="absolute -top-2 -right-6" />
                )}
                <T keyName={route.label} />
              </h2>
            </NavLink>
          ))}
        </div>
        <div className="flex justify-center gap-12">
          <div
            className="flex flex-col items-center gap-4 text-gray hover:text-white cursor-pointer"
            onClick={handleLogOut}
          >
            <LogoutIcon />
            <h3 className="text-xs">
              <T keyName="navbar.options.logout" />
            </h3>
          </div>
          <NavLink
            to="/overview/settings"
            className={({ isActive }) =>
              twJoin(
                'flex flex-col items-center gap-4 hover:text-white cursor-pointer',
                isActive ? 'text-white' : 'text-gray'
              )
            }
          >
            <SettingsIcon />
            <h3 className="text-xs">
              <T keyName="navbar.options.settings" />
            </h3>
          </NavLink>
        </div>
      </div>
      <div
        className="flex-grow overflow-y-scroll w-full overflow-visible"
        ref={scrollRef}
      >
        <ScrollRefContext.Provider value={scrollRef}>
          {outlet}
        </ScrollRefContext.Provider>
      </div>
    </div>
  )
}
