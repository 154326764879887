import { Button, TextInput } from '@/components/Inputs'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { SubscriptionPopupContainer } from '@/pages/overview/subscriptions/popups/PopupContainer.tsx'
import { getErrorMessage } from '@/utils/errors.ts'
import { toastError } from '@/utils/toast.ts'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslate } from '@tolgee/react'
import { Form, Formik } from 'formik'
import { useLocation } from 'react-router-dom'
import { match } from 'ts-pattern'
import * as yup from 'yup'

const GET_PROVIDER_REQUESTS = gql(`
  query getProviderRequest {
    providerRequests {
      id
      name
    }
  }
`)

const CREATE_PROVIDER_REQUEST = gql(`
  mutation createProviderRequest($name: String!) {
    createProviderRequest(name: $name) {
      id
    }
  }
`)

export default function ProviderRequest() {
  const { t } = useTranslate()
  const query = useQuery(GET_PROVIDER_REQUESTS)
  const [createProviderRequest] = useMutation(CREATE_PROVIDER_REQUEST)
  const { state } = useLocation()

  return (
    <SubscriptionPopupContainer type={state?.type ?? 'join'}>
      {match(query)
        .with({ loading: true }, () => <Spinner />)
        .with({ loading: false }, ({ data }) => (
          <>
            <h1 className="text-xl mb-2">Request a subscription</h1>
            <h6 className="text-xs text-gray-shuttle mb-8">
              See something missing? Wish them here!
            </h6>

            <Formik
              initialValues={{ name: '' }}
              validationSchema={yup.object({
                name: yup
                  .string()
                  .required(t('form.inputs.errors.providerRequest.required'))
                  .min(3, t('form.inputs.errors.providerRequest.min'))
              })}
              onSubmit={({ name }) => {
                createProviderRequest({ variables: { name } })
                  .then(() => query.refetch())
                  .catch((err) => {
                    console.error(err)
                    toastError(getErrorMessage(err))
                  })
              }}
            >
              {(formik) => (
                <Form>
                  <TextInput
                    name="name"
                    formik={formik}
                    intlKey="providerRequest.name"
                  />
                  <Button className="mt-4">Submit</Button>
                </Form>
              )}
            </Formik>

            {
              <div className="mt-4 max-h-72 overflow-y-scroll">
                <ul className="grid gap-4 grid-cols-3 grid-cols-3">
                  {data?.providerRequests.map(({ id, name }) => (
                    <li
                      key={id}
                      className="p-4 border-dashed border-2 rounded-md border-gray-shuttle table-cell align-middle text-center"
                    >
                      {name}
                    </li>
                  ))}
                </ul>
              </div>
            }
          </>
        ))
        .exhaustive()}
    </SubscriptionPopupContainer>
  )
}
