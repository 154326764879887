import { Sceptic, Smile } from '@/assets/icons'
import {
  IBAN_CORRECT,
  IBAN_ERROR_1,
  IBAN_ERROR_2,
  IBAN_ERROR_3,
  KYC_CORRECT,
  KYC_ERROR_1,
  KYC_ERROR_2,
  KYC_ERROR_3
} from '@/assets/illustrations'
import { LinkButton } from '@/components/Inputs/Button'
import { PopupSkeleton } from '@/components/Popup'
import { T } from '@tolgee/react'
import { useSearchParams } from 'react-router-dom'

const StepsIBAN = [IBAN_ERROR_1, IBAN_ERROR_2, IBAN_ERROR_3, IBAN_CORRECT]
const StepsKYC = [KYC_ERROR_1, KYC_ERROR_2, KYC_ERROR_3, KYC_CORRECT]

export const Guidelines: React.FC = () => {
  const [searchParams] = useSearchParams()
  const isIban = searchParams.has('iban')

  return (
    <PopupSkeleton
      showClose
      showGoBack
      onCloseLink="/overview/payments/bank-details"
    >
      <div className="w-[800px] h-full max-md:w-full py-8 px-4 text-center">
        <h3 className="text-2xl font-medium mb-4">
          <T
            keyName={`components.payments.bankDetails.documentsVerification.documentsGuidelines.title${
              isIban ? 'Iban' : ''
            }`}
          />
        </h3>

        <p className="text-gray-shuttle-soft mb-8">
          <T
            keyName={`components.payments.bankDetails.documentsVerification.documentsGuidelines.subTitle${
              isIban ? 'Iban' : ''
            }`}
          />
        </p>

        <div className="grid grid-cols-4 gap-x-4 max-md:grid-cols-2 max-md:gap-y-8">
          {(isIban ? StepsIBAN : StepsKYC).map((step, index) => (
            <div key={index} className="flex flex-col gap-2">
              <div
                className="relative h-[176px] w-full flex justify-center items-center
                  rounded border border-dashed border-silver bg-gray-400/10"
              >
                <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-0">
                  {index === 3 ? <Smile /> : <Sceptic />}
                </div>
                <img src={step} alt="" className="" />
              </div>
              <p className="text-xs text-gray-shuttle-soft">
                <T
                  keyName={`components.payments.bankDetails.documentsVerification.documentsGuidelines.card${
                    index + 1
                  }`}
                />
              </p>
            </div>
          ))}
        </div>

        <div className="flex justify-center gap-6 mt-8">
          <LinkButton
            to={isIban ? '../upload?type=IBAN_PROOF' : '../verify-id'}
          >
            <T keyName="components.payments.bankDetails.documentsVerification.documentsGuidelines.btn" />
          </LinkButton>
        </div>
      </div>
    </PopupSkeleton>
  )
}
