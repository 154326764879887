import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { routes } from './routes'
import './main.css'
import { ApolloProvider } from '@apollo/client'
import { ToastContainer } from 'react-toastify'
import { client } from './apollo'
import { AuthProvider } from './providers/Auth/provider'
import { TranslationProvider } from './providers/Translation/provider'
import 'react-toastify/dist/ReactToastify.css'
import { GOOGLE_CLIENT_ID } from '@/consts.ts'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN
})

const router = createBrowserRouter(routes)

// biome-ignore lint/style/noNonNullAssertion: The root div is defined in the index.html file
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ApolloProvider client={client}>
        <TranslationProvider>
          <AuthProvider>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <RouterProvider router={router} />
              <ToastContainer />
            </GoogleOAuthProvider>
          </AuthProvider>
        </TranslationProvider>
      </ApolloProvider>
    </LocalizationProvider>
  </React.StrictMode>
)
