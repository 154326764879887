import { Rewards as RewardsIcon } from '@/assets/icons'
import { Rewards } from '@/pages/overview/rewards'
import type { RouteObject } from 'react-router-dom'

export const rewardsRoutes: RouteObject[] = [
  {
    path: 'rewards',
    element: <Rewards />,
    handle: {
      label: 'navbar.links.rewards',
      icon: RewardsIcon
    }
  }
]
