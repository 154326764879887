import { UnreadCounter } from '@/components/UnreadCounter'
import { useState } from 'react'
import { twJoin } from 'tailwind-merge'

export const StateTabs: React.FC<{
  initialIndex?: number
  tabs: {
    component: JSX.Element
    label: string | JSX.Element
    type: 'offer' | 'join'
  }[]
}> = ({ initialIndex, tabs }) => {
  const [activeTab, setActiveTab] = useState(initialIndex ?? 0)

  return (
    <>
      <div className="flex gap-12 border-b border-bombay max-md:justify-center">
        {tabs.map((tab, idx) => (
          <div
            onClick={() => {
              setActiveTab(idx)
            }}
            key={idx}
            className={twJoin(
              'text-lg text-gray-shuttle pb-2 relative max-md:text-base max-md:pb-4',
              activeTab === idx && 'text-black border-b-[3px] border-b-black'
            )}
          >
            <UnreadCounter
              serviceType={tab.type}
              className="absolute -top-2 -right-4"
            />
            {tab.label}
          </div>
        ))}
      </div>
      <div>{tabs[activeTab].component}</div>
    </>
  )
}
